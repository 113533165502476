


































































































































































import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'

// controls
import JTableInputWithDialog from '@/components/controls/JTableInputWithDialog.vue'
import JFormattedInput from '@/components/controls/JFormattedInput.vue'
import JCalculator from '@/components/controls/JCalculator.vue'

// models
import OrderAdjustmentEditable from '@/models/OrderAdjustments/OrderAdjustmentEditable'

export default Vue.extend({
  components: {
    JFormattedInput,
    JTableInputWithDialog,
    JCalculator,
  },
  props: {
    value: Object as PropType<OrderAdjustmentEditable>,

    disableMoveUp: Boolean,
    disableMoveDown: Boolean,

    readonly: Boolean,
  },
  data: () => ({
    menu: false,
    isDeleting: false,

    orderAdjustmentTypes: [
      { id: '$', icon: 'mdi-currency-usd' },
      { id: '%', icon: 'mdi-percent' },
    ],
  }),
  computed: {
    ...mapGetters(['orderAdjustmentCategories']),
    item(): OrderAdjustmentEditable {
      return this.value
    },
    isValid(): boolean {
      return this.item.isValid
    },
    isDefault(): boolean {
      return this.item.isDefault
    },
  },
  methods: {
    onDeleteClick(): void {
      if (this.isDeleting) {
        this.$emit('delete')
      } else {
        this.isDeleting = true
      }
    },
    closeMenu(): void {
      this.menu = false
    },
  },
})
