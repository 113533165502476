import { render, staticRenderFns } from "./OrderCalcCommissionComponent.vue?vue&type=template&id=cd4ace08&scoped=true&"
import script from "./OrderCalcCommissionComponent.vue?vue&type=script&lang=ts&"
export * from "./OrderCalcCommissionComponent.vue?vue&type=script&lang=ts&"
import style0 from "./OrderCalcCommissionComponent.vue?vue&type=style&index=0&id=cd4ace08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd4ace08",
  null
  
)

export default component.exports