// eslint-disable-file @typescript-eslint/camelcase
import {
  UserManager,
  WebStorageStateStore,
  InMemoryWebStorage,
  User,
  UserManagerSettings,
} from 'oidc-client'

import log from '@/log'
import { UserProfile, transformUser } from '@/auth/providers/auth0'

// types
import { User as DbUser } from '@/types'
import { Token } from '@/auth/types'

const AUTH_AUTHORITY = process.env.VUE_APP_AUTH_AUTHORITY
const AUTH_CLIENT_ID = process.env.VUE_APP_AUTH_CLIENT_ID
const AUTH_AUDIENCE = process.env.VUE_APP_AUTH_AUDIENCE
const AUTH_SCOPE = process.env.VUE_APP_AUTH_SCOPE || 'openid profile'
const authorizationParams: string[] = (
  process.env.VUE_APP_AUTH_PARAMS || 'state session_state code'
).split(' ')

export default class AuthService {
  private userManager: UserManager
  private tokenParsed?: Token<UserProfile>
  private accessTokenExpired = false
  public error?: Error

  constructor() {
    const settings: UserManagerSettings = {
      userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
      authority: AUTH_AUTHORITY,
      client_id: AUTH_CLIENT_ID, // eslint-disable-line @typescript-eslint/camelcase
      extraQueryParams: { audience: AUTH_AUDIENCE },
      redirect_uri: window.location.origin, // eslint-disable-line @typescript-eslint/camelcase
      response_type: 'code', // eslint-disable-line @typescript-eslint/camelcase
      scope: AUTH_SCOPE,
      filterProtocolClaims: true,
      automaticSilentRenew: false,
      metadata: {
        issuer: `${AUTH_AUTHORITY}/`,
        authorization_endpoint: `${AUTH_AUTHORITY}/authorize`, // eslint-disable-line @typescript-eslint/camelcase
        token_endpoint: `${AUTH_AUTHORITY}/oauth/token`, // eslint-disable-line @typescript-eslint/camelcase
        userinfo_endpoint: `${AUTH_AUTHORITY}/userinfo`, // eslint-disable-line @typescript-eslint/camelcase
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_session_endpoint: `${AUTH_AUTHORITY}/v2/logout?client_id=${AUTH_CLIENT_ID}&returnTo=${encodeURIComponent(
          window.location.origin
        )}`,
      },
    }

    this.userManager = new UserManager(settings)

    // subscribers
    this.userManager.events.addUserLoaded(response => {
      log.info('userLoaded:', response)
      this.updateUser(response)
    })

    this.userManager.events.addUserUnloaded(() => {
      log.info('userUnloaded')
    })

    this.userManager.events.addAccessTokenExpiring(response => {
      log.info('accessTokenExpiring', response)
    })

    this.userManager.events.addAccessTokenExpired(response => {
      log.info('accessTokenExpired', response)
      this.accessTokenExpired = true
    })

    this.userManager.events.addSilentRenewError(response => {
      log.info('silentRenewError', response)
      this.error = response
    })

    this.userManager.events.addUserSignedOut(() => {
      log.info('userSignedOut ')
    })
  }

  private updateUser(user: User) {
    this.tokenParsed = user as Token<UserProfile> // required for Auth0
    this.accessTokenExpired = false
    this.error = undefined
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser()
  }

  public login(): Promise<void> {
    const AUTH_REDIRECT_URL = window.location.origin

    return this.userManager.signinRedirect({
      // eslint-disable-next-line @typescript-eslint/camelcase
      redirect_uri:
        AUTH_REDIRECT_URL + `?to=${encodeURIComponent(window.location.href)}`,
    })
  }

  public logout(): Promise<void> {
    const href = window.location.href
    return this.userManager.signoutRedirect({
      // eslint-disable-next-line @typescript-eslint/camelcase
      post_logout_redirect_uri: href,
    })
  }

  public refresh(): Promise<User> {
    return this.userManager.signinSilent().then(user => {
      this.updateUser(user)
      return user
    })
  }

  private handleRedirectCallback() {
    return this.userManager.signinRedirectCallback().then(() => {
      this.cleanLocation()
      this.redirectLogin()
    })
  }

  private redirectLogin() {
    const url = new URL(window.location.href)

    const params = new URLSearchParams(url.search)

    if (params.has('to')) {
      const to = params.get('to') as string
      if (new URL(to).origin === window.location.origin) {
        window.history.replaceState({}, document.title, to)
      }
    }
  }

  public isExpired(): boolean {
    return !!this.tokenParsed?.expired
  }

  // added 2021-02-01
  public init(feedback?: (text: string) => void) {
    const params = new URLSearchParams(window.location.search)

    if (params.has('error')) {
      return Promise.reject({
        type: 'error',
        message: `${params.get('error')}: ${params.get('error_description')}}`,
      })
    } else if (authorizationParams.every(p => params.has(p))) {
      if (feedback) feedback('logging in...')
      return this.handleRedirectCallback()
    } else {
      return this.login().then(() => {
        return Promise.reject({
          type: 'authenticating',
          message: 'authenticating user...',
        })
      })
    }
  }

  private cleanLocation() {
    const url = new URL(window.location.href)

    const params = new URLSearchParams(url.search)

    authorizationParams.forEach(p => {
      params.delete(p)
    })

    url.search = params.toString()

    window.history.replaceState({}, document.title, url.toString())
  }

  public get token() {
    return this.tokenParsed?.access_token || 'token-missing'
  }

  public get user(): DbUser | undefined {
    const profile = this.tokenParsed?.profile

    if (!profile) return undefined

    return transformUser(profile)
  }
}
