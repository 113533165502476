import { v4 as uuid } from 'uuid'

// models
import { OrderEditable } from '@/models/Orders/OrderEditable'

// utils
import { isDefault } from '@/utils/isDefault'

// types
import {
  ID,
  OrderAdjustmentEditableInput,
  OrderAdjustmentType,
  OrderAdjustmentCategory,
  OrderAdjustmentOutput,
} from '@/types'

// defaults
const DEFAULT_ORDER_ADJUSTMENT: OrderAdjustmentEditableInput = {
  id: undefined,
  type: '$',
  category: undefined,
  amount: undefined,
  note: undefined,
}

export class OrderAdjustmentEditable {
  localId = uuid()

  id: ID | undefined

  type: OrderAdjustmentType = '$'
  category?: OrderAdjustmentCategory
  amount?: number
  note?: string

  order: OrderEditable

  constructor(
    order: OrderEditable,
    orderAdjustmentInput: OrderAdjustmentEditableInput
  ) {
    const { id, type, category, amount, note } = orderAdjustmentInput

    this.order = order

    this.id = id
    this.type = type
    this.category = category
    this.amount = amount
    this.note = note
  }

  get isValid(): boolean {
    // TODO: validate
    return !!this.type && !!this.category && !!this.amount
  }

  get isDefault(): boolean {
    return isDefault<OrderAdjustmentEditableInput>(
      this,
      DEFAULT_ORDER_ADJUSTMENT
    )
  }

  // calculated
  calcAdjustmentAmount(grossAmount: number): number {
    return this.type === '%'
      ? (this.amount || 0) * grossAmount
      : this.amount || 0
  }

  public toJSON(): OrderAdjustmentOutput {
    return {
      id: this.id,

      type: this.type,
      category: this.category,
      amount: this.amount,
      note: this.note,
    }
  }

  public static create(order: OrderEditable): OrderAdjustmentEditable {
    return new OrderAdjustmentEditable(order, DEFAULT_ORDER_ADJUSTMENT)
  }
}

export default OrderAdjustmentEditable
