import Vue from 'vue'
import { VCheckbox, VSelect, VTextarea, VTextField } from 'vuetify/lib'
import JDateField from '@/components/controls/JDateField.vue'

// api
import { ApiInterface } from '@/api'

// types
import {
  CommissionAdjustment,
  CommissionPayment,
  CommissionPayoutRate,
  CommissionQuota,
  CommissionPayPeriodQuota,
  OrderTag,
  Customer,
  CustomerType,
  Product,
  ProductCategory,
  ProductCommissionComponent,
  OrderType,
  OrderCategory,
  OrderAdjustmentCategory,
  Company,
  SalesArea,
  Currency,
  Salesperson,
  CommissionQuotaPeriod,
  CommissionPayPeriod,
  ID,
  DateOnly,
} from '@/types'

import { RefDataObj, RefDataItemWithId, Store, RefDataItem } from './types'
export {
  RefDataField,
  RefDataItem,
  RefDataItemWithId,
  RefDataStore,
  RefDataApi,
} from './types'

// fields
import {
  idField,
  abbrField,
  nameField,
  descriptionField,
  currencyField,
  startDateField,
  endDateField,
  companyField,
  productField,
  commissionComponentField,
  commissionQuotaPeriodField,
  salesAreaField,
  salespersonField,
  commissionPayPeriodField,
  commissionAdjustmentTypeField,
  getRefDataField,
} from './ref-data-fields'
import { currencyFormatter, percentFormatter } from '@/utils/format-helpers'

const getRefDataItem = <Item>(input: RefDataItem<Item>) => input

const defaultFields = <
  Item extends { id: ID; abbr: string; name: string }
>() => [idField<Item>(), abbrField<Item>(), nameField<Item>()]

export const refDataObj: RefDataObj = {
  currencies: getRefDataItem<Currency>({
    label: ['Currency', 'Currencies'],
    icon: '$currency',
    route: {
      path: '/currencies',
      name: 'currencies',
    },
    store: {
      state: 'currencies',
      mutateAll: 'setCurrencies',
    },
    sortBy: 'id',
    api: {
      list: (api: ApiInterface) => api.currencies.list,
    },
    fields: [
      // required because currency is a string
      getRefDataField({
        id: 'id',
        label: 'ID',
        field: 'id',
        value: v => v.id,
        filter: 'list',
      }),
      nameField(),
      getRefDataField<Currency>({
        id: 'symbol',
        label: 'Symbol',
        field: 'symbol',
        value: v => v.symbol,
        filter: 'list',
      }),
    ],
  }),

  orderTypes: getRefDataItem<OrderType>({
    label: ['Order Type', 'Order Types'],
    route: {
      path: '/order-types',
      name: 'order-types',
    },
    store: {
      state: 'orderTypes',
      mutateOne: 'setOrderType',
      mutateAll: 'setOrderTypes',
    },
    api: {
      list: (api: ApiInterface) => api.orderTypes.list,
    },
    fields: [
      ...defaultFields<OrderType>(),
      getRefDataField({
        id: 'displayInitial',
        label: 'Display Initial',
        field: 'displayInitial',
        value: v => v.displayInitial,
        filter: 'list',
      }),
    ],
  }),

  orderCategories: {
    label: ['Order Category', 'Order Categories'],
    route: {
      path: '/order-categories',
      name: 'order-categories',
    },
    store: {
      state: 'orderCategories',
      mutateOne: 'setOrderCategory',
      mutateAll: 'setOrderCategories',
    },
    api: {
      list: (api: ApiInterface) => api.orderCategories.list,
    },
    fields: [...defaultFields<OrderCategory>()],
  },
  orderAdjustmentCategories: {
    label: ['Order Adjustment Category', 'Order Adjustment Categories'],
    route: {
      path: '/order-adjustment-categories',
      name: 'order-adjustment-categories',
    },
    store: {
      state: 'orderAdjustmentCategories',
      mutateOne: 'setOrderAdjustmentCategory',
      mutateAll: 'setOrderAdjustmentCategories',
    },
    api: {
      list: (api: ApiInterface) => api.orderAdjustmentCategories.list,
    },
    fields: [...defaultFields<OrderAdjustmentCategory>()],
  },

  companies: {
    label: ['Company', 'Companies'],
    icon: '$company',
    route: {
      path: '/companies',
      name: 'companies',
    },
    store: {
      state: 'companies',
      mutateOne: 'setCompany',
      mutateAll: 'setCompanies',
    },
    api: {
      list: (api: ApiInterface) => api.companies.list,
    },
    fields: [
      ...defaultFields<Company>(),
      {
        id: 'baseCurrency',
        label: 'Base Currency',
        field: 'baseCurrency',
        value: v => v.baseCurrency,
        filter: 'list',
      },
    ],
  },

  salesAreas: {
    label: ['Sales Area', 'Sales Areas'],
    icon: '$sales-area',
    route: {
      path: '/sales-areas',
      name: 'sales-areas',
    },
    store: {
      state: 'salesAreas',
      mutateOne: 'setSalesArea',
      mutateAll: 'setSalesAreas',
    },
    api: {
      list: (api: ApiInterface) => api.salesAreas.list,
    },
    fields: [...defaultFields<SalesArea>()],
  },

  salespersons: {
    label: ['Salesperson', 'Salespersons'],
    icon: '$salesperson',
    route: {
      path: '/salespersons',
      name: 'salespersons',
    },
    store: {
      state: 'salespersons',
      mutateAll: 'setSalespersons',
    },
    sortBy: 'username',
    api: {
      list: (api: ApiInterface) => api.salespersons.list,
    },
    fields: [
      idField<Salesperson>(),
      {
        id: 'username',
        label: 'Username',
        field: 'username',
        value: v => v.username,
        filter: 'list',
      },
      {
        id: 'lastName',
        label: 'Last',
        field: 'lastName',
        value: v => v.lastName,
        filter: 'list',
      },
      {
        id: 'firstName',
        label: 'First',
        field: 'firstName',
        value: v => v.firstName,
        filter: 'list',
      },
    ],
  },

  products: {
    label: ['Product', 'Products'],
    icon: '$products',
    route: {
      path: '/products',
      name: 'products',
    },
    store: {
      state: 'products',
      mutateOne: 'setProduct',
      mutateAll: 'setProducts',
    },
    api: {
      list: (api: ApiInterface) => api.products.list,
      create: (api: ApiInterface) => api.products.create,
      replace: (api: ApiInterface) => api.products.replace,
    },
    fields: [
      ...defaultFields(),
      descriptionField(),
      getRefDataField({
        id: 'imageUrl',
        label: 'Image URL',
        field: 'imageUrl',
        value: v => v.imageUrl || null,
        input: {
          value: (item: Product) => item.imageUrl,
          component: VTextField,
          attrs: { label: 'Image URL' },
        },
        colAttrs: { col: 12 },
        filter: 'none',
      }),
      getRefDataField<Product, ProductCategory>({
        id: 'categoryId',
        castId: v => parseInt(v),
        label: 'Cat',
        field: 'category.abbr',
        value: v => v.category.abbr,
        input: {
          value: item => item.category,
          onInput: copy => item => Vue.set(copy, 'category', item),
          component: VSelect,
          items: (store: Store) => store.getters.productCategories,
          attrs: {
            'item-text': 'abbr',
            'item-value': 'id',
            'return-object': true,
            label: 'Category',
          },
        },
        colAttrs: { 'col-md': 6 },
        filter: 'list',
      }),
      getRefDataField<Product>({
        id: 'postedTrigger',
        label: 'Post',
        field: 'postedTrigger',
        value: v => v.postedTrigger,
        input: {
          value: (item: Product) => item.postedTrigger,
          component: VSelect,
          items: store => store.getters.productPostedTriggers,
          attrs: {
            label: 'Posted Trigger',
          },
        },
        colAttrs: { 'col-md': 6 },
        filter: 'list',
      }),
      commissionComponentField(),
    ],
  },

  productCommissionComponents: {
    label: ['Product Commission Component', 'Product Commission Components'],
    route: {
      path: '/product-commission-components',
      name: 'product-commission-components',
    },
    api: {
      list: (api: ApiInterface) => api.productCommissionComponents.list,
      create: (api: ApiInterface) => api.productCommissionComponents.create,
      replace: (api: ApiInterface) => api.productCommissionComponents.replace,
      delete: (api: ApiInterface) => api.productCommissionComponents.delete,
    },
    fields: [
      idField(),
      productField(),
      commissionComponentField(),
      getRefDataField<ProductCommissionComponent, DateOnly>({
        id: 'expiryDate',
        label: 'Expiry Date',
        field: 'expiryDate',
        value: v => v.expiryDate,
        input: {
          value: (item: ProductCommissionComponent) => item.expiryDate,
          onInput: copy => item => {
            Vue.set(copy, 'expiryDate', item)
          },
          component: JDateField,
          attrs: { label: 'Expiry Date', 'emit-change': true, left: true },
        },
        filter: 'date-range',
      }),
    ],
  },

  orderTags: {
    label: ['Order Tag', 'Order Tags'],
    icon: '$order-tags',
    route: {
      path: '/order-tags',
      name: 'order-tags',
    },
    store: {
      state: 'orderTags',
      mutateOne: 'setOrderTag',
      mutateAll: 'setOrderTags',
    },
    api: {
      list: (api: ApiInterface) => api.orderTags.list,
      create: (api: ApiInterface) => api.orderTags.create,
      replace: (api: ApiInterface) => api.orderTags.replace,
    },
    fields: [
      ...defaultFields(),
      descriptionField(),
      getRefDataField({
        id: 'color',
        label: 'Color',
        field: 'color',
        value: v => v.color || null,
        input: {
          value: (item: OrderTag) => item.color,
          component: VTextField,
          attrs: { label: 'Color' },
        },
        colAttrs: { col: 12 },
        filter: 'none',
      }),
      getRefDataField({
        id: 'inactive',
        label: 'Inactive',
        field: 'inactive',
        value: v => v.inactive,
        input: {
          value: (item: OrderTag) => !!item.inactive,
          onInput: copy => value => Vue.set(copy, 'inactive', !!value),
          component: VCheckbox,
          attrs: { label: 'Inactive' },
        },
        colAttrs: { col: 6 },
        filter: 'none',
      }),
    ],
  },

  productCategories: {
    label: ['Product Category', 'Product Categories'],
    route: {
      path: '/product-categories',
      name: 'product-categories',
    },
    store: {
      state: 'productCategories',
      mutateOne: 'setProductCategory',
      mutateAll: 'setProductCategories',
    },
    api: {
      list: (api: ApiInterface) => api.productCategories.list,
    },
    fields: [
      ...defaultFields(),
      {
        id: 'groupId',
        castId: v => parseInt(v),
        label: 'Group',
        field: 'group.abbr',
        value: v => v.group.abbr,
        filter: 'list',
      },
    ],
  },
  productConditions: {
    label: ['Product Condition', 'Product Conditions'],
    route: {
      path: '/product-conditions',
      name: 'product-conditions',
    },
    store: {
      state: 'productConditions',
      mutateOne: 'setProductCondition',
      mutateAll: 'setProductConditions',
    },
    api: {
      list: (api: ApiInterface) => api.productConditions.list,
    },
    fields: [...defaultFields()],
  },

  customers: {
    label: ['Customer', 'Customers'],
    icon: '$customers',
    route: {
      path: '/customers',
      name: 'customers',
    },
    store: {
      state: 'customers',
      mutateOne: 'setCustomer',
      mutateAll: 'setCustomers',
    },
    api: {
      list: (api: ApiInterface) => api.customers.list,
      create: (api: ApiInterface) => api.customers.create,
      replace: (api: ApiInterface) => api.customers.replace,
    },
    fields: [
      ...defaultFields(),
      getRefDataField<Customer, CustomerType>({
        id: 'typeId',
        castId: v => parseInt(v),
        idGetter: item => item.type.id,
        label: 'Type',
        field: 'type.abbr',
        value: v => v.type.abbr,
        input: {
          value: item => item.type,
          onInput: copy => item => Vue.set(copy, 'type', item),
          component: VSelect,
          items: (store: Store) => store.getters.customerTypes,
          attrs: {
            'item-text': 'abbr',
            'item-value': 'id',
            'return-object': true,
            label: 'Type',
          },
        },
        colAttrs: { 'col-md': 6 },
        filter: 'list',
      }),
    ],
    to: (item: Customer) => ({
      name: 'customer',
      params: { customerId: item.id.toString() },
    }),
    toField: 'id',
  },
  customerTypes: {
    label: ['Customer Type', 'Customer Types'],
    route: {
      path: '/customer-types',
      name: 'customer-types',
    },
    store: {
      state: 'customerTypes',
      mutateOne: 'setCustomerType',
      mutateAll: 'setCustomerTypes',
    },
    api: {
      list: (api: ApiInterface) => api.customerTypes.list,
      create: (api: ApiInterface) => api.customerTypes.create,
      replace: (api: ApiInterface) => api.customerTypes.replace,
      delete: (api: ApiInterface) => api.customerTypes.delete,
    },
    fields: [...defaultFields()],
  },
  shippingTerms: {
    label: ['Shipping Term', 'Shipping Terms'],
    route: {
      path: '/shipping-terms',
      name: 'shipping-terms',
    },
    store: {
      state: 'shippingTerms',
      mutateOne: 'setShippingTerm',
      mutateAll: 'setShippingTerms',
    },
    sortBy: 'id',
    api: {
      list: (api: ApiInterface) => api.shippingTerms.list,
    },
    fields: [...defaultFields()],
  },

  commissionQuotaPeriods: {
    label: ['Commission Quota Period', 'Commission Quota Periods'],
    icon: '$commission-quota-period',
    route: {
      path: '/commission-quota-periods',
      name: 'commission-quota-periods',
    },
    store: {
      state: 'commissionQuotaPeriods',
      mutateOne: 'setCommissionQuotaPeriod',
      mutateAll: 'setCommissionQuotaPeriods',
    },
    api: {
      list: (api: ApiInterface) => api.commissionQuotaPeriods.list,
    },
    fields: [...defaultFields(), startDateField(), endDateField()],
  },

  commissionComponents: {
    label: ['Commission Component', 'Commission Components'],
    route: {
      path: '/commission-components',
      name: 'commission-components',
    },
    store: {
      state: 'commissionComponents',
      mutateOne: 'setCommissionComponent',
      mutateAll: 'setCommissionComponents',
    },
    api: {
      list: (api: ApiInterface) => api.commissionComponents.list,
    },
    fields: [
      ...defaultFields(),
      {
        id: 'groupId',
        castId: v => parseInt(v),
        label: 'Group',
        field: 'group.abbr',
        value: v => v.group.abbr,
        filter: 'list',
      },
    ],
  },

  commissionPayPeriods: getRefDataItem<CommissionPayPeriod>({
    label: ['Commission Pay Period', 'Commission Pay Periods'],
    icon: '$commission-pay-period',
    route: {
      path: '/commission-pay-periods',
      name: 'commission-pay-periods',
    },
    store: {
      state: 'commissionPayPeriods',
      mutateOne: 'setCommissionPayPeriod',
      mutateAll: 'setCommissionPayPeriods',
    },
    api: {
      list: (api: ApiInterface) => api.commissionPayPeriods.list,
    },
    fields: [
      ...defaultFields<CommissionPayPeriod>(),
      startDateField<CommissionPayPeriod>(),
      endDateField<CommissionPayPeriod>(),
      commissionQuotaPeriodField<CommissionPayPeriod>(),
      getRefDataField<CommissionPayPeriod, number>({
        id: 'periodNumber',
        castId: v => parseInt(v),
        label: 'Period Number',
        field: 'periodNumber',
        value: v => v.periodNumber,
        align: 'end',
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 4 },
        filter: 'list',
      }),
      getRefDataField<CommissionPayPeriod, number>({
        id: 'periodGroup4',
        castId: v => parseInt(v),
        label: 'Period Group (4)',
        field: 'periodGroup4',
        value: v => v.periodGroup4,
        align: 'end',
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 4 },
        filter: 'list',
      }),
      {
        id: 'periodGroup2',
        castId: v => parseInt(v),
        label: 'Period Group (2)',
        field: 'periodGroup2',
        value: v => v.periodGroup2,
        align: 'end',
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 4 },
        filter: 'list',
      },
    ],
  }),

  productCategoryGroups: {
    label: ['Product Category Group', 'Product Category Groups'],
    route: {
      path: '/product-category-groups',
      name: 'product-category-groups',
    },
    store: {
      state: 'productCategoryGroups',
      mutateOne: 'setProductCategoryGroup',
      mutateAll: 'setProductCategoryGroups',
    },
    api: {
      list: (api: ApiInterface) => api.productCategoryGroups.list,
    },
    fields: [...defaultFields()],
  },
  commissionComponentGroups: {
    label: ['Commission Component Group', 'Commission Component Groups'],
    route: {
      path: '/commission-component-groups',
      name: 'commission-component-groups',
    },
    store: {
      state: 'commissionComponentGroups',
      mutateOne: 'setCommissionComponentGroup',
      mutateAll: 'setCommissionComponentGroups',
    },
    api: {
      list: (api: ApiInterface) => api.commissionComponentGroups.list,
    },
    fields: [...defaultFields()],
  },

  commissionPayments: getRefDataItem<CommissionPayment>({
    label: ['Commission Payment', 'Commission Payments'],
    icon: '$commission-payment',
    route: {
      path: '/commission-payments',
      name: 'commission-payments',
    },
    sortBy: 'commissionPayPeriod.abbr',
    sortDesc: true,
    api: {
      list: (api: ApiInterface) => api.commissions.payments.list,
      create: (api: ApiInterface) => api.commissions.payments.create,
      replace: (api: ApiInterface) => api.commissions.payments.replace,
      delete: (api: ApiInterface) => api.commissions.payments.delete,
    },
    fields: [
      idField(),
      { ...companyField(), colAttrs: { 'col-md': 3 } },
      { ...salesAreaField(), colAttrs: { 'col-md': 4 } },
      { ...salespersonField(), colAttrs: { 'col-md': 5 } },
      commissionPayPeriodField(),
      getRefDataField<CommissionPayment>({
        id: 'commissionQuotaPeriodId',
        castId: v => parseInt(v),
        idGetter: item => item.commissionPayPeriod.commissionQuotaPeriodId,
        label: 'Quota Period',
        field: 'commissionPayPeriod.commissionQuotaPeriodId',
        value: v => v.commissionPayPeriod.commissionQuotaPeriodId,
        filter: 'list',
      }),
      commissionComponentField(),
      {
        ...currencyField(),
        colAttrs: { 'col-md': 3 },
      },
      getRefDataField<CommissionPayment>({
        id: 'commissionComponentGroupId',
        castId: v => parseInt(v),
        idGetter: item => item.commissionComponent.group.id,
        label: 'Comp Group',
        field: 'commissionComponent.group.abbr',
        value: v => v.commissionComponent.group.abbr,
        filter: 'list',
      }),
      getRefDataField<CommissionPayment>({
        id: 'paymentAmount',
        castId: v => Number(v),
        label: 'Payment',
        field: 'paymentAmount',
        value: v => v.paymentAmount,
        align: 'end',
        formatter: (value: number, item) =>
          currencyFormatter(item.currency)(value),
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 4 },
        filter: 'number',
      }),
      {
        id: 'paymentPostedAmount',
        castId: v => Number(v),
        label: 'Posted',
        field: 'paymentPostedAmount',
        value: v => v.paymentPostedAmount,
        align: 'end',
        formatter: (value: number, item: CommissionPayment) =>
          currencyFormatter(item.currency)(value),
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 5 },
        filter: 'number',
      },
      {
        id: 'note',
        label: 'Note',
        field: 'note',
        value: v => v.note || null,
        input: {
          component: VTextarea,
          attrs: {
            rows: 4,
          },
        },
        filter: 'search',
      },
    ],
  }),

  commissionAdjustmentTypes: {
    label: ['Commission Adjustment Type', 'Commission Adjustment Types'],
    route: {
      path: '/commission-adjustment-types',
      name: 'commission-adjustment-types',
    },
    store: {
      state: 'commissionAdjustmentTypes',
      mutateOne: 'setCommissionAdjustmentType',
      mutateAll: 'setCommissionAdjustmentTypes',
      mutateRemove: 'removeCommissionAdjustmentType',
    },
    sortBy: 'abbr',
    api: {
      list: (api: ApiInterface) => api.commissions.adjustmentTypes.list,
      create: (api: ApiInterface) => api.commissions.adjustmentTypes.create,
      replace: (api: ApiInterface) => api.commissions.adjustmentTypes.replace,
      delete: (api: ApiInterface) => api.commissions.adjustmentTypes.delete,
    },
    fields: [...defaultFields()],
  },
  commissionAdjustments: {
    label: ['Commission Adjustment', 'Commission Adjustments'],
    icon: '$commission-adjustment',
    route: {
      path: '/commission-adjustments',
      name: 'commission-adjustments',
    },
    sortBy: 'commissionPayPeriod.abbr',
    sortDesc: true,
    api: {
      list: (api: ApiInterface) => api.commissions.adjustments.list,
      create: (api: ApiInterface) => api.commissions.adjustments.create,
      replace: (api: ApiInterface) => api.commissions.adjustments.replace,
      delete: (api: ApiInterface) => api.commissions.adjustments.delete,
    },
    fields: [
      idField<CommissionAdjustment>(),
      { ...commissionAdjustmentTypeField(), colAttrs: { 'col-md': 6 } },
      {
        id: 'orderId',
        castId: v => parseInt(v),
        label: 'Order ID',
        field: 'orderId',
        value: v => v.orderId,
        align: 'start',
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 6 },
        filter: 'list',
      },
      { ...companyField(), colAttrs: { 'col-md': 3 } },
      { ...salesAreaField(), colAttrs: { 'col-md': 4 } },
      { ...salespersonField(), colAttrs: { 'col-md': 5 } },
      commissionPayPeriodField(),
      getRefDataField<CommissionAdjustment>({
        id: 'commissionQuotaPeriodId',
        castId: v => parseInt(v),
        idGetter: item => item.commissionPayPeriod.commissionQuotaPeriodId,
        label: 'Quota Period',
        field: 'commissionPayPeriod.commissionQuotaPeriodId',
        value: v => v.commissionPayPeriod.commissionQuotaPeriodId,
        filter: 'list',
      }),
      commissionComponentField(),
      getRefDataField<CommissionAdjustment>({
        id: 'commissionComponentGroupId',
        castId: v => parseInt(v),
        idGetter: item => item.commissionComponent.groupId,
        label: 'Comp Group',
        field: 'commissionComponent.group.abbr',
        value: v => v.commissionComponent.group.abbr,
        filter: 'list',
      }),
      {
        ...currencyField(),
        colAttrs: { 'col-md': 3 },
      },
      {
        id: 'paymentAmount',
        castId: v => Number(v),
        label: 'Payout Amount',
        field: 'payoutAmount',
        value: v => v.payoutAmount,
        align: 'end',
        formatter: (value: number, item: CommissionAdjustment) =>
          currencyFormatter(item.currency)(value),
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 4 },
        filter: 'number',
      },
      {
        id: 'postedAmount',
        castId: v => Number(v),
        label: 'Posted Amount',
        field: 'postedAmount',
        value: v => v.postedAmount,
        align: 'end',
        formatter: (value: number, item: CommissionAdjustment) =>
          currencyFormatter(item.currency)(value),
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 5 },
        filter: 'number',
      },
      {
        id: 'note',
        label: 'Note',
        field: 'note',
        value: v => v.note,
        input: {
          component: VTextarea,
          attrs: {
            rows: 4,
          },
        },
        filter: 'search',
      },
    ],
  },

  commissionQuotas: {
    label: ['Commission Quota', 'Commission Quotas'],
    icon: '$commission-quota',
    route: {
      path: '/commission-quotas',
      name: 'commission-quotas',
    },
    sortBy: 'commissionQuotaPeriod.abbr',
    sortDesc: true,
    api: {
      list: (api: ApiInterface) => api.commissions.quotas.list,
      create: (api: ApiInterface) => api.commissions.quotas.create,
      replace: (api: ApiInterface) => api.commissions.quotas.replace,
      delete: (api: ApiInterface) => api.commissions.quotas.delete,
    },
    fields: [
      idField<CommissionQuotaPeriod>(),
      { ...companyField(), colAttrs: { 'col-md': 3 } },
      { ...salesAreaField(), colAttrs: { 'col-md': 4 } },
      { ...salespersonField(), colAttrs: { 'col-md': 5 } },
      commissionQuotaPeriodField(),
      commissionComponentField(),
      {
        ...currencyField(),
        colAttrs: { 'col-md': 4 },
      },
      {
        id: 'amount',
        castId: v => Number(v),
        label: 'Amount',
        field: 'amount',
        value: v => v.amount,
        align: 'end',
        formatter: (value: number, item: CommissionQuota) =>
          currencyFormatter(item.currency)(value),
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 8 },
        filter: 'number',
      },
    ],
  },

  commissionPayPeriodQuotas: {
    label: ['Commission Pay Period Quota', 'Commission Pay Period Quotas'],
    route: {
      path: '/commission-pay-period-quotas',
      name: 'commission-pay=period-quotas',
    },
    sortBy: 'commissionPayPeriod.abbr',
    sortDesc: true,
    api: {
      list: (api: ApiInterface) => api.commissions.payPeriodQuotas.list,
      create: (api: ApiInterface) => api.commissions.payPeriodQuotas.create,
      replace: (api: ApiInterface) => api.commissions.payPeriodQuotas.replace,
      delete: (api: ApiInterface) => api.commissions.payPeriodQuotas.delete,
    },
    fields: [
      idField<CommissionPayPeriod>(),
      { ...companyField(), colAttrs: { 'col-md': 3 } },
      { ...salesAreaField(), colAttrs: { 'col-md': 4 } },
      { ...salespersonField(), colAttrs: { 'col-md': 5 } },
      commissionPayPeriodField(),
      commissionComponentField(),
      {
        ...currencyField(),
        colAttrs: { 'col-md': 4 },
      },
      {
        id: 'amount',
        castId: v => Number(v),
        label: 'Amount',
        field: 'amount',
        value: v => v.amount,
        align: 'end',
        formatter: (value: number, item: CommissionPayPeriodQuota) =>
          currencyFormatter(item.currency)(value),
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 8 },
        filter: 'number',
      },
    ],
  },

  commissionPayoutRates: {
    label: ['Commission Payout Rate', 'Commission Payout Rates'],
    icon: '$commission-payout-rates',
    route: {
      path: '/commission-payout-rates',
      name: 'commission-payout-rates',
    },
    store: {
      state: 'commissionPayoutRates',
      mutateOne: 'setCommissionPayoutRate',
      mutateAll: 'setCommissionPayoutRates',
    },
    api: {
      list: (api: ApiInterface) => api.commissions.payouts.rates.list,
      create: (api: ApiInterface) => api.commissions.payouts.rates.create,
      replace: (api: ApiInterface) => api.commissions.payouts.rates.replace,
      delete: (api: ApiInterface) => api.commissions.payouts.rates.delete,
    },
    fields: [
      idField<CommissionQuotaPeriod>(),
      { ...companyField(), colAttrs: { 'col-md': 3 } },
      { ...salesAreaField(), colAttrs: { 'col-md': 4 } },
      { ...salespersonField(), colAttrs: { 'col-md': 5 } },
      { ...commissionQuotaPeriodField(), colAttrs: { 'col-md': 6 } },
      { ...commissionComponentField(), colAttrs: { 'col-md': 6 } },
      {
        ...currencyField(),
        colAttrs: { 'col-md': 4 },
      },
      {
        id: 'payoutRate',
        castId: v => Number(v),
        label: 'Payout Rate',
        field: 'payoutRate',
        value: v => v.payoutRate,
        align: 'end',
        formatter: (value: number) => percentFormatter(3)(value),
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 8 },
        filter: 'number',
      },
      {
        id: 'startAmount',
        castId: v => Number(v),
        label: 'Start Amount',
        field: 'startAmount',
        value: v => v.startAmount,
        align: 'end',
        formatter: (value: number, item: CommissionPayoutRate) =>
          currencyFormatter(item.currency)(value),
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 6 },
        filter: 'number',
      },
      {
        id: 'endAmount',
        castId: v => Number(v),
        label: 'End Amount',
        field: 'endAmount',
        value: v => v.endAmount,
        align: 'end',
        formatter: (value: number, item: CommissionPayoutRate) =>
          currencyFormatter(item.currency)(value),
        input: {
          component: VTextField,
          attrs: {
            type: 'number',
          },
        },
        colAttrs: { 'col-md': 6 },
        filter: 'number',
      },
      {
        id: 'note',
        label: 'Note',
        field: 'note',
        value: v => v.note || null,
        input: {
          component: VTextarea,
          attrs: {
            rows: 4,
          },
        },
        filter: 'search',
      },
    ],
  },
}

export const refData: RefDataItemWithId<unknown>[] = Object.entries(
  refDataObj
).map(([key, value]) => ({
  ...value,
  id: key,
}))
