var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{attrs:{"dense":""}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.closeable),expression:"closeable"}],staticStyle:{"z-index":"1"},attrs:{"icon":"","absolute":"","top":"","right":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("$close")])],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-icon',[_c('img',{staticStyle:{"width":"40px","height":"40px","margin-left":"-8px","margin-right":"-8px"},attrs:{"src":require('@/assets/logo/logo.svg')}})]),_c('v-list-item-content',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.$app.name)+" ")])],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$dashboard")])],1),_c('v-list-item-content',{staticClass:"text-truncate"},[_vm._v(" Dashboard ")]),_c('v-list-item-action',{staticClass:"my-0"},[_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-btn',{attrs:{"icon":"","small":"","exact":"","to":{
            name: 'dashboard-payout',
            params: {
              periodId:
                _vm.$route.params.periodId || new Date().getFullYear().toString(),
            },
          }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$commission-payout")])],1),_c('v-btn',{attrs:{"icon":"","small":"","exact":"","to":{
            name: 'dashboard-charts',
            params: {
              periodId:
                _vm.$route.params.periodId || new Date().getFullYear().toString(),
            },
          }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$dashbard-charts")])],1)],1)])],1),_c('v-list-item',{attrs:{"exact":"","to":{ name: 'orders-table' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$order-search")])],1),_c('v-list-item-content',{staticClass:"text-truncate"},[_vm._v(" Orders ")]),_c('v-list-item-action',{staticClass:"my-0"},[_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-btn',{attrs:{"icon":"","small":"","exact":""},on:{"click":function($event){$event.preventDefault();return _vm.onSearchOrder($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$search")])],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.searchOrderDialog),callback:function ($$v) {_vm.searchOrderDialog=$$v},expression:"searchOrderDialog"}},[_c('order-search-dialog',{on:{"close":function($event){_vm.searchOrderDialog = false}}})],1),_c('v-btn',{attrs:{"icon":"","small":"","exact":"","to":{ name: 'order-create' }}},[_c('v-icon',[_vm._v("$add")])],1)],1)])],1),_c('v-list-item',{attrs:{"exact":"","to":{ name: 'issues-table' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$issues-search")])],1),_c('v-list-item-content',{staticClass:"text-truncate"},[_vm._v(" Issues ")])],1),_c('v-list-item',{attrs:{"to":{ name: 'chart-explorer' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$chart-explorer")])],1),_c('v-list-item-content',{staticClass:"text-truncate"},[_vm._v(" Explore ")])],1),_c('v-list-item',{attrs:{"to":{ name: 'map-explorer' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$map-explorer")])],1),_c('v-list-item-content',{staticClass:"text-truncate"},[_vm._v(" Maps ")])],1),_c('v-list-item',{attrs:{"exact":"","to":{
      name: 'dashboard-backlog',
      params: {
        periodId:
          _vm.$route.params.periodId || new Date().getFullYear().toString(),
      },
    }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$backlog")])],1),_c('v-list-item-content',{staticClass:"text-truncate"},[_vm._v(" Backlog ")])],1),_c('v-menu',{attrs:{"bottom":"","right":"","nudge-right":"64"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$ref-data")])],1),_c('v-list-item-content',[_vm._v("Reference")]),_c('v-list-item-action',{staticClass:"my-0"},[_c('v-icon',[_vm._v("$arrow-right")])],1)],1)]}}]),model:{value:(_vm.menuRefData),callback:function ($$v) {_vm.menuRefData=$$v},expression:"menuRefData"}},[_c('v-card',{staticClass:"flex-card",staticStyle:{"max-height":"90vh"}},[_c('v-card-title',[_vm._v(" Reference Data ")]),_c('div',{staticStyle:{"position":"absolute","top":"16px","right":"16px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.refreshReferenceData}},[_c('v-icon',[_vm._v("$refresh")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.menuRefData = false}}},[_c('v-icon',[_vm._v("$close")])],1)],1),_c('v-divider'),_c('v-list',{staticClass:"flex-card-content",attrs:{"dense":""}},_vm._l((_vm.refData),function(refItem){return _c('v-list-item',{key:refItem.id,attrs:{"exact":"","to":{ name: refItem.route.name }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(refItem.icon || '$ref-data'))])],1),_c('v-list-item-content',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(Array.isArray(refItem.label) ? refItem.label[1] : refItem.label)+" ")])],1)}),1)],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'webpush' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$test")])],1),_c('v-list-item-content',{staticClass:"text-truncate"},[_vm._v(" Push Notifications ")])],1),_c('v-list-item',{attrs:{"to":{ name: 'about' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$about")])],1),_c('v-list-item-content',{staticClass:"text-truncate"},[_vm._v(" About ")])],1),(_vm.isInstallable)?_c('v-list-item',{on:{"click":function($event){return _vm.installApp()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$install")])],1),_c('v-list-item-content',{staticClass:"text-truncate"},[_vm._v(" Install ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }