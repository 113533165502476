var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.copy)?_c('v-form',[_c('v-card',{staticClass:"flex-card"},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('v-divider'),_c('v-container',{staticClass:"flex-card-content"},[_c('v-row',_vm._l((_vm.fields.filter(function (f) { return !!f.input; })),function(field){return _c('v-col',{key:field.id,class:Object.entries(field.colAttrs || {}).map(
              function (ref) {
                          var key = ref[0];
                          var value = ref[1];

                          return (key + "-" + value);
}
            ),attrs:{"cols":field.colAttrs && field.colAttrs.cols ? field.colAttrs.cols : 12}},[_c(field.input.component,_vm._b({tag:"component",attrs:{"value":field.input.value ? field.input.value(_vm.copy) : _vm.copy[field.field],"input-value":field.input.value ? field.input.value(_vm.copy) : _vm.copy[field.field],"items":field.input.items ? field.input.items(_vm.$store) : [],"label":field.input.label || field.label,"hide-details":""},on:{"change":function (v) { return field.input.onInput
                  ? field.input.onInput(_vm.copy)(v)
                  : _vm.$set(_vm.copy, field.field, v); }}},'component',field.input.attrs,false))],1)}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.isSaving,"disabled":_vm.isSaving},on:{"click":_vm.onSubmit}},[_vm._v(" Submit ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }