

































import Vue from 'vue'
import log from '@/log'

export default Vue.extend({
  data: () => ({
    refreshing: false,
  }),
  computed: {
    showNotification(): boolean {
      return !!this.swUpdatablePrompt || this.refreshing
    },
    swUpdatablePrompt(): CustomEventInit<ServiceWorkerRegistration> {
      return this.$store.state.swUpdatablePrompt
    },
  },
  methods: {
    installUpdate() {
      log.info('installUpdate')
      this.refreshing = true
      this.swUpdatablePrompt.detail?.waiting?.postMessage('SKIP_WAITING')
      this.$store.commit('setSwUpdatablePrompt', undefined)

      setTimeout(() => {
        window.location.reload()
      }, 1000 * 5)
    },
  },
})
