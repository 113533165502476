































































import Vue from 'vue'

export default Vue.extend({
  props: {
    value: [String, Number],
    align: {
      type: String,
      default: 'left',
    },

    label: String,
    placeholder: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      default: 'mdi-fullscreen',
    },
    right: Boolean,
    left: Boolean,

    readonly: Boolean,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    closeHandler(): void {
      this.dialog = false
    },
  },
})
