










































import Vue from 'vue'

import log from '@/log'

// components
import JTextField from '@/components/controls/JTextField.vue'

export default Vue.extend({
  components: {
    JTextField,
  },
  props: {
    // value
    value: String,

    // v-text-field props
    label: String,
    placeholder: String,

    filled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    hideDetails: Boolean,
    clearable: Boolean,

    readonly: Boolean,

    // v-menu props
    left: Boolean,
    right: Boolean,
    top: Boolean,
    bottom: Boolean,

    emitChange: Boolean,
  },
  data: () => ({
    menu: false,
  }),
  computed: {},
  methods: {
    doEmit(val: string | null, event: Event): void {
      const target = event.target as HTMLInputElement
      if (!target) throw new Error('event target null')

      if (!val) {
        target.value = (null as unknown) as string
        this.$emit('input', null)
        if (this.emitChange) this.$emit('change', null)
      } else {
        const date = Date.parse(val)

        if (Number.isNaN(date)) {
          log.error('invalid date')
          target.value = (null as unknown) as string
          this.$emit('input', null)
          if (this.emitChange) this.$emit('change', null)
        } else {
          const v = new Date(date).toISOString().split('T')[0]
          target.value = v
          this.$emit('input', v)
          if (this.emitChange) this.$emit('change', v)
        }
      }
    },
    onInput(val: string | undefined): void {
      this.$emit('input', val)
      if (this.emitChange) this.$emit('change', val)
      this.menu = false
    },
    onChange(val: string, event: Event): void {
      this.doEmit(val, event)
    },
  },
})
