




























































































































































import Vue, { PropType } from 'vue'

// controls
import JFormattedInput from '@/components/controls/JFormattedInput.vue'

// models
import Order from '@/models/Orders/Order'

export default Vue.extend({
  components: {
    JFormattedInput,
  },
  props: {
    order: Object as PropType<Order>,
    readonly: Boolean,
  },
  data: () => ({
    shippingAmountDialog: false,
    estTaxRateDialog: false,
  }),
})
