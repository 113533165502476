const padStart = (str: string, length: number, pad = '0') =>
  (pad.repeat(length) + str).slice(length * -1)

export const getToday = () => {
  const d = new Date()
  const yyyy = d.getFullYear().toString()
  const mm = padStart((d.getMonth() + 1).toString(), 2)
  const dd = padStart(d.getDate().toString(), 2)
  return `${yyyy}-${mm}-${dd}`
}

export const dateDiff = (inDate1: Date | number, inDate2?: Date | number) => {
  const date1 = new Date(inDate1)
  const date2 = new Date(inDate2 || new Date().setUTCHours(0, 0, 0, 0))

  // To calculate the time difference of two dates
  const timeDiff = date2.getTime() - date1.getTime()

  // To calculate the no. of days between two dates
  const dayDiff = timeDiff / (1000 * 60 * 60 * 24)

  return Math.round(dayDiff)
}

export const sortDate = (a: string | Date, b: string | Date) =>
  new Date(a).getTime() - new Date(b).getTime()
