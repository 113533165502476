



































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'

// message bus
import MessageBus from '@/message-bus'

// components
import { Marker } from '@/components/maps/AddressMap.vue'
import AddressMap from '@/components/maps/AddressMap.vue'

// types
import { Customer, GeocodeResponse, GeocodeResult } from '@/types'

type ApiResponse = GeocodeResponse

type MapMarker = Marker<GeocodeResult>

export default Vue.extend({
  components: {
    AddressMap,
  },
  props: {
    customerId: Number,
  },
  data() {
    return {
      search: '',
      results: undefined as ApiResponse | undefined,
      isSearching: false,

      current: null as number | null, // index of markers
      selected: null as GeocodeResult | null,
      addressProps: [
        'street',
        'city',
        'state',
        'zip',
        'county',
        'country',
        'location',
      ],
      isSubmitting: false,
    }
  },
  computed: {
    ...mapGetters(['customers']),
    customer(): Customer {
      return this.customers.find((z: Customer) => z.id === this.customerId)
    },
    mapMarkers(): MapMarker[] {
      if (this.results && this.results.results) {
        return this.results.results
          .map(z => ({
            location: z.location,
            title: z.displayAddress,
            caption: z.source,
            icon: this.accuracyTypes[z.type.toLowerCase()] || '$info',
            data: z,
          }))
          .reverse()
      }

      return []
    },
    accuracyTypes(): { [key: string]: string } {
      return {
        rooftop: 'mdi-map-marker-outline', //	The exact point was found with rooftop level accuracy
        point: 'mdi-map-marker-question-outline', //	The exact point was found from address range interpolation where the range contained a single point
        // eslint-disable-next-line @typescript-eslint/camelcase
        range_interpolation: 'mdi-map-marker-radius-outline', //	The point was found by performing address range interpolation
        // eslint-disable-next-line @typescript-eslint/camelcase
        nearest_rooftop_match: 'mdi-map-marker-distance', //	The exact house number was not found, so a close, neighboring house number was used instead
        intersection: 'mdi-map-marker-remove-outline', //	The result is an intersection between two streets
        // eslint-disable-next-line @typescript-eslint/camelcase
        street_center: 'mdi-map-marker-alert-outline', //	The result is a geocoded street centroid
        place: 'mdi-map', //	The point is a city/town/place zip code centroid
        county: 'mdi-map', //	The point is a county centroid
        state: 'mdi-map', //	The point is a state centroid
      }
    },
  },
  methods: {
    clear() {
      this.current = null
      this.selected = null
      this.search = ''
      this.results = undefined
    },
    onSubmit() {
      this.isSubmitting = true

      const input = this.selected

      if (!input) {
        console.warn('no address selected', input)
        MessageBus.error('no address selected')
        return
      }

      this.$api.customers.addresses
        .create(this.customerId, input)
        .then(a => {
          this.$store.commit('insertCustomerAddress', a)
          this.clear()
          MessageBus.success(`Added: ${a.displayAddress}!`)
          this.$emit('success', a)
        })
        .catch(err => {
          console.error(err)
          MessageBus.error(err)
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    doSearch() {
      if (!this.search.trim()) {
        MessageBus.error('search is empty')
        return
      }

      this.isSearching = true

      this.$api.geocode
        .get(this.search)
        .then(res => {
          this.results = res
        })
        .catch(err => {
          console.error(err)
          MessageBus.error(err)
        })
        .finally(() => {
          this.isSearching = false
        })
    },
    selectLocation(location: GeocodeResult) {
      this.selected = location
    },
    selectMapMarker(m: MapMarker) {
      if (!m || !m.data) {
        console.error('Marker is missing required "data"', m)
        MessageBus.error('Marker is missing required data')
        return
      }

      this.selected = m.data
    },
  },
})
