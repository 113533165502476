


























import Vue, { PropType } from 'vue'
import VueSelect from 'vue-select'

import JIconCloseSmall from '@/components/controls/JIconCloseSmall.vue'

interface Item {
  [key: string]: unknown
}

export default Vue.extend({
  components: {
    VueSelect,
  },
  props: {
    label: String,

    value: [String, Number, Object],
    items: Array as PropType<Item[]>,
    itemValue: {
      type: String as PropType<keyof Item>,
      default: 'id',
    },
    itemText: {
      type: String,
      default: 'name',
    },
    returnObject: Boolean,

    clearable: Boolean,
    readonly: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    components: {
      Deselect: JIconCloseSmall,
    },
  }),
  computed: {
    calcValue(): string | number {
      return !this.value
        ? this.value === 0
          ? this.value
          : undefined
        : this.returnObject
        ? this.value[this.itemValue]
        : this.value
    },
    hasLabel(): boolean {
      return !!this.label
    },
  },
  methods: {
    onInput(value: unknown): void {
      if (this.returnObject) {
        this.$emit(
          'input',
          this.items.find(item => item[this.itemValue] == value)
        )
      } else {
        this.$emit('input', value)
      }
    },
    onClearableClick(): void {
      this.$emit('input', null)
      this.$emit('click:clear')
    },
    onAppendClick(): void {
      this.$emit('click:append')
    },
  },
})
