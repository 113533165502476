import urljoin from 'url-join'

// utils
import mimetypes, { MimetypeRef, getMimeTypeRef } from '@/utils/mimetype'

// types
import { StorageObjectInput } from '@/types'

const STORAGE_URL = process.env.VUE_APP_STORAGE_BASE_URL

export class StorageObject {
  id: string
  bucket: string
  size: number // bytes
  mimetype: string // mime type
  name: string
  desc?: string
  location: string
  locationDepth: number
  token: string
  metadata: unknown
  createdAt: Date
  updatedAt: Date

  constructor(input: StorageObjectInput) {
    const {
      id,
      bucket,
      size,
      mimetype,
      name,
      desc,
      location,
      locationDepth,
      token,
      metadata,
      createdAt,
      updatedAt,
    } = input

    this.id = id
    this.bucket = bucket
    this.size = size
    this.mimetype = mimetype
    this.name = name
    this.desc = desc
    this.location = location
    this.locationDepth = locationDepth
    this.token = token
    this.metadata = metadata
    this.createdAt = new Date(createdAt)
    this.updatedAt = new Date(updatedAt)
  }

  private get mimetypeRef(): MimetypeRef {
    return getMimeTypeRef(this.mimetype as keyof typeof mimetypes)
  }

  get icon() {
    return this.mimetypeRef.icon
  }

  get displayType() {
    return this.mimetypeRef.name
  }

  get href() {
    const name = encodeURIComponent(this.name).replaceAll(' ', '+')

    return urljoin(
      STORAGE_URL,
      this.bucket,
      this.location,
      this.id,
      name,
      `?token=${this.token}`
    )
  }

  public toJSON(): StorageObjectInput {
    return {
      id: this.id,
      bucket: this.bucket,
      mimetype: this.mimetype,
      name: this.name,
      desc: this.desc,
      size: this.size,
      location: this.location,
      locationDepth: this.locationDepth,
      token: this.token,
      updatedAt: this.updatedAt.toISOString(),
      createdAt: this.createdAt.toISOString(),
    }
  }

  public toFreezeJSON() {
    return {
      ...this.toJSON(),
      mimetypeRef: this.mimetypeRef,
      icon: this.icon,
      displayType: this.displayType,
      href: this.href,
    }
  }

  public static fromJSON(input: StorageObjectInput) {
    return new StorageObject(input)
  }
}

export default StorageObject
