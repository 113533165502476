
































































































import Vue from 'vue'

import MessageBus from '@/message-bus'

// types
import { Audit } from '@/types'

export default Vue.extend({
  props: {
    table: String,
    tableId: Number,
  },
  data: () => ({
    isFetching: false,
    hasFetched: false,

    history: [] as Audit[],

    dialog: undefined as Audit | undefined,
  }),
  mounted() {
    this.fetch(this.table, this.tableId)
  },
  methods: {
    fetch(table: string, tableId: number) {
      this.isFetching = true

      this.$api.audit
        .list(table, tableId)
        .then(items => {
          this.history = items
        })
        .catch(err => {
          MessageBus.error(err)
        })
        .finally(() => {
          this.hasFetched = true
          this.isFetching = false
        })
    },
  },
})
