





















































import Vue, { PropType } from 'vue'
import { DataTableHeader } from 'vuetify'
import cloneDeep from 'lodash.clonedeep'

// components
import IssueEdit from '@/components/issues/IssueEdit.vue'

// models
import Issue from '@/models/Issues/Issue'

// helpers
import { getToday } from '@/utils/jad-date'
import { UUID } from '@/types'
import StorageObject from '@/models/StorageObject'

const DEFAULT_ISSUE = (additionalDefaults = {}) =>
  Issue.fromJSON({
    id: undefined,
    createdDate: getToday(),
    amount: 0,
    currency: 'USD',
    ...additionalDefaults,
  })

const extendedHeaders: DataTableHeader[] = [
  { text: 'Sales Area', value: 'salesArea.abbr' },
  { text: 'Salesperson', value: 'salesperson.username' },
  { text: 'Company', value: 'company.abbr' },
  { text: 'Customer', value: 'customer.abbr' },
  { text: 'Order ID', value: 'orderId' },
  { text: 'CCY', value: 'currency' },
]

export default Vue.extend({
  components: {
    IssueEdit,
  },
  props: {
    items: Array as PropType<Issue[]>,

    dense: Boolean,
    loading: Boolean,
    hideDefaultFooter: Boolean,
    extendedColumns: Boolean,

    search: String,

    itemsPerPage: {
      type: Number,
      default: 50,
    },
  },
  data: () => ({
    editItem: undefined as Partial<Issue> | undefined,

    isDeleting: false,

    sortBy: 'updatedAt',
    sortDesc: true,
  }),
  computed: {
    dialogEdit(): boolean {
      return !!this.editItem
    },
    headers(): DataTableHeader[] {
      return [
        { text: 'ID', value: 'id', class: 'px-2', cellClass: 'px-2' },
        {
          text: 'Created',
          value: 'createdDate',
          align: 'center',
          class: 'px-2',
          cellClass: 'px-2 text-truncate',
        },
        {
          text: 'Age',
          value: 'age',
          align: 'end',
          class: 'px-2',
          cellClass: 'px-2 text-truncate',
        },
        {
          text: 'Resolved',
          value: 'resolvedDate',
          align: 'center',
          class: 'px-2',
          cellClass: 'px-2 text-truncate',
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'end',
          class: 'px-2',
          cellClass: 'px-2',
        },
        { text: 'Note', value: 'note', class: 'px-2', cellClass: 'px-2' },
        {
          text: 'Pay Period',
          value: 'commissionPayPeriod.abbr',
          align: 'center',
          class: 'px-2',
          cellClass: 'px-2 text-truncate',
        },
        {
          text: 'Comm Comp',
          value: 'commissionComponent.abbr',
          align: 'start',
          class: 'px-2',
          cellClass: 'px-2 text-truncate',
        },

        ...(this.extendedColumns ? extendedHeaders : []),
      ]
    },
  },
  methods: {
    close(): void {
      this.editItem = undefined
    },
    onUploadedAttachments({
      issue,
      attachments,
    }: {
      issue: Issue
      attachments: StorageObject[]
    }): void {
      const frozenItem = this.items.find(z => z.id === issue.id)

      if (frozenItem) {
        const item = Issue.fromJSON({
          ...frozenItem,
          attachments: [
            ...frozenItem.attachments,
            ...attachments.map(attachment => attachment.toJSON()),
          ],
        })

        this.$emit('save', item)
      }
    },
    onDeletedAttachment({
      issue,
      attachmentId,
    }: {
      issue: Issue
      attachmentId: UUID
    }): void {
      const frozenItem = this.items.find(z => z.id === issue.id)

      if (frozenItem) {
        const attachmentIndex = frozenItem.attachments.findIndex(
          attachment => attachment.id === attachmentId
        )

        if (attachmentIndex >= 0) {
          const item = Issue.fromJSON({
            ...frozenItem,
            attachments: [
              ...frozenItem.attachments.slice(0, attachmentIndex),
              ...frozenItem.attachments.slice(attachmentIndex + 1),
            ],
          })

          this.$emit('save', item)
        }
      }
    },
    setEditItem(item: Issue): void {
      this.editItem = cloneDeep(item.toEditItem())
    },
    createIssue(defaults = {}): void {
      this.setEditItem(DEFAULT_ISSUE(defaults))
    },
    onSave(item: Issue): void {
      this.$emit('save', item)
      this.close()
    },
    onDelete(itemId: number): void {
      this.$emit('delete', itemId)
      this.close()
    },
    scrollToTop() {
      this.$vuetify.goTo('.jad-data-table tbody tr', {
        offset: 50,
        container: '.v-data-table__wrapper',
      })
    },
  },
})
