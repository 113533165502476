import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      light: {
        // https://colorhunt.co/palette/253093
        primary: '#16c79a',
        secondary: '#19456b',
        accent: '#11698e',
        anchor: '#11698e',
        background: '#f8f1f1',
        dev: '#f57c00',

        error: '#E53D00',
        info: '#19456b',
        success: '#16C79A',
        warning: '#E8EC67',
      },
    },
  },
  icons: {
    values: {
      home: 'mdi-home-outline',
      about: 'mdi-information-outline',
      dashboard: 'mdi-view-dashboard-outline',

      test: 'mdi-test-tube',

      order: 'mdi-text-box-outline',
      orders: 'mdi-text-box-multiple-outline',
      'order-create': 'mdi-text-box-plus-outline',
      'order-search': 'mdi-text-box-search-outline',
      'orders-search': 'mdi-text-box-search-outline',
      'order-item': 'mdi-microscope',
      'order-items': 'mdi-microscope',
      'order-adjustment': 'mdi-percent',
      'order-adjustments': 'mdi-percent',
      'order-tag': 'mdi-tag-outline',
      'order-tags': 'mdi-tag-multiple-outline',
      'order-tag-add': 'mdi-tag-plus-outline',

      issue: 'mdi-sticker-alert-outline',
      issues: 'mdi-sticker-alert-outline',
      'issues-search': 'mdi-sticker-alert-outline',

      'ref-data': 'mdi-table',

      customer: 'mdi-office-building-outline',
      customers: 'mdi-office-building-outline',

      product: 'mdi-microscope',
      products: 'mdi-microscope',

      attachment: 'mdi-paperclip',
      attachments: 'mdi-paperclip',

      salesperson: 'mdi-account-tie',
      salespersons: 'mdi-account-tie',

      salesArea: 'mdi-group',
      'sales-area': 'mdi-group',
      salesAreas: 'mdi-group',
      'sales-areas': 'mdi-group',

      company: 'mdi-factory',
      companies: 'mdi-factory',

      currency: 'mdi-currency-eur',
      currencies: 'mdi-currency-eur',

      'commission-adjustment': 'mdi-adjust',
      'commission-adjustments': 'mdi-adjust',

      'commission-pay-period': 'mdi-calendar',
      'commission-pay-periods': 'mdi-calendar',

      'commission-quota-period': 'mdi-calendar-blank',
      'commission-quota-periods': 'mdi-calendar-blank',

      'commission-quota': 'mdi-chart-gantt',
      'commission-quotas': 'mdi-chart-gantt',
      'commission-quota-attainment': 'mdi-chart-gantt',
      'commission-payout': 'mdi-cash',
      'commission-payment': 'mdi-cash',
      'commission-payments': 'mdi-cash',
      'commission-payout-rate': 'mdi-percent',
      'commission-payout-rates': 'mdi-percent',

      'dashbard-charts': 'mdi-chart-line',
      'chart-explorer': 'mdi-compass-outline',
      'map-explorer': 'mdi-map-search-outline',

      backlog: 'mdi-package-variant',

      edit: 'mdi-pencil',
      add: 'mdi-plus',
      search: 'mdi-magnify',
      filter: 'mdi-filter',
      save: 'mdi-content-save-outline',
      delete: 'mdi-delete',
      refresh: 'mdi-refresh',
      download: 'mdi-download',
      share: 'mdi-share-variant',
      settings: 'mdi-cog',
      calendar: 'mdi-calendar',
      csv: 'mdi-microsoft-excel',

      table: 'mdi-table',
      chart: 'mdi-chart-bar',
      image: 'mdi-image-outline',
      map: 'mdi-map',

      info: 'mdi-information-outline',

      prev: 'mdi-chevron-left',
      next: 'mdi-chevron-right',

      'arrow-left': 'mdi-chevron-left',
      'arrow-right': 'mdi-chevron-right',
      'arrow-up': 'mdi-chevron-up',
      'arrow-down': 'mdi-chevron-down',

      install: 'mdi-download-outline',
    },
  },
})
