// models
import { StorageObject } from '@/models/StorageObject'
import Order from '@/models/Orders/Order'

// utils
import { dateDiff } from '@/utils/jad-date'

// types
import {
  ID,
  DateOnly,
  Company,
  Customer,
  CustomerAddress,
  SalesArea,
  Salesperson,
  StorageObjectFull,
  CommissionComponent,
  CommissionPayPeriod,
  IssueInput,
} from '@/types'

export class Issue {
  readonly id?: ID

  readonly createdDate: DateOnly
  readonly resolvedDate?: DateOnly
  readonly note?: string

  readonly amount: number
  readonly currency: string

  readonly company?: Company
  readonly customer?: Omit<Customer, 'addresses'> & {
    addresses: readonly CustomerAddress[]
  }
  readonly salesArea?: SalesArea
  readonly salesperson?: Salesperson

  readonly commissionComponent?: CommissionComponent
  readonly commissionPayPeriod?: CommissionPayPeriod

  readonly orderId?: ID
  readonly order?: Order

  readonly attachments: ReadonlyArray<StorageObjectFull>

  readonly updatedAt?: string // Date
  readonly createdAt?: string // Date
  readonly deletedAt?: string // Date

  readonly fetchedAt: string // Date

  private constructor(issueInput: IssueInput) {
    const {
      id,
      currency,
      amount,

      company,
      customer,
      salesArea,
      salesperson,
      commissionComponent,
      commissionPayPeriod,
      order,
      orderId,

      note,

      createdDate,
      resolvedDate,

      attachments,

      updatedAt,
      createdAt,
      deletedAt,
    } = issueInput

    this.id = id

    this.currency = currency
    this.amount = amount

    this.createdDate = createdDate
    this.resolvedDate = resolvedDate

    this.commissionComponent = commissionComponent
    this.commissionPayPeriod = commissionPayPeriod

    this.order = order
    this.orderId = orderId

    this.company = company
    this.customer = customer
    this.salesArea = salesArea
    this.salesperson = salesperson

    this.note = note

    this.updatedAt = updatedAt
    this.createdAt = createdAt
    this.deletedAt = deletedAt

    this.fetchedAt = new Date().toISOString()

    this.attachments = (attachments || []).map(a =>
      StorageObject.fromJSON(a).toFreezeJSON()
    )
  }

  get age(): number {
    return dateDiff(
      Date.parse(this.createdDate),
      this.resolvedDate ? Date.parse(this.resolvedDate) : undefined
    )
  }

  public toEditItem() {
    return {
      id: this.id,

      createdDate: this.createdDate,
      resolvedDate: this.resolvedDate,
      note: this.note,

      amount: this.amount,
      currency: this.currency,

      companyId: this.company?.id,
      customerId: this.customer?.id,
      salesAreaId: this.salesArea?.id,
      salespersonId: this.salesperson?.id,
      commissionComponentId: this.commissionComponent?.id,
      commissionPayPeriodId: this.commissionPayPeriod?.id,

      orderId: this.orderId,

      attachments: this.attachments,
    }
  }

  public static fromJSON(input: IssueInput) {
    return new Issue(input)
  }
}

export default Issue
