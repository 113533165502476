import { ID } from '@/types'

export type UserProfile = {
  email: string
  email_verified: boolean
  name: string
  nickname: string
  picture: string
  sub: string
  updated_at: string

  'https://sales.jeffdelaney.com/app_metadata': {
    userId: ID
  }[]
  'https://sales.jeffdelaney.com/user_metadata': {
    avatarUrl?: string
  }[]
}

export const transformUser = (profile: UserProfile) => {
  return {
    id: profile['https://sales.jeffdelaney.com/app_metadata'][0].userId,
    username: profile.email,
    name: profile.nickname,
    firstName: profile.name,
    lastName: profile.name,
    avatarUrl:
      profile['https://sales.jeffdelaney.com/user_metadata'][0].avatarUrl,
  }
}
