import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

// route data
import { refData } from '@/components/refdata/ref-data'

// components
import Base from '@/views/Base.vue'
import Container from '@/components/formats/Container.vue'
import PageNotFound from '@/components/PageNotFound.vue'
import OrderEdit from '@/components/orders/OrderEdit/OrderEdit.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'base',
    component: Base,
    children: [
      {
        path: '/',
        name: 'base-container',
        component: Container,
        props: {
          fixedHeight: false,
        },
        children: [
          {
            path: '/webpush',
            name: 'webpush',
            component: () => import('@/components/webpush/TempWebPush.vue'),
          },
          {
            path: '/explorer',
            name: 'chart-explorer',
            component: () => import('@/components/explorer/ChartExplorer.vue'),
          },
          {
            path: '/maps',
            name: 'map-explorer',
            component: () => import('@/components/explorer/MapExplorer.vue'),
          },

          {
            path: '/',
            name: 'home',
            redirect: { name: 'dashboard' },
          },
          {
            path: '/about',
            name: 'about',
            component: () =>
              import(/* webpackChunkName: "about" */ '@/views/About.vue'),
          },
          {
            path: '/document/:docId',
            name: 'document',
            props: true,
            component: () => import('@/components/documents/DocumentView.vue'),
          },

          {
            path: '/orders/:orderId',
            name: 'order',
            props: true,
            redirect: { name: 'order-edit' },
          },
          {
            path: '/orders/new',
            name: 'order-create',
            props: {
              orderId: 'new',
            },
            component: () =>
              import('@/components/orders/OrderEdit/OrderEdit.vue'),
          },
          {
            path: '/orders/:orderId',
            name: 'order-edit',
            props: true,
            component: OrderEdit,
          },
        ],
      },
      {
        path: '/',
        name: 'base-fixed-container',
        component: Container,
        props: {
          fixedHeight: true,
          fluid: true,
        },
        children: [
          {
            path: '/orders',
            name: 'orders-table',
            component: () => import('@/components/orders/OrdersCard.vue'),
          },

          {
            path: '/order-details',
            name: 'order-details',
            props: route => ({
              query: route.query,
            }),
            component: () => import('@/components/orders/OrderDetailsCard.vue'),
          },

          {
            path: '/issues',
            name: 'issues-table',
            component: () =>
              import('@/components/issues/IssuesCardWrapper.vue'),
          },

          {
            path: '/customers/:customerId',
            name: 'customer',
            props: true,
            component: () => import('@/components/customers/CustomerView.vue'),
          },

          ...refData
            .filter(r => !!r.fields && r.fields.length > 0)
            .map(r => ({
              path: `ref-data/${r.route.path}`,
              name: r.route.name,
              props: {
                id: r.id,
                label: r.label,
                icon: r.icon,
                'store-state': r.store?.state,
                mutateOne: r.store?.mutateOne,
                mutateAll: r.store?.mutateAll,
                mutateRemove: r.store?.mutateRemove,
                list: r.api.list,
                create: r.api.create,
                replace: r.api.replace,
                delete: r.api.delete,
                fields: r.fields,
                to: r.to,
                toField: r.toField,
                sortBy: r.sortBy,
                sortDesc: r.sortDesc,
              },
              component: () => import('@/components/refdata/RefItemsList.vue'),
            })),
        ],
      },
    ],
  },

  // Base with tabs
  {
    path: '/',
    name: 'base-tabs',
    component: Base,
    props: {
      tabs: [
        {
          to: { name: 'dashboard-attainment' },
          name: 'Attainment',
          icon: '$commission-quota-attainment',
        },
        {
          to: { name: 'dashboard-payout' },
          name: 'Payout',
          icon: '$commission-payout',
        },
        {
          to: { name: 'dashboard-charts' },
          name: 'Charts',
          icon: '$dashbard-charts',
        },
        {
          to: { name: 'dashboard-backlog' },
          name: 'Backlog',
          icon: '$backlog',
        },
      ],
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        redirect: ({ params, query }) => ({
          name: 'dashboard-attainment',
          params: {
            ...params,
            periodId: new Date().getFullYear().toString(),
          },
          query,
        }),
      },
      {
        path: '/dashboard/:periodId',
        name: 'dashboard-period',
        props: route => ({
          ...route.params,
          fluid: route.name === 'dashboard-charts',
        }),
        component: () => import('@/components/dashboard/DashboardView.vue'),
        children: [
          {
            path: 'attainment',
            name: 'dashboard-attainment',
            component: () =>
              import(
                '@/components/dashboard/attainment/CommissionQuotaAttainment.vue'
              ),
          },
          {
            path: 'payout',
            name: 'dashboard-payout',
            props: true,
            component: () =>
              import('@/components/dashboard/payout/CommissionPayout.vue'),
          },
          {
            path: 'payout/detail',
            name: 'dashboard-payout-detail',
            props: true,
            component: () =>
              import(
                '@/components/dashboard/payout/CommissionPayoutDetail.vue'
              ),
          },
          {
            path: 'charts',
            name: 'dashboard-charts',
            props: true,
            redirect: () => ({
              name: 'dashboard-charts-sales',
            }),
            component: () =>
              import('@/components/dashboard/charts/DashboardCharts.vue'),
            children: [
              {
                path: 'sales',
                name: 'dashboard-charts-sales',
                props: true,
                component: () =>
                  import(
                    '@/components/dashboard/charts/sales/SalesByPostedDateCharts.vue'
                  ),
              },
              {
                path: 'commission-pay-period',
                name: 'dashboard-charts-commission-pay-periods',
                props: true,
                component: () =>
                  import(
                    '@/components/dashboard/charts/by_period/SalesByCommissionPayPeriodCharts.vue'
                  ),
              },
              {
                path: 'customer-types',
                name: 'dashboard-charts-customer-types',
                props: true,
                component: () =>
                  import(
                    '@/components/dashboard/charts/customer_types/SalesByCustomerTypeCharts.vue'
                  ),
              },
              {
                path: 'customers',
                name: 'dashboard-charts-customers',
                props: true,
                component: () =>
                  import(
                    '@/components/dashboard/charts/customers/SalesByCustomerCharts.vue'
                  ),
              },
              {
                path: 'locations',
                name: 'dashboard-charts-locations',
                props: true,
                component: () =>
                  import(
                    '@/components/dashboard/charts/locations/SalesByLocationCharts.vue'
                  ),
              },
              {
                path: 'product-categories',
                name: 'dashboard-charts-product-categories',
                props: true,
                component: () =>
                  import(
                    '@/components/dashboard/charts/product_categories/SalesByProductCategoryCharts.vue'
                  ),
              },
              {
                path: 'products',
                name: 'dashboard-charts-products',
                props: true,
                component: () =>
                  import(
                    '@/components/dashboard/charts/products/SalesByProductCharts.vue'
                  ),
              },
              {
                path: 'products/unit-price',
                name: 'dashboard-charts-products-unit-price',
                props: true,
                component: () =>
                  import(
                    '@/components/dashboard/charts/unit_price/UnitPriceByProductChart.vue'
                  ),
              },
              {
                path: 'products/timeline',
                name: 'dashboard-charts-timeline',
                props: true,
                component: () =>
                  import(
                    '@/components/dashboard/charts/timeline/SalesTimelineCharts.vue'
                  ),
              },
            ],
          },
          {
            path: 'backlog',
            name: 'dashboard-backlog',
            props: true,
            component: () =>
              import('@/components/dashboard/backlog/Backlog.vue'),
          },
        ],
      },
    ],
  },

  // default route, when none of the above matches:
  { path: '*', component: PageNotFound },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
})

export default router
