

































import Vue, { PropType } from 'vue'

import { User } from '@/types'

export default Vue.extend({
  props: {
    user: Object as PropType<User>,
  },
  data: () => ({}),
  computed: {},
  methods: {
    logout(): void {
      this.$auth.logout()
    },
  },
})
