var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"j-table",class:{ dense: _vm.dense, filled: _vm.filled, narrow: _vm.narrow }},[_vm._t("thead",[(_vm.headers)?_c('thead',{staticClass:"j-table-thead"},[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:header.id || header.value,class:{
            'text-left': header.align === 'left',
            'text-center': header.align === 'center',
            'text-right': header.align === 'right',
          },style:({
            width: header.width,
          })},[(header.icon)?_c('v-icon',{attrs:{"small":header.smallIcon},domProps:{"textContent":_vm._s(header.icon)}}):_vm._e(),_vm._v(" "+_vm._s(header.text)+" ")],1)}),0)]):_vm._e()]),_c('tbody',{ref:"tbody",staticClass:"j-table-tbody"},[_vm._l((_vm.items),function(item,i){return [_vm._t("default",[_c('tr',{key:item[_vm.itemKey]},_vm._l((_vm.headers),function(header){return _c('td',{key:header.id || header.value,class:{
              'text-left': header.align === 'left',
              'text-center': header.align === 'center',
              'text-right': header.align === 'right',
            }},[_vm._t(("item." + (header.id || header.value)),[_vm._v(" "+_vm._s(_vm.evalValue(item, header.value, header.format))+" ")],{"item":item,"header":header,"value":item[header.value]})],2)}),0)],{"item":item,"index":i,"headers":_vm.headers,"items":_vm.items})]})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }