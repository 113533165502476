import Vue from 'vue'

import { isString } from './utils/type-helpers'

export const MessageBus = new Vue({
  methods: {
    success(message: string): void {
      this.$emit('message', {
        text: message,
        color: 'success',
        icon: '$success',
      })
    },
    error(error: Error | string): void {
      this.$emit('message', {
        text: isString(error) ? error : error.message || error,
        color: 'error',
        icon: '$error',
      })
    },
    info(message: string): void {
      this.$emit('message', { text: message, icon: '$info' })
    },
  },
})
export default MessageBus
