




























import Vue from 'vue'

// utils
import { JMathEval } from '@/utils/JMathEval'

export default Vue.extend({
  props: {
    value: Number,
    label: String,
  },
  data: () => ({
    expression: '' as string,
  }),
  computed: {
    result(): number | undefined {
      if (!this.expression) return undefined

      let result: number

      try {
        result = JMathEval(this.expression)
      } catch {
        return this.value
      }

      return result
    },
  },
  methods: {
    close(): void {
      this.$emit('close')
    },
    onCancel(): void {
      this.close()
    },
    onDone(): void {
      this.$emit('input', this.result)
      this.close()
    },
  },
  created() {
    this.expression = this.value ? this.value.toString() : ''
  },
})
