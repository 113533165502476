export type MimetypeRef = {
  ext: string | string[]
  name: string
  icon: string
  desc: string
}

const MIME_UNKNOWN: MimetypeRef = {
  ext: 'unknown',
  icon: 'mdi-file',
  name: 'File',
  desc: '',
}

const icons = {
  image: 'mdi-image',
  video: 'mdi-video',
  audio: 'mdi-volume-high',
  csv: 'mdi-microsoft-excel',
  msexcel: 'mdi-microsoft-excel',
  msword: 'mdi-microsoft-word',
  mspowerpoint: 'mdi-microsoft-powerpoint',
  pdf: 'mdi-adobe-acrobat',
  archive: 'mdi-folder-zip',
  mail: 'mdi-email',
  text: 'mdi-text',
}

const mimetypes = {
  // .aac	AAC audio	audio/aac
  // .abw	AbiWord document	application/x-abiword
  // .arc	Archive document (multiple files embedded)	application/x-freearc
  // .avi	AVI: Audio Video Interleave	video/x-msvideo
  // .azw	Amazon Kindle eBook format	application/vnd.amazon.ebook
  // .bin	Any kind of binary data	application/octet-stream

  // .bmp	Windows OS/2 Bitmap Graphics	image/bmp
  'image/bmp': {
    ext: 'bmp',
    name: 'Bitmap',
    icon: icons.image,
    desc: 'Windows OS/2 Bitmap Graphics',
  },

  // .bz	BZip archive	application/x-bzip
  // .bz2	BZip2 archive	application/x-bzip2
  // .csh	C-Shell script	application/x-csh
  // .css	Cascading Style Sheets (CSS)	text/css

  // .csv	Comma-separated values (CSV)	text/csv
  'text/csv': {
    ext: 'csv',
    name: 'CSV',
    icon: icons.csv,
    desc: 'Comma-separated values (CSV)',
  },

  // .doc	Microsoft Word	application/msword
  'application/msword': {
    ext: 'doc',
    name: 'Microsoft Word',
    icon: icons.msword,
    desc: 'Microsoft Word',
  },

  // .docx	Microsoft Word (OpenXML)	application/vnd.openxmlformats-officedocument.wordprocessingml.document
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    ext: 'docx',
    name: 'Microsoft Word',
    icon: icons.msword,
    desc: 'Microsoft Word (OpenXML)',
  },

  // .eot	MS Embedded OpenType fonts	application/vnd.ms-fontobject
  // .epub	Electronic publication (EPUB)	application/epub+zip
  // .gz	GZip Compressed Archive	application/gzip

  // .gif	Graphics Interchange Format (GIF)	image/gif
  'image/gif': {
    ext: 'gif',
    name: 'GIF',
    icon: icons.image,
    desc: 'Graphics Interchange Format (GIF)',
  },

  // .htm
  // .html	HyperText Markup Language (HTML)	text/html

  // .ico	Icon format	image/vnd.microsoft.icon
  'image/vnd.microsoft.icon': {
    ext: 'ico',
    name: 'Icon',
    icon: icons.image,
    desc: 'Icon format',
  },

  // .ics	iCalendar format	text/calendar
  // .jar	Java Archive (JAR)	application/java-archive

  // .jpeg
  // .jpg	JPEG images	image/jpeg
  'image/jpeg': {
    ext: ['jpeg', 'jpg'],
    name: 'JPEG',
    icon: icons.image,
    desc: 'JPEG images',
  },

  // .js	JavaScript text/javascript, per the following specifications:
  // .json	JSON format	application/json
  // .jsonld	JSON-LD format	application/ld+json
  // .mid
  // .midi	Musical Instrument Digital Interface (MIDI)	audio/midi audio/x-midi
  // .mjs	JavaScript module	text/javascript

  // .mp3	MP3 audio	audio/mpeg
  'audio/mpeg': {
    ext: 'mp3',
    name: 'MP3',
    icon: icons.audio,
    desc: 'MP3 audio',
  },

  // .mpeg	MPEG Video	video/mpeg
  'video/mpeg': {
    ext: 'mpeg',
    name: 'MPEG Video',
    icon: icons.video,
    desc: 'MPEG Video',
  },

  // .mpkg	Apple Installer Package	application/vnd.apple.installer+xml
  // .odp	OpenDocument presentation document	application/vnd.oasis.opendocument.presentation
  // .ods	OpenDocument spreadsheet document	application/vnd.oasis.opendocument.spreadsheet
  // .odt	OpenDocument text document	application/vnd.oasis.opendocument.text
  // .oga	OGG audio	audio/ogg
  // .ogv	OGG video	video/ogg
  // .ogx	OGG	application/ogg
  // .opus	Opus audio	audio/opus
  // .otf	OpenType font	font/otf

  // .png	Portable Network Graphics	image/png
  'image/png': {
    ext: 'png',
    name: 'PNG',
    icon: icons.image,
    desc: 'Portable Network',
  },

  // .pdf	Adobe Portable Document Format (PDF)	application/pdf
  'application/pdf': {
    ext: 'pdf',
    name: 'PDF',
    icon: icons.pdf,
    desc: 'Adobe Portable Document Format (PDF)',
  },

  // .php	Hypertext Preprocessor (Personal Home Page)	application/x-httpd-php

  // .ppt	Microsoft PowerPoint	application/vnd.ms-powerpoint
  'application/vnd.ms-powerpoint': {
    ext: 'ppt',
    name: 'Microsoft PowerPoint',
    icon: icons.mspowerpoint,
    desc: 'Microsoft PowerPoint',
  },

  // .pptx	Microsoft PowerPoint (OpenXML)	application/vnd.openxmlformats-officedocument.presentationml.presentation
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    ext: 'pptx',
    name: 'Microsoft PowerPoint (OpenXML)',
    icon: icons.mspowerpoint,
    desc: 'Microsoft PowerPoint (OpenXML)',
  },

  // .rar	RAR archive	application/vnd.rar

  // .rtf	Rich Text Format (RTF)	application/rtf
  'application/rtf': {
    ext: 'rtf',
    name: 'Rich Text Format (RTF)',
    icon: icons.msword,
    desc: 'Rich Text Format (RTF)',
  },

  // .sh	Bourne shell script	application/x-sh

  // .svg	Scalable Vector Graphics (SVG)	image/svg+xml
  'image/svg+xml': {
    ext: 'svg',
    name: 'SVG',
    icon: icons.image,
    desc: 'Scalable Vector Graphics (SVG)',
  },

  // .swf	Small web format (SWF) or Adobe Flash document	application/x-shockwave-flash
  // .tar	Tape Archive (TAR)	application/x-tar

  // .tif
  // .tiff	Tagged Image File Format (TIFF)	image/tiff
  'image/tiff': {
    ext: ['tiff', 'tif'],
    name: 'TIFF',
    icon: icons.image,
    desc: 'Tagged Image File Format (TIFF)',
  },

  // .ts	MPEG transport stream	video/mp2t
  // .ttf	TrueType Font	font/ttf

  // .txt	Text, (generally ASCII or ISO 8859-n)	text/plain
  'text/plain': {
    ext: 'txt',
    name: 'Text file',
    icon: icons.text,
    desc: 'Text, (generally ASCII or ISO 8859-n)',
  },

  // .vsd	Microsoft Visio	application/vnd.visio

  // .wav	Waveform Audio Format	audio/wav
  'audio/wav': {
    ext: 'wav',
    name: 'WAV',
    icon: icons.audio,
    desc: 'Waveform Audio Format',
  },

  // .weba	WEBM audio	audio/webm
  'audio/webm': {
    ext: 'weba',
    name: 'WEBM audio',
    icon: icons.audio,
    desc: 'WEBM audio',
  },

  // .webm	WEBM video	video/webm
  'video/webm': {
    ext: 'webm',
    name: 'WEBM video',
    icon: icons.video,
    desc: 'WEBM video',
  },

  // .webp	WEBP image	image/webp
  'image/webp': {
    ext: 'webp',
    name: 'WEBP image',
    icon: icons.image,
    desc: 'WEBP image',
  },

  // .woff	Web Open Font Format (WOFF)	font/woff
  // .woff2	Web Open Font Format (WOFF)	font/woff2
  // .xhtml	XHTML	application/xhtml+xml

  // .xls	Microsoft Excel	application/vnd.ms-excel
  'application/vnd.ms-excel': {
    ext: 'xls',
    name: 'Microsoft Excel',
    icon: icons.msexcel,
    desc: 'Microsoft Excel',
  },

  // .xlsx	Microsoft Excel (OpenXML)	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    ext: 'xlsx',
    name: 'Microsoft Excel',
    icon: icons.msexcel,
    desc: 'Microsoft Excel (OpenXML)',
  },

  // .xml	XML	application/xml if not readable from casual users (RFC 3023, section 3)
  // text/xml if readable from casual users (RFC 3023, section 3)
  // .xul	XUL	application/vnd.mozilla.xul+xml

  // .zip	ZIP archive	application/zip
  'application/zip': {
    ext: 'zip',
    name: 'ZIP archive',
    icon: icons.archive,
    desc: 'ZIP archive',
  },

  // .3gp	3GPP audio/video container	video/3gpp
  // audio/3gpp if it doesn't contain video
  // .3g2	3GPP2 audio/video container	video/3gpp2
  // audio/3gpp2 if it doesn't contain video
  // .7z	7-zip archive	application/x-7z-compressed

  // ADDED 2021-01-30

  // Outlook mail message	application/vnd.ms-outlook	msg
  'application/vnd.ms-outlook': {
    ext: 'msg',
    name: 'Outlook mail message',
    icon: icons.mail,
    desc: 'Outlook mail message',
  },

  // MPEG-4	video/mp4	mp4
  'video/mp4': {
    ext: 'mp4',
    name: 'MPEG-4',
    icon: icons.video,
    desc: 'MPEG-4',
  },

  // Apple QuickTime movie	video/quicktime	mov
  'video/quicktime': {
    ext: 'mov',
    name: 'Apple QuickTime movie',
    icon: icons.video,
    desc: 'Apple QuickTime movie',
  },
}

export const getMimeTypeRef = (mimetype: keyof typeof mimetypes) => {
  return mimetypes[mimetype] || MIME_UNKNOWN
}

export default mimetypes
