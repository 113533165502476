type FormatterValue = number | undefined | null

const isValidNumber = (value: unknown): value is number =>
  value !== undefined && value !== null && !Number.isNaN(value)

export const numberFormatter = (
  decimalDigits = 0,
  options: Intl.NumberFormatOptions = {}
) => (value: FormatterValue) => {
  if (!isValidNumber(value)) return ''
  return value.toLocaleString(undefined, {
    ...options,
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
  })
}

export const currencyFormatter = (
  currency: string,
  options: Intl.NumberFormatOptions = {},
  zeroValue?: string
) => (value?: FormatterValue) => {
  if (!isValidNumber(value)) return ''
  if (value === 0 && zeroValue !== undefined) return zeroValue
  return Number(value).toLocaleString(undefined, {
    ...options,
    style: 'currency',
    currency: currency,
  })
}

export const percentFormatter = (
  digits = 3,
  options: Intl.NumberFormatOptions = {}
) => (value: FormatterValue) => {
  if (!isValidNumber(value)) return ''
  return value.toLocaleString(undefined, {
    ...options,
    style: 'percent',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
}
