import { DataTableHeader } from 'vuetify'
import evalValue from './eval-value'

type ExportType = 'auto' | 'text' | 'number'

export interface ExportableDataTableHeader extends DataTableHeader {
  exportType?: ExportType
  hideInExport?: true
}

const normalizeValue = (
  value: unknown,
  exportType: ExportType = 'auto',
  wrapChar = '"'
): string | number => {
  if (exportType !== 'text' && typeof value === 'number') return value

  const result =
    wrapChar +
    (value === null
      ? ''
      : typeof value === 'string'
      ? value.toString()
      : 'invalid value'
    )
      .replace(/"/g, '""')
      .replace(/#/g, '%23') +
    wrapChar

  return result
}

export const openContent = (
  content: string,
  filename = 'download',
  ext = 'csv'
) => {
  const link = document.createElement('a')
  link.setAttribute('href', content)
  link.setAttribute(
    'download',
    filename.endsWith('.' + ext) ? filename : filename + '.' + ext
  )
  document.body.appendChild(link)
  link.click()
}

export const exportCsv = <T>(
  items: T[],
  headers: ExportableDataTableHeader[],
  filename = 'download.csv'
): void => {
  const exportableHeaders = headers.filter(h => !h.hideInExport)

  const headerRow = exportableHeaders.map(h =>
    normalizeValue(h.text || h.value, 'text')
  )

  const rows = items.map(o =>
    exportableHeaders.map(h =>
      normalizeValue(evalValue(o, h.value), h.exportType)
    )
  )

  const csvContent =
    'data:text/csv;charset=utf-8,' +
    [headerRow, ...rows].map(e => e.join(',')).join('\n')

  const encodedUri = encodeURI(csvContent)

  openContent(encodedUri, filename)
}
