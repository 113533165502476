import { v4 as uuid } from 'uuid'

// TODO: standardize list of Products
import store, { SHIPPING_TERM } from '@/store'

// models
import { OrderEditable } from '@/models/Orders/OrderEditable'

// utils
import { isDefault } from '@/utils/isDefault'

// types
import {
  ID,
  DateOnly,
  Product,
  OrderItemEditableInput,
  OrderItemOutput,
} from '@/types'

// defaults
const DEFAULT_ORDER_ITEM: OrderItemEditableInput = {
  id: undefined,
  productId: undefined,
  quantity: 1,
  grossAmount: undefined,
  excludeFromAdjustment: false,
  conditionId: undefined,
  note: undefined,
  fmvAmount: undefined,
  overridePostedDate: undefined,
  serialNo: undefined,
}

export class OrderItemEditable {
  localId = uuid()

  id: ID | undefined

  productId?: ID
  quantity?: number
  grossAmount?: number

  excludeFromAdjustment: boolean

  conditionId?: ID

  serialNo?: string
  note?: string
  fmvAmount?: number
  overridePostedDate?: DateOnly

  order: OrderEditable

  constructor(order: OrderEditable, orderItemInput: OrderItemEditableInput) {
    const {
      id,
      productId,
      quantity,
      grossAmount,
      excludeFromAdjustment,
      conditionId,
      serialNo,
      note,
      overridePostedDate,
      fmvAmount,
    } = orderItemInput
    this.order = order

    this.id = id
    this.quantity = quantity
    this.grossAmount = grossAmount
    this.productId = productId

    this.excludeFromAdjustment = excludeFromAdjustment

    this.conditionId = conditionId

    this.serialNo = serialNo
    this.note = note
    this.fmvAmount = fmvAmount
    this.overridePostedDate = overridePostedDate
  }

  public product(): Product | undefined {
    if (!this.productId) return undefined
    const productId = Number(this.productId)
    return store.getters.getProductById(productId)
  }

  get isValid(): boolean {
    // TODO: validate
    return !!this.productId && !!this.quantity && !!this.grossAmount
  }

  get isDefault(): boolean {
    return isDefault(this, DEFAULT_ORDER_ITEM)
  }

  // calculated
  get adjustmentWeight(): number {
    return this.adjustmentBasis / this.order.adjustmentBasisTotal
  }

  get adjustmentBasis(): number {
    return (this.grossAmount || 0) * (this.excludeFromAdjustment ? 0 : 1)
  }

  get adjustmentAmount(): number {
    if (!this.order.adjustmentAmount) return 0
    return this.order.adjustmentAmount * this.adjustmentWeight
  }

  get netAmount(): number {
    const grossAmount = this.grossAmount || 0
    return grossAmount + this.adjustmentAmount
  }

  get revenueAmount(): number {
    return this.fmvAmount || this.netAmount
  }

  get unitPrice(): number {
    const grossAmount = this.grossAmount || 0
    if (!this.quantity) return 0
    return grossAmount / this.quantity
  }

  get unitNetPrice(): number {
    if (!this.quantity) return 0
    return this.netAmount / this.quantity
  }

  get postedDate(): DateOnly | undefined {
    if (this.overridePostedDate) return this.overridePostedDate

    const product = this.product()
    if (!product) return undefined

    const postedTrigger = product.postedTrigger

    switch (postedTrigger) {
      case 'installed':
        return this.order.installedDate
      case 'shipped':
        switch (this.order.shippingTermId) {
          case SHIPPING_TERM.Destination:
            return this.order.deliveredDate
          case SHIPPING_TERM.ShippingPoint:
          case SHIPPING_TERM.Unknown:
          default:
            return this.order.shippedDate
        }
      default:
        return undefined
    }
  }

  get estPostedDate(): DateOnly | undefined {
    if (this.postedDate) return this.postedDate

    const product = this.product()
    if (!product) return undefined

    const postedTrigger = product.postedTrigger

    switch (postedTrigger) {
      case 'installed':
        return this.order.estInstalledDate
      case 'shipped':
        switch (this.order.shippingTermId) {
          case SHIPPING_TERM.Destination:
            return this.order.estDeliveredDate
          case SHIPPING_TERM.ShippingPoint:
          case SHIPPING_TERM.Unknown:
          default:
            return this.order.estShippedDate
        }
      default:
        return undefined
    }
  }

  get isPosted(): boolean {
    return !!this.postedDate
  }

  public toJSON(): OrderItemOutput {
    return {
      id: this.id,

      productId: this.productId,
      quantity: this.quantity,
      grossAmount: this.grossAmount,

      excludeFromAdjustment: this.excludeFromAdjustment || false,

      conditionId: this.conditionId,

      serialNo: this.serialNo,
      note: this.note,
      fmvAmount: this.fmvAmount,
      overridePostedDate: this.overridePostedDate,
    }
  }

  public static create(
    order: OrderEditable,
    input: OrderItemEditableInput = DEFAULT_ORDER_ITEM
  ): OrderItemEditable {
    return new OrderItemEditable(order, input)
  }
}

export default OrderItemEditable
