















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import log from '@/log'

// event bus
import MessageBus from '@/message-bus'

// controls
import JTable, { JTableHeader } from '@/components/controls/JTable.vue'
import JGrid from '@/components/controls/JGrid.vue'
import JGridItem from '@/components/controls/JGridItem.vue'
import JDateField from '@/components/controls/JDateField.vue'
import JTextField from '@/components/controls/JTextField.vue'
import JSelect from '@/components/controls/JSelect.vue'
import JTextarea from '@/components/controls/JTextarea.vue'

// components
import OrderItemEdit from '@/components/orders/OrderEdit/OrderItemEdit.vue'
import OrderAdjustmentEdit from '@/components/orders/OrderEdit/OrderAdjustmentEdit.vue'
import StorageObjectTable from '@/components/attachments/StorageObjectTable.vue'
import StorageObjectUpload from '@/components/attachments/StorageObjectUpload.vue'
import IssuesCard from '@/components/issues/IssuesCard.vue'
import OrderCalcSummary from '@/components/orders/OrderEdit/OrderCalcSummary.vue'
import OrderCalcCommissionComponent from '@/components/orders/OrderEdit/OrderCalcCommissionComponent.vue'
import OrderCalcPosted from '@/components/orders/OrderEdit/OrderCalcPosted.vue'
import AuditList from '@/components/audit/AuditList.vue'
import CreateCustomerAddress from '@/components/customers/CreateCustomerAddress.vue'
import RefItemAdd from '@/components/refdata/RefItemAdd.vue'

// models
import OrderEditable from '@/models/Orders/OrderEditable'
import Issue from '@/models/Issues/Issue'
import { StorageObject } from '@/models/StorageObject'

// utils
import { arrayMovePrev, arrayMoveNext } from '@/utils/arrayMove'
import { sanitizeFilename } from '@/utils/filename-utils'
import { getDisplayId } from '@/models/Orders/Order'
import { getMapUrl } from '@/utils/geomaps'

// ref
import { refDataObj } from '@/components/refdata/ref-data'
import { RefDataItem } from '@/components/refdata/types'

// types
import {
  OrderEditableInput,
  OrderType,
  Customer,
  CustomerAddress,
  ID,
  OrderTag,
} from '@/types'

interface PasteColumn {
  id: string
  label: string
  value: (v: string) => string | number | null
}

// constants
const pasteColumns: PasteColumn[] = [
  {
    id: 'productId',
    label: 'Product ID',
    value: v => (!v ? null : parseInt(v)),
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: v => (!v ? null : parseInt(v)),
  },
  {
    id: 'grossAmount',
    label: 'Gross Amount',
    value: v => (!v ? null : Number(v)),
  },
  { id: 'note', label: 'Note', value: v => v || null },
  {
    id: 'overridePostedDate',
    label: 'Posted Date',
    value: val => {
      const date = Date.parse(val)
      return Number.isNaN(date)
        ? null
        : new Date(date).toISOString().split('T')[0]
    },
  },
  { id: 'serialNo', label: 'Serial #', value: v => v || null },
  {
    id: 'fmvAmount',
    label: 'FMV',
    value: v => (!v ? null : Number(v)),
  },
]

export default Vue.extend({
  metaInfo() {
    return {
      title: this.orderTitle as string,
    }
  },
  components: {
    // controls
    JDateField,
    JTable,
    JGrid,
    JGridItem,
    JTextField,
    JSelect,
    JTextarea,

    // components
    OrderItemEdit,
    OrderAdjustmentEdit,
    StorageObjectTable,
    StorageObjectUpload,
    IssuesCard,
    OrderCalcSummary,
    OrderCalcCommissionComponent,
    OrderCalcPosted,
    AuditList,
    CreateCustomerAddress,
    RefItemAdd,
  },
  props: {
    orderId: [Number, String],
  },
  data: () => ({
    order: undefined as OrderEditable | undefined,
    originalOrderJSON: '' as string,
    originalOrderTypeId: 0 as number,
    allowNavigation: false,

    tab: 0,

    isFetching: false,
    isSaving: false,
    errors: [] as unknown[],

    filled: false,
    outlined: true,
    dense: true,

    isDeleting: false,
    deleteDialog: false,

    isRestoring: false,
    restoreDialog: false,

    isFetchingPdf: false,

    uploadAttachmentDialog: false as boolean,

    sendEmailDialog: undefined as
      | undefined
      | { to: string; subject: string; text: string },

    auditDialog: false,

    addAddressDialog: false,

    addOrderTagDialog: false,
    addOrderTagAddItemDialog: false,
  }),
  computed: {
    ...mapGetters([
      'currencies',
      'orderTypes',
      'orderTags',
      'getOrderTypeById',
      'orderCategories',
      'companies',
      'salesAreas',
      'salespersons',
      'customers',
      'shippingTerms',
      'getCustomerDeliveryAddressById',
    ]),
    customer(): Customer | null {
      if (!this.customers || !this.order?.customerId) return null
      return this.customers.find(
        (c: Customer) => c.id === this.order?.customerId
      )
    },
    customerAddresses(): CustomerAddress[] | readonly CustomerAddress[] {
      if (!this.customer) return []
      return this.customer.addresses
    },
    deliveryAddress(): CustomerAddress | undefined {
      if (
        !this.order ||
        !this.order.customerId ||
        !this.order.deliveryAddressId
      ) {
        return undefined
      } else {
        return this.getCustomerDeliveryAddressById(
          this.order.customerId,
          this.order.deliveryAddressId
        )
      }
    },
    orderTitle(): string {
      return !this.order
        ? this.isFetching
          ? 'Loading Order'
          : `Not Found: Order #${this.orderId}`
        : this.order.isNew
        ? 'New Order'
        : this.order.id === undefined
        ? 'Unexpected Error'
        : `Order #${getDisplayId(this.order.id, this.orderType)}-${
            this.order.version
          }`
    },
    orderType(): OrderType | undefined {
      return this.getOrderTypeById(this.order?.typeId)
    },
    orderTagsActive(): OrderTag[] {
      return this.orderTags.filter((v: OrderTag) => !v.inactive)
    },
    orderTagsInactive(): OrderTag[] {
      return this.orderTags.filter((v: OrderTag) => !!v.inactive)
    },
    orderTagsRef(): RefDataItem<OrderTag> {
      return refDataObj.orderTags
    },
    ORDER_TYPE_CANCELLED(): ID {
      return 3
    },
    isDeleted(): boolean {
      return this.order?.deletedAt !== null
    },
    isSavable(): boolean {
      return !this.isDeleted
    },
    isReadonly(): boolean {
      return !this.isSavable
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xsOnly
    },
    hasAdjustments(): boolean {
      return !!this.order && this.order.hasAdjustments
    },
    uploadAttachmentsFunction() {
      if (!this.order?.id) return undefined
      return this.$api.orders.uploadAttachments(this.order.id)
    },
    deleteAttachmentFunction() {
      if (!this.order?.id) return undefined
      return this.$api.orders.deleteAttachment(this.order.id)
    },
    orderItemHeaders(): JTableHeader[] {
      return [
        { text: '', id: 'row-status', width: '28px' },
        { text: 'Product', value: 'product', width: '10em' },
        { text: 'Qty', value: 'quantity', width: '5em' },
        { text: 'Amount', value: 'grossAmount', width: '10em' },
        {
          text: 'Note',
          value: 'note',
          width: `calc(100% - (28px * 3) - 10em - 5em - 10em - 8em - 10em - ${
            this.hasAdjustments ? '8em - 10em' : '0em'
          })`,
        },
        { text: 'Serial #', value: 'serialNo', width: '8em' },
        ...(this.hasAdjustments
          ? [
              { text: 'Adj', value: 'adjustmentAmount', width: '8em' },
              { text: 'Net', value: 'netAmount', width: '10em' },
            ]
          : []),
        { text: 'Posted', value: 'postedDate', width: '10.5em' },
        { text: '', id: 'more-info', width: '28px' },
      ]
    },
    orderAdjustmentHeaders(): JTableHeader[] {
      return [
        { text: '', id: 'row-status', width: '28px' },
        { text: 'Type', value: 'type', width: '28px' },
        { text: 'Category', value: 'category', width: '10em' },
        { text: 'Amount', value: 'amount', width: '10em' },
        {
          text: 'Note',
          value: 'note',
          width: 'calc(100% - (28px * 3) - 10em - 10em)',
        },
        { text: '', id: 'move-item', width: `${28 * 2}px` },
        { text: '', id: 'more-info', width: '28px' },
      ]
    },
  },
  watch: {
    orderId: {
      immediate: true,
      handler(orderId: string | number, oldOrderId: string | number): void {
        if (orderId !== oldOrderId) this.allowNavigation = false // reset nav gaurd override

        if (orderId === 'new') {
          const userDefaults: Partial<OrderEditableInput> = {
            salespersonId: this.$auth.user?.id,
            // TODO: set defaults by user (get from db)
            currency: 'USD',
            companyId: 1,
            salesAreaId: 1,
            typeId: 1,
            categoryId: 1,
          }

          this.setOrder(OrderEditable.create(userDefaults))
        } else {
          this.fetchOrder(parseInt(orderId.toString()))
        }
      },
    },
    'order.customerId': {
      handler(val: number, oldVal: number) {
        if (!val || (val !== oldVal && oldVal)) {
          if (this.order) {
            this.order.deliveryAddressId = null
          }
        }
      },
    },
  },
  methods: {
    getMapUrl,
    getDisplayId,
    isDirty(): boolean {
      return JSON.stringify(this.order?.toJSON()) !== this.originalOrderJSON
    },
    clearAllItems(): void {
      if (window.confirm('Are you sure you want to clear all items?')) {
        if (this.order) this.order.items = []
      }
    },
    pasteItems(): void {
      const input = window.prompt(
        `Paste inputs (tab delimited):\n\n${pasteColumns
          .map(c => c.label)
          .join(', ')}`
      )

      if (input && this.order) {
        const lines = input.split(/\r?\n/)

        lines.forEach(line => {
          const data = line.split('\t')

          const newItem = pasteColumns.reduce(
            (prev, c, i) => ({
              ...prev,
              [c.id]: c.value(data[i]),
            }),
            {
              id: undefined,
              excludeFromAdjustment: false,
            }
          )

          this.order?.addItem(newItem)
        })

        MessageBus.info('Items pasted!')
      }
    },
    onCreateCustomerAddress(newCustomerAddress: CustomerAddress): void {
      this.addAddressDialog = false

      if (
        this.order &&
        this.order?.customerId === newCustomerAddress.customerId
      ) {
        this.order.deliveryAddressId = newCustomerAddress.id
      }
    },
    setOrder(value: OrderEditable): void {
      this.order = value
      this.originalOrderTypeId = value.typeId || 0
      this.originalOrderJSON = JSON.stringify(value.toJSON())
    },
    displayError(err: string | Error): void {
      MessageBus.error(err)
    },
    displaySuccess(message: string): void {
      MessageBus.success(message)
    },
    displayInfo(message: string): void {
      MessageBus.info(message)
    },
    closeSendEmailDialog(): void {
      this.sendEmailDialog = undefined
    },
    openSendEmailDialog(): void {
      if (this.isDirty()) {
        if (
          !window.confirm(
            'You have unsaved changes. Your document will not reflect these changes.\n\nDo you want to continue?'
          )
        ) {
          return
        }
      }

      const findCaption = <T extends { id: number; name: string }>(
        list: T[],
        id: number | undefined,
        defaultCaption: string,
        captionField: keyof T = 'name'
      ) =>
        list.find((z: T) => z.id === (id || -1)) || {
          id: id || -1,
          [captionField]: defaultCaption,
        }

      this.sendEmailDialog = {
        to: '',
        subject: `${
          findCaption(this.orderCategories, this.order?.categoryId, 'Order')
            .name
        } #${
          findCaption<OrderType>(
            this.orderTypes,
            this.order?.typeId,
            '',
            'displayInitial'
          ).displayInitial
        }${this.orderId}-${this.order?.version || 0} - ${
          findCaption(
            this.customers,
            this.order?.customerId,
            'Unknown Customer'
          ).name
        } + ${
          findCaption(this.companies, this.order?.companyId, 'Unknown Company')
            .name
        }`,
        text: '',
      }
    },
    onSendEmail(): void {
      if (!this.order?.id) {
        this.displayError('missing id')
        return
      }

      if (!this.sendEmailDialog) {
        this.displayError('missing send email dialog info')
        return
      }

      const { to, subject, text } = this.sendEmailDialog

      if (!to) {
        this.displayError('to field required')
        return
      }

      if (!subject) {
        this.displayError('subject field required')
        return
      }

      this.$api.orders
        .sendEmail(this.order.id, to, subject, text)
        .then(() => {
          this.displaySuccess('email queued...')
          this.closeSendEmailDialog()
        })
        .catch(err => {
          this.errors.push(err)
        })
    },
    onDownloadPdf(): void {
      if (this.isDirty()) {
        if (
          !window.confirm(
            'You have unsaved changes. Your document will not reflect these changes.\n\nDo you want to continue?'
          )
        ) {
          return
        }
      }

      if (!this.order?.id) {
        this.displayError('missing id')
        return
      }
      this.displayInfo('Generating document...')

      this.isFetchingPdf = true

      this.$api.orders
        .get1(this.order.id)
        .then(order => {
          if (!order) throw new Error('order not found')

          return this.$api.orders.downloadPdf(
            order.id,
            true,
            sanitizeFilename(
              `${order.category.name} #${order.displayId}-${order.version} - ${order.customer.name} + ${order.company.name}.pdf`
            )
          )
        })
        .then(() => {
          this.displaySuccess('Downloading document...')
        })
        .catch(err => {
          this.displayError(err)
        })
        .finally(() => {
          this.isFetchingPdf = false
        })
    },
    retry(): void {
      if (!this.orderId || this.orderId === 'new')
        throw new Error('cannot retry new order')
      this.fetchOrder(parseInt(this.orderId.toString()))
    },
    fetchOrder(orderId: number): Promise<void> {
      this.isFetching = true
      this.errors = []

      return this.$api.orders
        .get(orderId)
        .then(order => {
          this.setOrder(order)
        })
        .catch(err => {
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    moveAdjustmentPrev(index: number): void {
      if (!this.order || !this.order.adjustments) return
      arrayMovePrev(this.order.adjustments, index)
    },
    moveAdjustmentNext(index: number): void {
      if (!this.order || !this.order.adjustments) return
      arrayMoveNext(this.order.adjustments, index)
    },
    onDeleteItem(index: number): void {
      if (!this.order) return
      this.$delete(this.order.items, index)
    },
    onSaveIssue(item: Issue): void {
      if (!this.order) throw new Error('order not found')
      const index = this.order.issues.findIndex(z => z.id === item.id)

      if (index >= 0) {
        this.$set(this.order.issues, index, item)
      } else {
        this.order.issues.push(item)
      }
    },
    onDeleteIssue(itemId: number): void {
      if (!this.order) throw new Error('order not found')
      const index = this.order.issues.findIndex(z => z.id === itemId)

      if (index >= 0) {
        this.$delete(this.order.issues, index)
      }
    },
    onDeleteAdjustment(index: number): void {
      if (!this.order) return
      this.$delete(this.order.adjustments, index)
    },
    onUploadedAttachments(sos: StorageObject[]) {
      sos.forEach(so => {
        this.order?.attachments.push(so)
      })
      this.displaySuccess(
        `uploaded ${sos.length} attachment(s) ${sos
          .map(so => `'${so.name}'`)
          .join(', ')}`
      )
      this.uploadAttachmentDialog = false
    },
    onReload(): void {
      if (this.order?.isNew) throw Error('cannot reload new order')
      if (!this.order || !this.order.id) throw new Error('id does not exist')

      if (this.isDirty()) {
        if (
          !window.confirm(
            'You have unsaved changes.\n\nDo you want to lose your changes and continue?'
          )
        ) {
          return
        }
      }

      this.fetchOrder(this.order.id)
    },
    onSave(): void {
      if (!this.order) throw new Error('order does not exist')

      this.isSaving = true
      this.errors = []

      if (this.order.isNew) {
        // create
        this.$api.orders
          .create(this.order.toJSON())
          .then(value => {
            if (!value) throw new Error('order saved but error fetching')

            this.displaySuccess(`created order #${value.id}`)
            this.allowNavigation = true // override nav guard
            this.$router.replace({
              name: 'order-edit',
              params: { orderId: value.id ? value.id.toString() : 'new' },
            })
          })
          .catch(err => {
            this.displayError(`error creating order: ${err.message || err}`)
            log.error(err)
            this.errors.push(err)
          })
          .finally(() => {
            this.isSaving = false
          })
      } else {
        if (!this.order.id) throw new Error('invalid order id')

        // update
        this.$api.orders
          .replace(this.order.id, this.order.toJSON())
          .then(value => {
            if (!value) throw new Error('order saved but error fetching')
            this.displaySuccess(`updated order #${value.id}`)
            this.setOrder(value)
          })
          .catch(err => {
            this.displayError(
              `error saving order #${this.order?.id}: ${err.message || err}`
            )
            log.error(err)
            this.errors.push(err)
          })
          .finally(() => {
            this.isSaving = false
          })
      }
    },
    closeDeleteDialog(): void {
      this.deleteDialog = false
    },
    deleteOrder(): void {
      if (!this.order || !this.order.id) throw new Error('order does not exist')

      const orderId = this.order.id

      this.isDeleting = true
      this.errors = []

      this.$api.orders
        .delete(orderId)
        .then(() => this.fetchOrder(orderId))
        .then(() => {
          this.closeDeleteDialog()
          this.displaySuccess(`cancelled order #${orderId}`)
        })
        .catch(err => {
          this.displayError(`error deleting order: ${err.message || err}`)
          this.errors.push(err)
        })
        .finally(() => {
          this.isDeleting = false
        })
    },
    closeRestoreDialog(): void {
      this.restoreDialog = false
    },
    closeAddOrderTagDialog(): void {
      this.addOrderTagDialog = false
    },
    restoreOrder(restoreToTypeId: ID): void {
      if (!this.order || !this.order.id) throw new Error('order does not exist')

      const orderId = this.order.id

      this.isRestoring = true
      this.errors = []

      this.$api.orders
        .restore(orderId, restoreToTypeId)
        .then(() => {
          this.closeRestoreDialog()
          this.displaySuccess(`restored order #${orderId}`)
          this.fetchOrder(orderId)
        })
        .catch(err => {
          this.displayError(`error restoring order: ${err.message || err}`)
          this.errors.push(err)
        })
        .finally(() => {
          this.isRestoring = false
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.order) {
      next()
      return
    }

    const isDirty = this.isDirty()

    if (!this.allowNavigation && isDirty) {
      if (
        window.confirm('Do you really want to leave? You have unsaved changes!')
      ) {
        next()
      } else {
        next(false)
      }
    } else {
      log.info('no changes made...navigating away from order')
      next()
    }
  },
})
