var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"j-table-input-with-dialog",class:{
    left: _vm.left,
  }},[_vm._t("default",[_c('input',{staticClass:"j-table-input text-truncate",class:{
        'text-left': _vm.align === 'left',
        'text-right': _vm.align === 'right',
      },attrs:{"readonly":_vm.readonly,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function (evt) { return _vm.$emit('input', evt.target.value); }}})]),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"j-table-input-btn",attrs:{"icon":"","small":"","tabindex":"-1","disabled":_vm.readonly}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.icon)}})],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._t("dialog",[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.label || 'Edit')+" ")]),_c('v-textarea',{staticClass:"ma-3",attrs:{"value":_vm.value,"readonly":_vm.readonly,"hide-details":"","auto-grow":"","outlined":"","autofocus":""},on:{"input":function (v) { return _vm.$emit('input', v); }}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Done ")])],1)],1)],{"dialog":_vm.dialog,"closeHandler":_vm.closeHandler})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }