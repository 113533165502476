




































































































































































































































import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash.clonedeep'

import StorageObjectTable from '@/components/attachments/StorageObjectTable.vue'
import StorageObjectUpload from '@/components/attachments/StorageObjectUpload.vue'

import JTextField from '@/components/controls/JTextField.vue'
import JTextarea from '@/components/controls/JTextarea.vue'
import JSelect from '@/components/controls/JSelect.vue'
import JDateField from '@/components/controls/JDateField.vue'
import JFormattedTextField from '@/components/controls/JFormattedTextField.vue'

import { getToday } from '@/utils/jad-date'

import { ID, IssueInput, StorageObjectInput, UUID } from '@/types'
import MessageBus from '@/message-bus'

const DEFAULT_ISSUE = (): Partial<IssueInput> => ({
  id: undefined,
  createdDate: getToday(),
  amount: 0,
})

export default Vue.extend({
  components: {
    StorageObjectTable,
    StorageObjectUpload,

    JTextField,
    JTextarea,
    JSelect,
    JDateField,
    JFormattedTextField,
  },
  props: {
    value: Object as PropType<IssueInput>,
  },
  data: () => ({
    issue: {} as IssueInput | Partial<IssueInput>,

    isSubmitting: false,

    deleteDialog: false,

    uploadAttachmentDialog: false,
  }),
  computed: {
    ...mapGetters([
      'currencies',
      'companies',
      'customers',
      'salesAreas',
      'salespersons',
      'commissionPayPeriods',
      'commissionComponents',
    ]),
    isNew(): boolean {
      return !this.issue.id
    },
    uploadAttachmentsFunction() {
      if (!this.issue?.id) return undefined
      return this.$api.issues.uploadAttachments(this.issue.id)
    },
    deleteAttachmentFunction() {
      if (!this.issue?.id) return undefined
      return this.$api.issues.deleteAttachment(this.issue.id)
    },
  },
  methods: {
    onUploadedAttachments(sos: StorageObjectInput[]): void {
      sos.forEach(so => this.issue?.attachments?.push(so))
      this.$emit('upload-attachments', {
        issue: this.issue,
        attachments: sos,
      })
      MessageBus.success(
        `uploaded ${sos.length} attachment(s) ${sos
          .map(so => `'${so.name}'`)
          .join(', ')}`
      )
      this.uploadAttachmentDialog = false
    },
    onDeletedAttachment(id: UUID): void {
      this.$emit('delete-attachment', {
        issue: this.issue,
        attachmentId: id,
      })
    },
    onInput(prop: keyof IssueInput) {
      return (v: unknown): void => {
        this.$set(this.issue, prop, v)
      }
    },
    close(): void {
      this.$emit('close')
    },
    saveIssue(issue: IssueInput): void {
      const fn = this.isNew
        ? this.$api.issues.create(issue)
        : this.$api.issues.replace(issue.id as ID, issue)

      this.isSubmitting = true

      fn.then(item => {
        MessageBus.success(`saved issue #${item.id}`)
        this.$emit('save', item)
      })
        .catch(err => {
          MessageBus.error(err.message || err)
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    deleteIssue(issue: IssueInput): void {
      this.isSubmitting = true

      if (!issue.id) throw new Error('missing issue id')

      const id = issue.id

      this.$api.issues
        .delete(id)
        .then(() => {
          MessageBus.success(`deleted issue #${id}`)
          this.$emit('delete', id)
        })
        .catch(err => {
          MessageBus.error(err.message || err)
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
  },
  created(): void {
    const value = {
      ...DEFAULT_ISSUE(),
      ...(this.value || {}),
    }
    this.issue = cloneDeep(value)
  },
})
