var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"pa-0 text-center"},[(_vm.isDefault)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-asterisk")]):(_vm.isValid)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-alert-circle")])],1),_c('td',{staticClass:"pa-0"},[_c('v-menu',{attrs:{"disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"focusable d-flex justify-center align-center pa-0",staticStyle:{"width":"100%","height":"100%"},attrs:{"tabindex":"0"}},'div',attrs,false),on),[(_vm.item.type)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.orderAdjustmentTypes.find(function (z) { return z.id === _vm.item.type; }).icon)+" ")]):_vm._e()],1)]}}])},[_c('v-list',_vm._l((_vm.orderAdjustmentTypes),function(type){return _c('v-list-item',{key:type.id,staticClass:"d-flex justify-center",on:{"click":function($event){_vm.item.type = type.id}}},[_c('v-icon',[_vm._v(_vm._s(type.icon))])],1)}),1)],1)],1),_c('td',{staticClass:"pa-0"},[_c('v-menu',{attrs:{"disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"focusable d-flex align-center",staticStyle:{"width":"100%","height":"100%"},attrs:{"tabindex":"0"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.item.category ? _vm.item.category.abbr : undefined)+" ")])]}}])},[_c('v-list',_vm._l((_vm.orderAdjustmentCategories),function(category){return _c('v-list-item',{key:category.id,on:{"click":function($event){_vm.item.category = category}}},[_c('v-list-item-title',[_vm._v(_vm._s(category.abbr))])],1)}),1)],1)],1),_c('td',{staticClass:"pa-0"},[_c('j-table-input-with-dialog',{attrs:{"readonly":_vm.readonly,"icon":"mdi-calculator","left":""},scopedSlots:_vm._u([{key:"dialog",fn:function(ref){
var dialog = ref.dialog;
var closeHandler = ref.closeHandler;
return [(dialog)?_c('j-calculator',{on:{"close":closeHandler},model:{value:(_vm.item.amount),callback:function ($$v) {_vm.$set(_vm.item, "amount", $$v)},expression:"item.amount"}}):_vm._e()]}}])},[_c('j-formatted-input',{staticClass:"text-right",staticStyle:{"width":"100%","height":"100%"},attrs:{"readonly":_vm.readonly,"format":_vm.item.type === '$'
            ? ("currency:" + (_vm.item.order.currency || 'USD'))
            : _vm.item.type === '%'
            ? 'percent'
            : 'standard'},model:{value:(_vm.item.amount),callback:function ($$v) {_vm.$set(_vm.item, "amount", $$v)},expression:"item.amount"}})],1)],1),_c('td',{staticClass:"pa-0"},[_c('j-table-input-with-dialog',{attrs:{"readonly":_vm.readonly,"label":"Note","right":""},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", $$v)},expression:"item.note"}})],1),_c('td',{staticClass:"pa-0"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.disableMoveUp || _vm.readonly},on:{"click":function($event){return _vm.$emit('moveUp')}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.disableMoveDown || _vm.readonly},on:{"click":function($event){return _vm.$emit('moveDown')}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]),_c('td',{staticClass:"pa-0"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-menu',{attrs:{"offset-x":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-actions',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.isDeleting = false); }),expression:"() => (isDeleting = false)"}]},[_c('v-btn',{attrs:{"icon":!_vm.isDeleting,"disabled":_vm.readonly,"color":_vm.isDeleting ? 'error' : undefined},on:{"click":_vm.onDeleteClick}},[_c('v-icon',{attrs:{"left":_vm.isDeleting}},[_vm._v("mdi-delete")]),(_vm.isDeleting)?_c('span',[_vm._v("Confirm")]):_vm._e()],1),_c('v-spacer')],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }