






import Vue from 'vue'

interface Style {
  [k: string]: string
}

export default Vue.extend({
  props: {
    inline: Boolean,

    itemsCount: {
      type: Number,
      default: 12,
    },
    gap: {
      type: Number,
      default: 8,
    },
  },
  computed: {
    calcItemsCount(): number {
      return Number(this.itemsCount || 12)
    },
    calcGapPx(): string {
      return (this.gap || 8) + 'px'
    },
    gridStyle(): Style {
      return {
        'grid-template-columns': `repeat(${
          this.calcItemsCount
        }, calc((100% - (${this.calcGapPx} * ${this.calcItemsCount - 1})) / ${
          this.calcItemsCount
        }))`,
        padding: this.calcGapPx,
        'column-gap': this.calcGapPx,
        'row-gap': this.calcGapPx,
      }
    },
  },
})
