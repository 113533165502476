




import Vue from 'vue'
import log from '@/log'
import initStore from '@/store/init'

export default Vue.extend({
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'App',
      // all titles will be injected into this template
      titleTemplate: `%s | ${this.$app.name}`,
    }
  },
  created() {
    initStore(this.$api)

    document.addEventListener(
      'swUpdated',
      (e: CustomEventInit<ServiceWorkerRegistration>) => {
        log.info('swUpdated', e)
        this.$store.commit('setSwUpdatablePrompt', e)
      },
      { once: true }
    )
  },
})
