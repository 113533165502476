


































import Vue from 'vue'

const DEFAULT_VALUE = ''

export default Vue.extend({
  data: () => ({
    value: DEFAULT_VALUE,
  }),
  computed: {
    valueIsValid() {
      return !!this.value.trim()
    },
  },
  methods: {
    search(q: string) {
      this.$router.push({ name: 'orders-table', query: { q } })
    },
    submit(q: string) {
      this.search(q)
      this.close()
    },
    close() {
      this.value = DEFAULT_VALUE
      this.$emit('close')
    },
    onSubmitClick() {
      this.submit(this.value)
    },
    onCancelClick() {
      this.close()
    },
    onKeyEnter() {
      if (this.valueIsValid) {
        this.submit(this.value)
      }
    },
    onKeyEscape() {
      this.close()
    },
  },
})
