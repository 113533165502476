import { evalValue } from '@/utils/eval-value'
import { isString } from './type-helpers'

type Prop<T, R> = string | ((item: T) => R)

const evalProp = <T, R>(item: T, prop: Prop<T, R>) =>
  isString(prop) ? evalValue(item, prop) : prop(item)

export const sortAlpha = <T>(prop: Prop<T, string>, desc = false) => (
  a: T,
  b: T
): number =>
  evalProp(a, prop) > evalProp(b, prop)
    ? 1 * (desc ? -1 : 1)
    : evalProp(a, prop) < evalProp(b, prop)
    ? -1 * (desc ? -1 : 1)
    : 0

export const sortNumeric = <T>(prop: Prop<T, number>, desc = false) => (
  a: T,
  b: T
): number => (evalProp(a, prop) - evalProp(b, prop)) * (desc ? -1 : 1)
