











import Vue from 'vue'
import MessageBus from '@/message-bus'

type SnackbarData = {
  visible: boolean
  text: string
  color?: 'success' | 'error' | 'info' | 'warn' | 'primary'
  icon?: string
}

type SnackbarPayload = Partial<SnackbarData>

const DEFAULT_SNACKBAR_OPTIONS: Partial<SnackbarData> = {
  visible: true,
  color: 'info',
}

export default Vue.extend({
  data: () => ({
    snackbar: {
      visible: false,
      text: '',
      color: undefined,
    } as SnackbarData,
  }),
  methods: {},
  created() {
    MessageBus.$on('message', (payload: SnackbarPayload) => {
      this.snackbar = Object.assign(
        {},
        DEFAULT_SNACKBAR_OPTIONS,
        payload
      ) as SnackbarData
    })
  },
})
