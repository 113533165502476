















import Vue, { PropType } from 'vue'

const formats: Formats = {
  standard: (value: number) => value.toLocaleString(),
  currency: (value: number, currency = 'USD') =>
    value.toLocaleString(undefined, {
      style: 'currency',
      currency,
    }),
  percent: (value: number, minimumFractionDigits = 3) =>
    value.toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits,
    }),
}

type Formats = {
  [k: string]: (value: number) => string
}

export default Vue.extend({
  props: {
    value: Number,
    placeholder: {
      type: String,
      required: false,
    },
    format: {
      type: String,
      default: 'standard',
    },
    selectText: Boolean,

    type: {
      type: String,
      default: 'number',
    },
    align: {
      type: String as PropType<'start' | 'center' | 'end'>,
      default: 'start',
    },

    readonly: Boolean,
    outlined: Boolean,
  },
  data: () => ({
    isInputActive: false,
  }),
  computed: {
    formatFn(): (value: number, ...args: unknown[]) => string {
      const format = this.format.split(':')[0]
      return formats[format] || formats['standard']
    },
    formatArgs(): unknown[] {
      return this.format.split(':').slice(1)
    },
    displayValue(): string | number | undefined {
      return this.formatValue(this.value, this.formatArgs)
    },
    isNegative(): boolean {
      return this.value < 0
    },
  },
  methods: {
    formatValue(
      value: number | undefined | null,
      ...args: unknown[]
    ): string | number | undefined {
      if (value === undefined || value === null) return ''

      if (this.isInputActive) {
        return value.toString()
      } else {
        const formattedValue = this.formatFn(value, args)
        return formattedValue
      }
    },
    cleanValue(modifiedValue: string): number | undefined {
      const regex = /[^0-9-.]/g

      let newValue: number | undefined = parseFloat(
        modifiedValue.replace(regex, '')
      )

      // Ensure that it is not NaN
      if (isNaN(newValue)) {
        newValue = undefined
      }

      return newValue
    },
    onFocus(event: FocusEvent): void {
      this.isInputActive = true

      if (this.selectText) {
        this.$nextTick(() => {
          const target = event.target as HTMLInputElement
          target.select()
        })
      }
    },
    onBlur(event: FocusEvent): void {
      this.isInputActive = false

      const value = (event.target as HTMLInputElement).value
      const emitValue =
        value === '' || value === undefined || value === null
          ? undefined
          : Number(value)

      this.$emit('input', emitValue)
    },
  },
})
