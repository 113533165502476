














import Vue, { PropType } from 'vue'

import { ApiInterface } from '@/api'

// components
import RefItemEdit from '@/components/refdata/RefItemEdit.vue'
import { RefDataItem, RefDataField } from '@/components/refdata/types'

// types
import { CreateFn } from '@/api/types'

export default Vue.extend({
  components: {
    RefItemEdit,
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<RefDataItem<unknown>>,
    },
  },
  computed: {
    isAddable(): boolean {
      return (
        !!this.inputFields &&
        this.inputFields.length > 0 &&
        !!this.mutateOne &&
        !!this.create
      )
    },
    labelOne(): string {
      return Array.isArray(this.value.label)
        ? this.value.label[0]
        : this.value.label
    },
    inputFields(): RefDataField<unknown>[] {
      return this.value.fields || []
    },
    mutateOne(): string | undefined {
      return this.value.store?.mutateOne
    },
    create(): ((api: ApiInterface) => CreateFn<unknown>) | undefined {
      return this.value.api.create
    },
  },
  methods: {
    onCreate(...args: unknown[]) {
      this.$emit('create', ...args)
    },
    onClose(...args: unknown[]) {
      this.$emit('close', ...args)
    },
  },
})
