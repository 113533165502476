







































































import Vue, { PropType } from 'vue'

import IssuesTable from '@/components/issues/IssuesTable.vue'

import Issue from '@/models/Issues/Issue'

const DEFAULT_DENSE = true

export default Vue.extend({
  components: {
    IssuesTable,
  },
  props: {
    issues: {
      type: Array as PropType<Issue[]>,
      default: () => [],
    },

    title: {
      type: String,
      default: 'Issues',
    },

    dense: {
      type: Boolean,
      default: DEFAULT_DENSE,
    },
    hideShowResolved: Boolean,
    hideRefresh: Boolean,
    flat: Boolean,

    loading: Boolean,
    errors: {
      type: Array as PropType<Error[]>,
      default: () => [],
    },

    createDefaults: {
      type: Object as PropType<Partial<Issue>>,
      default: () => ({}),
    },
  },
  data: () => ({
    showResolved: false,

    search: '',
    sortBy: ['createdDate'],
    sortDesc: [true],

    searchDialog: false,

    disablePagination: false,
    hideDefaultFooter: false,
  }),
  computed: {
    filteredIssues(): ReadonlyArray<Issue> {
      // TODO: add structured filter
      if (this.hideShowResolved || this.showResolved) return this.issues

      return this.issues.filter(z => !z.resolvedDate)
    },
  },
  methods: {
    refresh(): void {
      this.$emit('refresh')
    },
    addIssue(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const component = this.$refs.issuesTable as any // as unknown as  typeof IssuesTable
      if (!component) throw new Error('component or method not found')
      component.createIssue(this.createDefaults)
    },
    onSearchInput(v: string): void {
      this.search = v
    },
    onSave(item: Issue): void {
      this.$emit('save', item)
    },
    onDelete(itemId: number): void {
      this.$emit('delete', itemId)
    },
  },
})
