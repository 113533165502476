














































































































































































































import Vue, { PropType } from 'vue'
import { DataTableHeader } from 'vuetify'
import { cloneDeep } from 'lodash'

import MessageBus from '@/message-bus'
import log from '@/log'

// models
import { StorageObject } from '@/models/StorageObject'

// utils
import { sortDate } from '@/utils/jad-date'

// types
import { UUID } from '@/types'

type ApiCall = (storageObjectId: UUID) => Promise<unknown>

export default Vue.extend({
  props: {
    items: Array as PropType<StorageObject[]>,
    deleteApiCall: Function as PropType<ApiCall>,

    dense: Boolean,
    hideDefaultFooter: Boolean,
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data: () => ({
    deleteItem: undefined as StorageObject | undefined,
    updateItem: undefined as StorageObject | undefined,

    isDeleting: false,
    isUpdating: false,

    sortBy: 'updatedAt',
    sortDesc: true,

    dialogItem: undefined as StorageObject | undefined,

    canShare: false,
  }),
  computed: {
    dialog(): boolean {
      return !!this.dialogItem
    },
    dialogDelete(): boolean {
      return !!this.deleteItem
    },
    dialogUpdate(): boolean {
      return !!this.updateItem
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: '',
          value: 'icon',
          width: 24 + 8 * 2,
          class: 'px-2',
          cellClass: 'px-2',
        },
        { text: 'Name', value: 'name', class: 'px-2', cellClass: 'px-2' },
        {
          text: 'Size',
          value: 'size',
          sortable: true,
          align: 'end',
          width: 96,
          class: 'px-2',
          cellClass: 'px-2',
        },
        {
          text: 'Date modified',
          value: 'updatedAt',
          sortable: true,
          width: 128 + 32,
          class: 'px-2',
          cellClass: 'px-2 text-truncate',
          sort: sortDate,
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          width: 24 + 8 * 2,
          class: 'px-2',
          cellClass: 'px-2 text-truncate',
        },
      ]
    },
  },
  created() {
    this.canShare = !!navigator.share
  },
  methods: {
    setDialogItem(item?: StorageObject): void {
      this.dialogItem = item
    },
    setUpdateItem(item?: StorageObject): void {
      this.updateItem = cloneDeep(item)
    },
    closeDialogItem(): void {
      this.dialogItem = undefined
    },
    share(item: StorageObject): void {
      navigator
        .share({
          url: item.href,
          title: item.name,
        })
        .then(response => {
          log.info('shared', item.name, response)
        })
        .catch(err => {
          log.error(err)
        })
    },
    deleteAttachment(item: StorageObject): void {
      const id = item.id
      const name = item.name

      if (!this.deleteApiCall) throw new Error('delete function not found')
      if (!id) throw new Error('no storage object selected')

      this.isDeleting = true

      this.deleteApiCall(id)
        .then(() => {
          MessageBus.success(`deleted attachment: ${name}`)

          const index = this.items.findIndex(z => z.id === id)
          if (index >= 0) this.$delete(this.items, index)

          this.$emit('delete', id)

          this.deleteItem = undefined
        })
        .catch(err => {
          log.error(err)
          MessageBus.error(`error deleting attachment: ${name}`)
        })
        .finally(() => {
          this.isDeleting = false
        })
    },
    updateAttachment(item: StorageObject): void {
      const id = item.id

      if (!id) throw new Error('no storage object selected')

      this.isUpdating = true

      this.$api.storageObject
        .patch(id, item)
        .then(storageObject => {
          MessageBus.success(`Updated ${storageObject.name}`)

          const index = this.items.findIndex(z => z.id === id)
          if (index >= 0) this.$set(this.items, index, storageObject)

          this.$emit('update', storageObject)

          this.setUpdateItem()
        })
        .catch(err => {
          log.error(err)
          MessageBus.error(`error updating attachment: ${id}`)
        })
        .finally(() => {
          this.isUpdating = false
        })
    },
  },
})
