



































import 'leaflet/dist/leaflet.css'

import Vue, { PropType } from 'vue'

import { latLng, latLngBounds } from 'leaflet'
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet'
import { GeocodeResult } from '@/types'

export type Marker<Data> = {
  location: {
    lat: number
    lng: number
  }
  title: string
  caption?: string

  data?: Data
}

export default Vue.extend({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  props: {
    markers: {
      type: Array as PropType<Array<Marker<GeocodeResult>>>,
      default: () => [],
    },
    current: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      zoom: 6,
      center: [0, 0],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,

      markerIconSize: 48,
    }
  },
  computed: {
    bounds() {
      return latLngBounds([
        [
          Math.min(...this.markers.map(m => m.location.lat)),
          Math.min(...this.markers.map(m => m.location.lng)),
        ],
        [
          Math.max(...this.markers.map(m => m.location.lat)),
          Math.max(...this.markers.map(m => m.location.lng)),
        ],
      ])
    },
  },
  methods: {
    getLatLng(loc: { lat: number; lng: number }) {
      return latLng(loc.lat, loc.lng)
    },
    markerClick(e: Marker<GeocodeResult>) {
      this.$emit('click:marker', e)
    },
    updateCurrent(i: number | null) {
      this.$emit('update:current', i)
    },
  },
})
