// store
import { SHIPPING_TERM, DEFAULT_SHIPPING_TERM } from '@/store'

// models
import { OrderItemEditable } from '@/models/OrderItems/OrderItemEditable'
import { OrderAdjustmentEditable } from '@/models/OrderAdjustments/OrderAdjustmentEditable'
import { StorageObject } from '@/models/StorageObject'
import { Issue } from '@/models/Issues/Issue'

// utils
import { sumArray, minDateArray, maxDateArray } from '@/utils/arrayCalcs'
import { getToday } from '@/utils/jad-date'

// types
import {
  ID,
  DateOnly,
  OrderEditableInput,
  OrderOutput,
  ProductCategoryGroup,
  CommissionComponent,
  CommissionComponentGroup,
  User,
  OrderItemEditableInput,
  OrderTag,
} from '@/types'

type ProductCategoryGroupSummary = {
  group: ProductCategoryGroup
  revenueAmount: number
}

type OrderCommissionComponentGroupSummary = {
  commissionComponentGroup: CommissionComponentGroup
  revenueAmount: number
}

type OrderCommissionComponentSummary = {
  commissionComponent: CommissionComponent
  revenueAmount: number
}

// defaults
const getDefaultOrder = (
  overrideDefaults: Partial<OrderEditableInput> = {}
): OrderEditableInput => ({
  id: undefined,
  currency: 'USD', // hardcoded default, override in 'overrideDefaults' if needed
  companyId: undefined,
  salesAreaId: undefined,
  salespersonId: undefined,
  typeId: undefined,
  categoryId: undefined,
  shippingTermId: DEFAULT_SHIPPING_TERM,
  tags: [],
  quoteNo: undefined,
  orderNo: undefined,
  poNo: undefined,
  customerId: undefined,
  deliveryAddressId: undefined,
  note: undefined,
  receivedDate: getToday(),
  processedDate: undefined,
  shippedDate: undefined,
  estShippedDate: undefined,
  deliveredDate: undefined,
  estDeliveredDate: undefined,
  installedDate: undefined,
  estInstalledDate: undefined,
  shippingAmount: 0,
  estTaxRate: 0,
  items: [],
  adjustments: [],
  attachments: [],
  issues: [],
  version: 0,

  ...overrideDefaults,
})

export class OrderEditable {
  id: ID | undefined

  currency: string

  companyId?: ID
  salesAreaId?: ID
  salespersonId?: ID

  customerId?: ID
  deliveryAddressId?: ID | null
  typeId?: ID
  categoryId?: ID
  shippingTermId: SHIPPING_TERM

  tags: OrderTag[]

  receivedDate: DateOnly
  processedDate?: DateOnly
  shippedDate?: DateOnly
  estShippedDate?: DateOnly
  deliveredDate?: DateOnly
  estDeliveredDate?: DateOnly
  installedDate?: DateOnly
  estInstalledDate?: DateOnly

  shippingAmount = 0
  estTaxRate = 0

  quoteNo?: string
  orderNo?: string
  poNo?: string

  note?: string

  updatedAt?: Date
  createdAt?: Date
  deletedAt: Date | null

  fetchedAt: Date

  updatedBy?: User

  items: OrderItemEditable[] = []
  adjustments: OrderAdjustmentEditable[] = []
  attachments: StorageObject[] = []
  issues: Issue[] = []

  legacyId?: number
  version: number

  constructor(orderInput: OrderEditableInput) {
    const {
      id,
      currency,
      companyId,
      salesAreaId,
      salespersonId,
      customerId,
      deliveryAddressId,
      typeId,
      categoryId,
      shippingTermId,
      tags,
      quoteNo,
      orderNo,
      poNo,
      receivedDate,
      processedDate,
      shippedDate,
      estShippedDate,
      deliveredDate,
      estDeliveredDate,
      installedDate,
      estInstalledDate,
      shippingAmount,
      estTaxRate,
      note,
      items,
      adjustments,
      attachments,
      issues,
      updatedAt,
      createdAt,
      deletedAt,
      updatedBy,

      legacyId,
      version,
    } = orderInput

    this.id = id

    this.currency = currency

    this.companyId = companyId
    this.salesAreaId = salesAreaId
    this.salespersonId = salespersonId

    this.customerId = customerId
    this.deliveryAddressId = deliveryAddressId
    this.typeId = typeId
    this.categoryId = categoryId
    this.shippingTermId = shippingTermId || DEFAULT_SHIPPING_TERM

    this.tags = tags || []

    this.receivedDate = receivedDate
    this.processedDate = processedDate
    this.shippedDate = shippedDate
    this.estShippedDate = estShippedDate
    this.deliveredDate = deliveredDate
    this.estDeliveredDate = estDeliveredDate
    this.installedDate = installedDate
    this.estInstalledDate = estInstalledDate

    this.shippingAmount = shippingAmount || 0
    this.estTaxRate = estTaxRate || 0

    this.quoteNo = quoteNo
    this.orderNo = orderNo
    this.poNo = poNo

    this.note = note

    this.updatedAt = updatedAt ? new Date(updatedAt) : undefined
    this.createdAt = createdAt ? new Date(createdAt) : undefined
    this.deletedAt = deletedAt ? new Date(deletedAt) : null

    this.updatedBy = updatedBy

    this.fetchedAt = new Date()

    this.items = items.map(item => new OrderItemEditable(this, item))
    this.adjustments = adjustments
      .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
      .map(adjustment => new OrderAdjustmentEditable(this, adjustment))
    this.attachments = (attachments || []).map(attachment =>
      StorageObject.fromJSON(attachment)
    )
    this.issues = (issues || []).map(issue => Issue.fromJSON(issue))

    this.legacyId = legacyId
    this.version = version
  }

  addItem(input?: OrderItemEditableInput): OrderItemEditable {
    const item = OrderItemEditable.create(this, input)
    this.items.push(item)
    return item
  }

  addAdjustment(): void {
    this.adjustments.push(OrderAdjustmentEditable.create(this))
  }

  get isNew(): boolean {
    return !this.id
  }

  get grossAmount(): number {
    return sumArray(this.items, item => item.grossAmount || 0)
  }

  get adjustmentBasisTotal(): number {
    return sumArray(this.items, item => item.adjustmentBasis || 0)
  }

  get adjustmentAmount(): number {
    return this.adjustments.reduce((prev, adjustment) => {
      return (
        prev + adjustment.calcAdjustmentAmount((this.grossAmount || 0) + prev)
      )
    }, 0)
  }

  get netAmount(): number {
    return this.grossAmount + this.adjustmentAmount
  }

  get estTaxAmount(): number {
    return (this.netAmount + this.shippingAmount) * this.estTaxRate
  }

  get totalAmount(): number {
    return this.netAmount + this.shippingAmount + this.estTaxAmount
  }

  get revenueAmount(): number {
    return sumArray(this.items, item => item.revenueAmount)
  }

  get fmvAdjustmentAmount(): number {
    return this.revenueAmount - this.netAmount
  }

  get hasFmvAdjustment(): boolean {
    return Math.abs(this.fmvAdjustmentAmount) > 0.0001
  }

  get postedAmount(): number {
    return sumArray(this.items, item =>
      item.isPosted ? item.revenueAmount : 0
    )
  }

  get unpostedAmount(): number {
    return this.revenueAmount - this.postedAmount
  }

  get minPostedDate(): DateOnly | undefined {
    return minDateArray(this.items, item => item.postedDate)
  }

  get maxPostedDate(): DateOnly | undefined {
    return maxDateArray(this.items, item => item.postedDate)
  }

  get commissionComponentGroups(): OrderCommissionComponentGroupSummary[] {
    return this.items
      .reduce((prev, item) => {
        const product = item.product()
        if (!product) return prev

        let g = prev.find(
          a => a.commissionComponentGroup === product.commissionComponent.group
        )

        if (!g) {
          g = {
            commissionComponentGroup: product.commissionComponent.group,
            revenueAmount: 0,
          }
          prev.push(g)
        }

        g.revenueAmount = g.revenueAmount + item.revenueAmount

        return prev
      }, [] as OrderCommissionComponentGroupSummary[])
      .map(g => ({
        ...g,
        commissionComponents: this.commissionComponents.filter(
          c => c.commissionComponent.group === g.commissionComponentGroup
        ),
      }))
  }

  get commissionComponents(): OrderCommissionComponentSummary[] {
    return this.items.reduce((prev, item) => {
      const product = item.product()
      if (!product) return prev

      let g = prev.find(
        a => a.commissionComponent.id === product.commissionComponent.id
      )

      if (!g) {
        g = {
          commissionComponent: product.commissionComponent,
          revenueAmount: 0,
        }
        prev.push(g)
      }

      g.revenueAmount = g.revenueAmount + item.revenueAmount

      return prev
    }, [] as OrderCommissionComponentSummary[])
  }

  get productCategoryGroups(): ProductCategoryGroupSummary[] {
    return this.items.reduce((prev, item) => {
      const product = item.product()
      if (!product) return prev

      let g = prev.find(a => a.group === product.category.group)

      if (!g) {
        g = { group: product.category.group, revenueAmount: 0 }
        prev.push(g)
      }

      g.revenueAmount = g.revenueAmount + item.revenueAmount

      return prev
    }, [] as ProductCategoryGroupSummary[])
  }

  get hasAdjustments(): boolean {
    return this.adjustmentCount > 0
  }

  get itemCount(): number {
    return this.items.filter(z => !z.isDefault).length
  }

  get adjustmentCount(): number {
    return this.adjustments.filter(z => !z.isDefault).length
  }

  get attachmentCount(): number {
    return this.attachments.length
  }

  get hasAttachments(): boolean {
    return this.attachmentCount > 0
  }

  get issueCount(): number {
    return this.issues.length
  }

  get hasIssues(): boolean {
    return this.issueCount > 0
  }

  public toJSON(): OrderOutput {
    return {
      id: this.id,

      currency: this.currency,

      companyId: this.companyId,
      salesAreaId: this.salesAreaId,
      salespersonId: this.salespersonId,

      customerId: this.customerId,
      deliveryAddressId: this.deliveryAddressId,
      typeId: this.typeId,
      categoryId: this.categoryId,
      shippingTermId: this.shippingTermId || DEFAULT_SHIPPING_TERM,

      tags: this.tags,

      receivedDate: this.receivedDate,
      processedDate: this.processedDate,
      shippedDate: this.shippedDate,
      estShippedDate: this.estShippedDate,
      deliveredDate: this.deliveredDate,
      estDeliveredDate: this.estDeliveredDate,
      installedDate: this.installedDate,
      estInstalledDate: this.estInstalledDate,

      shippingAmount: this.shippingAmount,
      estTaxRate: this.estTaxRate,

      quoteNo: this.quoteNo,
      orderNo: this.orderNo,
      poNo: this.poNo,

      note: this.note,

      items: this.items
        .filter(item => !item.isDefault)
        .map(item => item.toJSON()),

      adjustments: this.adjustments
        .filter(adjustment => !adjustment.isDefault)
        .map(adjustment => adjustment.toJSON()),
    }
  }

  public static fromJSON(input: OrderEditableInput) {
    return new OrderEditable(input)
  }

  public static create(
    overrideDefaults: Partial<OrderEditableInput> = {}
  ): OrderEditable {
    return new OrderEditable(getDefaultOrder(overrideDefaults))
  }
}

export default OrderEditable
