// api
import { ApiInterface } from '@/api'

// ref-data
import {
  refData,
  RefDataItemWithId,
  RefDataStore,
} from '@/components/refdata/ref-data'

// message bus
import MessageBus from '@/message-bus'

// store
import store from '@/store'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type VuexRefDataItem = RefDataItemWithId<any> & { store: RefDataStore }

const getAll = (api: ApiInterface) => {
  return refData
    .filter(
      (r): r is VuexRefDataItem =>
        !!r.api && !!r.api.list && !!r.store && !!r.store.mutateAll
    )
    .map(r =>
      r.api
        .list(api)()
        .then(result => store.commit(r.store.mutateAll, result))
        .catch(err => {
          MessageBus.error(
            `error fetching ${r.label[1] || r.label} [${err.message}]`
          )
          throw err
        })
    )
}

export default getAll
