import Vue from 'vue'
import Vuex from 'vuex'

import log from '@/log'

// utils
import { sortAlpha, sortNumeric } from '@/utils/sort'

// types
import {
  Product,
  OrderType,
  OrderCategory,
  ShippingTerm,
  OrderAdjustmentCategory,
  Company,
  SalesArea,
  Salesperson,
  Customer,
  CustomerType,
  Currency,
  OrderTag,
  CommissionQuotaPeriod,
  CommissionPayPeriod,
  GenericRefItem,
  CommissionComponent,
  CommissionComponentGroup,
  CommissionAdjustmentType,
  ProductCategory,
  ProductCategoryGroup,
  ProductCondition,
  ID,
  CustomerAddress,
  CommissionPayoutRate,
} from '@/types'

export enum SHIPPING_TERM {
  Unknown = 0,
  ShippingPoint = 1,
  Destination = 2,
}
export const DEFAULT_SHIPPING_TERM = SHIPPING_TERM.Unknown

Vue.use(Vuex)

const setOne = <T extends { id: ID }>(items: T[], value: T) => {
  const i = items.findIndex(c => c.id === value.id)

  if (i >= 0) {
    Vue.set(items, i, value)
  } else {
    items.push(value)
  }
}

const removeOne = <T extends GenericRefItem>(items: T[], id: ID) => {
  const i = items.findIndex(c => c.id === id)

  if (i >= 0) {
    Vue.delete(items, i)
  }
}

const findById = <T extends { id: ID }>(items: T[], id: ID) =>
  items.find(item => item.id === id)

export default new Vuex.Store({
  state: {
    currencies: [] as Currency[],
    products: [] as Product[],
    productCategories: [] as ProductCategory[],
    productCategoryGroups: [] as ProductCategoryGroup[],
    productConditions: [] as ProductCondition[],
    orderTypes: [] as OrderType[],
    orderCategories: [] as OrderCategory[],
    shippingTerms: [] as ShippingTerm[],
    orderAdjustmentCategories: [] as OrderAdjustmentCategory[],
    companies: [] as Company[],
    salesAreas: [] as SalesArea[],
    salespersons: [] as Salesperson[],
    customers: [] as Customer[],
    customerTypes: [] as CustomerType[],
    orderTags: [] as OrderTag[],
    commissionQuotaPeriods: [] as CommissionQuotaPeriod[],
    commissionPayPeriods: [] as CommissionPayPeriod[],
    commissionComponents: [] as CommissionComponent[],
    commissionComponentGroups: [] as CommissionComponentGroup[],
    commissionAdjustmentTypes: [] as CommissionAdjustmentType[],
    commissionPayoutRates: [] as CommissionPayoutRate[],

    productPostedTriggers: ['shipped', 'installed'],

    // PWA installable variables
    installablePrompt: undefined as Event | undefined,
    swUpdatablePrompt: undefined as
      | CustomEventInit<ServiceWorkerRegistration>
      | undefined,
  },
  getters: {
    currencies: state => state.currencies,
    products: state => state.products.sort(sortAlpha('abbr')),
    productCategories: state => state.productCategories.sort(sortAlpha('abbr')),
    productCategoryGroups: state =>
      state.productCategoryGroups.sort(sortAlpha('abbr')),
    productConditions: state => state.productConditions.sort(sortAlpha('abbr')),
    orderTypes: state => state.orderTypes,
    orderTags: state => state.orderTags.sort(sortAlpha('abbr')),
    orderCategories: state => state.orderCategories,
    shippingTerms: state => state.shippingTerms.sort(sortNumeric('id')),
    orderAdjustmentCategories: state => state.orderAdjustmentCategories,
    companies: state => state.companies,
    salesAreas: state => state.salesAreas,
    salespersons: state => state.salespersons,
    customers: state => state.customers.sort(sortAlpha('abbr')),
    customerTypes: state => state.customerTypes,
    commissionQuotaPeriods: state =>
      state.commissionQuotaPeriods.sort(sortAlpha('startDate')),
    commissionPayPeriods: state =>
      state.commissionPayPeriods.sort(sortAlpha('startDate')),
    commissionComponents: state =>
      state.commissionComponents.sort(sortAlpha('abbr')),
    commissionComponentGroups: state =>
      state.commissionComponentGroups.sort(sortAlpha('abbr')),
    commissionAdjustmentTypes: state =>
      state.commissionAdjustmentTypes.sort(sortAlpha('abbr')),
    commissionPayoutRates: state => state.commissionPayoutRates,

    productPostedTriggers: state => state.productPostedTriggers.sort(),

    // getters by ID
    getProductById: state => (id: ID) => findById(state.products, id),
    getProductCategoryById: state => (id: ID) =>
      findById(state.productCategories, id),
    getProductCategoryGroupById: state => (id: ID) =>
      findById(state.productCategoryGroups, id),
    getProductConditionById: state => (id: ID) =>
      findById(state.productConditions, id),
    getOrderTypeById: state => (id: ID) => findById(state.orderTypes, id),
    getOrderCategoryById: state => (id: ID) =>
      findById(state.orderCategories, id),
    getShippingTermById: state => (id: ID) => findById(state.shippingTerms, id),
    getOrderAdjustmentCategoryById: state => (id: ID) =>
      findById(state.orderAdjustmentCategories, id),
    getCompanyById: state => (id: ID) => findById(state.companies, id),
    getSalesAreaById: state => (id: ID) => findById(state.salesAreas, id),
    getSalespersonById: state => (id: ID) => findById(state.salespersons, id),
    getCustomerById: state => (id: ID) => findById(state.customers, id),
    getCustomerTypeById: state => (id: ID) => findById(state.customerTypes, id),
    getCommissionQuotaPeriodById: state => (id: ID) =>
      findById(state.commissionQuotaPeriods, id),
    getCommissionPayPeriodById: state => (id: ID) =>
      findById(state.commissionPayPeriods, id),
    getCommissionComponentById: state => (id: ID) =>
      findById(state.commissionComponents, id),
    getCommissionComponentGroupById: state => (id: ID) =>
      findById(state.commissionComponentGroups, id),
    getCommissionAdjustmentTypeById: state => (id: ID) =>
      findById(state.commissionAdjustmentTypes, id),

    getCommissionPayPeriodsByCommissionQuotaPeriodId: state => (id: ID) =>
      state.commissionPayPeriods
        .filter(z => z.commissionQuotaPeriod.id === id)
        .sort(sortAlpha('startDate')),

    getCustomerDeliveryAddressById: (state, getters) => (
      customerId: ID,
      deliveryAddressId: ID
    ) => {
      const customer: Customer | undefined = getters.getCustomerById(customerId)

      if (!customer || !customer.addresses || customer.addresses.length < 1)
        return undefined

      return customer.addresses.find(
        (a: CustomerAddress) => a.id === deliveryAddressId
      )
    },
  },
  mutations: {
    setCurrencies(state, value: Currency[]) {
      state.currencies = value
    },
    setProducts(state, value: Product[]) {
      state.products = value
    },
    setProduct(state, value: Product) {
      setOne(state.products, value)
    },
    setOrderTags(state, value: OrderTag[]) {
      state.orderTags = value
    },
    setOrderTag(state, value: OrderTag) {
      setOne(state.orderTags, value)
    },
    setProductCategories(state, value: ProductCategory[]) {
      state.productCategories = value
    },
    setProductCategory(state, value: ProductCategory) {
      setOne(state.productCategories, value)
    },
    setProductCategoryGroups(state, value: ProductCategoryGroup[]) {
      state.productCategoryGroups = value
    },
    setProductCategoryGroup(state, value: ProductCategoryGroup) {
      setOne(state.productCategoryGroups, value)
    },
    setProductCondition(state, value: ProductCondition) {
      setOne(state.productConditions, value)
    },
    setProductConditions(state, value: ProductCondition[]) {
      state.productConditions = value
    },
    setOrderTypes(state, value: OrderType[]) {
      state.orderTypes = value
    },
    setOrderType(state, value: OrderType) {
      setOne(state.orderTypes, value)
    },
    setOrderCategories(state, value: OrderCategory[]) {
      state.orderCategories = value
    },
    setOrderCategory(state, value: OrderCategory) {
      setOne(state.orderCategories, value)
    },
    setShippingTerms(state, value: ShippingTerm[]) {
      state.shippingTerms = value
    },
    setShippingTerm(state, value: ShippingTerm) {
      setOne(state.shippingTerms, value)
    },
    setOrderAdjustmentCategories(state, value: OrderAdjustmentCategory[]) {
      state.orderAdjustmentCategories = value
    },
    setOrderAdjustmentCategory(state, value: OrderAdjustmentCategory) {
      setOne(state.orderAdjustmentCategories, value)
    },
    setCompanies(state, value: Company[]) {
      state.companies = value
    },
    setCompanie(state, value: Company) {
      setOne(state.companies, value)
    },
    setSalesAreas(state, value: SalesArea[]) {
      state.salesAreas = value
    },
    setSalesArea(state, value: SalesArea) {
      setOne(state.salesAreas, value)
    },
    setSalespersons(state, value: Salesperson[]) {
      state.salespersons = value
    },
    setCommissionQuotaPeriods(state, value: CommissionQuotaPeriod[]) {
      state.commissionQuotaPeriods = value
    },
    setCommissionQuotaPeriod(state, value: CommissionQuotaPeriod) {
      setOne(state.commissionQuotaPeriods, value)
    },
    setCommissionPayPeriods(state, value: CommissionPayPeriod[]) {
      state.commissionPayPeriods = value
    },
    setCommissionPayPeriod(state, value: CommissionPayPeriod) {
      setOne(state.commissionPayPeriods, value)
    },
    setCommissionPayoutRates(state, value: CommissionPayoutRate[]) {
      state.commissionPayoutRates = value
    },
    setCommissionPayoutRate(state, value: CommissionPayoutRate) {
      setOne(state.commissionPayoutRates, value)
    },
    setCustomers(state, value: Customer[]) {
      state.customers = value
    },
    setCustomer(state, value: Customer) {
      setOne(state.customers, value)
    },
    setCustomerTypes(state, value: CustomerType[]) {
      state.customerTypes = value
    },
    setCustomerType(state, value: CustomerType) {
      setOne(state.customerTypes, value)
    },
    setCommissionComponents(state, value: CommissionComponent[]) {
      state.commissionComponents = value
    },
    setCommissionComponent(state, value: CommissionComponent) {
      setOne(state.commissionComponents, value)
    },
    setCommissionComponentGroups(state, value: CommissionComponentGroup[]) {
      state.commissionComponentGroups = value
    },
    setCommissionComponentGroup(state, value: CommissionComponentGroup) {
      setOne(state.commissionComponentGroups, value)
    },
    setCommissionAdjustmentTypes(state, value: CommissionAdjustmentType[]) {
      state.commissionAdjustmentTypes = value
    },
    setCommissionAdjustmentType(state, value: CommissionAdjustmentType) {
      setOne(state.commissionAdjustmentTypes, value)
    },
    removeCommissionAdjustmentType(state, id: ID) {
      removeOne(state.commissionAdjustmentTypes, id)
    },

    setInstallablePrompt(state, installablePrompt: Event) {
      log.info('setInstallablePrompt', installablePrompt)
      state.installablePrompt = installablePrompt
    },
    setSwUpdatablePrompt(
      state,
      value: CustomEventInit<ServiceWorkerRegistration>
    ) {
      log.info('setSwUpdatablePrompt', value)
      state.swUpdatablePrompt = value
    },

    insertCustomerAddress(state, input: CustomerAddress) {
      const customer = state.customers.find(c => c.id === input.customerId)
      if (!customer) return
      customer.addresses = [...customer.addresses, input]
      setOne(state.customers, customer)
    },
    removeCustomerAddress(state, input: CustomerAddress) {
      const customer = state.customers.find(c => c.id === input.customerId)
      if (!customer) return
      const index = customer.addresses.findIndex(a => a.id === input.id)
      customer.addresses = [
        ...customer.addresses.slice(0, index),
        ...customer.addresses.slice(index + 1),
      ]
      setOne(state.customers, customer)
    },
  },
  actions: {},
  modules: {},
})
