// types
import {
  ID,
  DateOnly,
  Customer,
  OrderType,
  OrderCategory,
  ShippingTerm,
  OrderInput,
  Company,
  SalesArea,
  Salesperson,
  OrderItemInput,
  OrderAdjustmentInput,
  StorageObjectInput,
  CustomerAddress,
  OrderTag,
  User,
} from '@/types'

export const getDisplayId = (id: ID, type: OrderType | undefined) =>
  !type ? '?' : type.displayInitial + id.toString()

export class Order {
  readonly id: ID

  readonly currency: string

  readonly company: Company
  readonly salesArea: SalesArea
  readonly salesperson: Salesperson

  readonly customer: Omit<Customer, 'addresses'> & {
    addresses: ReadonlyArray<CustomerAddress>
  }
  readonly deliveryAddress?: CustomerAddress
  readonly type: OrderType
  readonly category: OrderCategory
  readonly shippingTerm: ShippingTerm

  readonly tags: ReadonlyArray<OrderTag>

  readonly receivedDate: DateOnly
  readonly processedDate?: DateOnly
  readonly shippedDate?: DateOnly
  readonly estShippedDate?: DateOnly
  readonly deliveredDate?: DateOnly
  readonly estDeliveredDate?: DateOnly
  readonly installedDate?: DateOnly
  readonly estInstalledDate?: DateOnly

  readonly shippingAmount: number
  readonly estTaxRate: number

  readonly quoteNo?: string
  readonly orderNo?: string
  readonly poNo?: string

  readonly note?: string

  readonly updatedAt: string // Date
  readonly createdAt: string // Date
  readonly deletedAt?: string // Date
  readonly updatedBy: User

  readonly fetchedAt: string // Date

  readonly grossAmount: number
  readonly adjustmentAmount: number
  readonly netAmount: number
  readonly estTaxAmount: number
  readonly totalAmount: number
  readonly revenueAmount: number
  readonly fmvAdjustmentAmount: number
  readonly postedAmount: number
  readonly unpostedAmount: number

  readonly minPostedDate?: DateOnly
  readonly maxPostedDate?: DateOnly

  readonly items?: ReadonlyArray<OrderItemInput>
  readonly adjustments?: ReadonlyArray<OrderAdjustmentInput>
  readonly attachments?: ReadonlyArray<StorageObjectInput>

  readonly attachmentCount: number

  readonly displayId: string
  readonly version: number

  private constructor(orderInput: OrderInput) {
    const {
      id,
      currency,
      company,
      salesArea,
      salesperson,
      customer,
      deliveryAddress,
      type,
      category,
      shippingTerm,
      tags,
      quoteNo,
      orderNo,
      poNo,
      receivedDate,
      processedDate,
      shippedDate,
      estShippedDate,
      deliveredDate,
      estDeliveredDate,
      installedDate,
      estInstalledDate,
      shippingAmount,
      estTaxRate,
      attachmentCount,
      note,
      updatedAt,
      createdAt,
      deletedAt,
      updatedBy,
      grossAmount,
      adjustmentAmount,
      netAmount,
      estTaxAmount,
      totalAmount,
      revenueAmount,
      fmvAdjustmentAmount,
      postedAmount,
      unpostedAmount,
      minPostedDate,
      maxPostedDate,
      items,
      adjustments,
      attachments,
      version,
    } = orderInput

    this.id = id

    this.currency = currency

    this.company = company
    this.salesArea = salesArea
    this.salesperson = salesperson

    this.customer = customer
    this.deliveryAddress = deliveryAddress
    this.type = type
    this.category = category
    this.shippingTerm = shippingTerm

    this.tags = tags

    this.receivedDate = receivedDate
    this.processedDate = processedDate
    this.shippedDate = shippedDate
    this.estShippedDate = estShippedDate
    this.deliveredDate = deliveredDate
    this.estDeliveredDate = estDeliveredDate
    this.installedDate = installedDate
    this.estInstalledDate = estInstalledDate

    this.quoteNo = quoteNo
    this.orderNo = orderNo
    this.poNo = poNo

    this.note = note

    this.shippingAmount = shippingAmount || 0
    this.estTaxRate = estTaxRate || 0

    this.attachmentCount = attachmentCount || 0

    this.updatedAt = updatedAt
    this.createdAt = createdAt
    this.deletedAt = deletedAt
    this.updatedBy = updatedBy

    this.fetchedAt = new Date().toISOString()

    this.grossAmount = grossAmount
    this.adjustmentAmount = adjustmentAmount
    this.netAmount = netAmount
    this.estTaxAmount = estTaxAmount
    this.totalAmount = totalAmount
    this.revenueAmount = revenueAmount
    this.fmvAdjustmentAmount = fmvAdjustmentAmount
    this.postedAmount = postedAmount
    this.unpostedAmount = unpostedAmount

    this.minPostedDate = minPostedDate
    this.maxPostedDate = maxPostedDate

    this.items = items
    this.adjustments = (adjustments || []).sort(
      (a, b) => a.sortOrder - b.sortOrder
    )
    this.attachments = attachments

    this.displayId = getDisplayId(id, type)

    this.version = version
  }

  public static fromJSON(input: OrderInput) {
    return new Order(input)
  }
}

export default Order
