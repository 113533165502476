





























































































































































































































































import Vue, { PropType } from 'vue'
import log from '@/log'

// controls
import JFormattedInput from '@/components/controls/JFormattedInput.vue'
import JTableSelect from '@/components/controls/JTableSelect.vue'
import JTableInputWithDialog from '@/components/controls/JTableInputWithDialog.vue'
import JCalculator from '@/components/controls/JCalculator.vue'

// models
import OrderItemEditable from '@/models/OrderItems/OrderItemEditable'

// types
import { Product, ProductCondition } from '@/types'
import JDateField from '@/components/controls/JDateField.vue'

export default Vue.extend({
  components: {
    JFormattedInput,
    JTableSelect,
    JTableInputWithDialog,
    JCalculator,
    JDateField,
  },
  props: {
    value: Object as PropType<OrderItemEditable>,
    hidePlaceholders: Boolean,
    readonly: Boolean,
  },
  data: () => ({
    menu: false,
    isDeleting: false,
  }),
  computed: {
    item(): OrderItemEditable {
      return this.value
    },
    isValid(): boolean {
      return this.item.isValid
    },
    isDefault(): boolean {
      return this.item.isDefault
    },
    showQuantityLine(): boolean {
      return Math.abs(this.item.quantity || 0) !== 1
    },
    showFmvAmountLine(): boolean {
      return !!this.item.fmvAmount
    },
    extraGrossAmountLineCount(): number {
      return [this.showQuantityLine, this.showFmvAmountLine].reduce(
        (prev, z) => prev + (z ? 1 : 0),
        0
      )
    },
    // START: product calcs
    selectedProduct(): Product | undefined {
      return this.item.product()
    },
    selectedProductTooltip(): string {
      if (this.selectedProduct) return this.selectedProduct.name
      return 'Select a product'
    },
    products(): Product[] {
      return this.$store.getters.products
    },
    // END: product calcs
    productConditions(): ProductCondition[] {
      return this.$store.getters.productConditions
    },
    conditionAbbr(): string | undefined {
      return (
        this.productConditions.find(z => z.id === this.item.conditionId) || {
          abbr: this.item.conditionId,
        }
      ).abbr?.toString()
    },
  },
  methods: {
    // TODO: remove when ready
    log(...stuff: string[]): void {
      log.info(...stuff)
    },
    onDeleteClick(): void {
      if (this.isDeleting) {
        this.$emit('delete')
      } else {
        this.isDeleting = true
      }
    },
    closeMenu(): void {
      this.menu = false
    },
    evalPlaceholder(placeholder: string): string | null | undefined {
      return this.hidePlaceholders ? undefined : placeholder
    },
  },
})
