import axios from 'axios'

import AuthService from '@/auth'
import log from '@/log'

// utils
import { openContent } from '@/utils/export-data-table'

// types
import {
  FetchMethod,
  FetchParams,
  FetchHeaders,
  FetchOpts,
  SimpleItem,
  OnProgress,
} from '@/api/types'

// constants
import { BASE_URL, REQUEST_TIMEOUT, DEFAULT_DELIMITER } from './constants'

type DefaultBody = any // eslint-disable-line  @typescript-eslint/no-explicit-any

const logFetch = (method: FetchMethod, path: string, ...extra: unknown[]) => {
  log.info(`REQ: ${method} ${path}`, ...extra)
}

const delimitParams = (
  params: FetchParams = {},
  delimiter = DEFAULT_DELIMITER
) =>
  Object.entries(params).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: Array.isArray(value) ? value.join(delimiter) : value,
    }),
    {}
  )

export const doFetchNoAuth = <Body = DefaultBody>(
  method: FetchMethod,
  path: string,
  data: Body,
  params?: FetchParams,
  headers: FetchHeaders = {},
  opts?: FetchOpts
) => {
  return axios
    .request({
      method,
      baseURL: BASE_URL,
      url: path,
      headers,
      params,
      data,

      timeout: REQUEST_TIMEOUT,
      responseType: opts?.responseType || 'json',

      onUploadProgress: opts?.onUploadProgress,
    })
    .then(response => {
      log.info(`RES: ${method} ${path} [${response.status}]:`, response.data)
      return response.data
    })
    .catch(err => {
      log.error(err)
      log.info(err.response)

      if (err.response && err.response.data && err.response.data.message) {
        const message = err.response.data.message

        throw new Error(message)
      }

      throw err
    })
}

const doFetch = <Result, Body = DefaultBody>(
  auth: AuthService,
  method: FetchMethod,
  path: string,
  data: Body,
  params?: FetchParams,
  headers: FetchHeaders = {},
  opts?: FetchOpts,
  refreshableToken = true
): Promise<Result> => {
  logFetch(method, path, params, data)

  if (auth.isExpired()) {
    if (refreshableToken) {
      // refresh token
      return auth
        .refresh()
        .then(() =>
          doFetch(auth, method, path, data, params, headers, opts, false)
        )
    } else {
      throw new Error('token expired')
    }
  } else {
    headers['Authorization'] = 'Bearer ' + auth.token
  }

  return axios
    .request({
      method,
      baseURL: BASE_URL,
      url: path,
      headers,
      params,
      data,

      timeout: REQUEST_TIMEOUT,
      responseType: opts?.responseType || 'json',

      onUploadProgress: opts?.onUploadProgress,
    })
    .then(response => {
      log.info(`RES: ${method} ${path} [${response.status}]:`, response.data)
      return response.data
    })
    .catch(err => {
      log.error(err)
      log.info(err.response)

      if (err.response && err.response.data && err.response.data.message) {
        const message = err.response.data.message

        throw new Error(message)
      }

      throw err
    })
}

const doRequestUpload = <T>(
  auth: AuthService,
  method: FetchMethod,
  path: string,
  files: File[],
  item?: SimpleItem,
  params?: FetchParams,
  opts?: FetchOpts
): Promise<T> => {
  const headers: FetchHeaders = {}

  const formData = new FormData()
  files.forEach(file => {
    formData.append('file', file)
  })

  for (const key of formData.entries()) {
    log.info('formData', key[0], key[1])
  }

  return doFetch(auth, method, path, formData, params, headers, opts)
}

const doRequestJSON = <T, Body = DefaultBody>(
  auth: AuthService,
  method: FetchMethod,
  path: string,
  body?: Body,
  params?: FetchParams,
  opts?: FetchOpts
): Promise<T> => {
  const headers: FetchHeaders = {
    'Content-Type': 'application/json',
  }

  return doFetch(auth, method, path, body, params, headers, opts)
}

const doGet = (auth: AuthService) => <T>(
  path: string,
  body?: unknown,
  params?: FetchParams,
  opts?: FetchOpts
) => doRequestJSON<T>(auth, 'GET', path, body, delimitParams(params), opts)

const doPut = (auth: AuthService) => <T>(
  path: string,
  body: unknown,
  params?: FetchParams,
  opts?: FetchOpts
) => doRequestJSON<T>(auth, 'PUT', path, body, delimitParams(params), opts)

const doPost = (auth: AuthService) => <T>(
  path: string,
  body: unknown,
  params?: FetchParams,
  opts?: FetchOpts
) => doRequestJSON<T>(auth, 'POST', path, body, delimitParams(params), opts)

const doPatch = (auth: AuthService) => <T>(
  path: string,
  body: unknown,
  params?: FetchParams,
  opts?: FetchOpts
) => doRequestJSON<T>(auth, 'PATCH', path, body, delimitParams(params), opts)

const doDelete = (auth: AuthService) => <T>(
  path: string,
  body?: unknown,
  params?: FetchParams,
  opts?: FetchOpts
) => doRequestJSON<T>(auth, 'DELETE', path, body, delimitParams(params), opts)

const doUpload = (auth: AuthService) => <T>(
  path: string,
  files: File[],
  item?: SimpleItem,
  params?: FetchParams,
  onUploadProgress?: OnProgress,
  opts?: FetchOpts
) =>
  doRequestUpload<T>(auth, 'POST', path, files, item, delimitParams(params), {
    ...(opts || {}),
    onUploadProgress,
  })

export default (auth: AuthService) => {
  return {
    doGet: <T>(path: string, body?: unknown, params?: FetchParams) =>
      doGet(auth)<T>(path, body, params),
    doPut: <T>(path: string, body: unknown, params?: FetchParams) =>
      doPut(auth)<T>(path, body, params),
    doPost: <T>(path: string, body?: unknown, params?: FetchParams) =>
      doPost(auth)<T>(path, body, params),
    doPatch: <T>(path: string, body: unknown, params?: FetchParams) =>
      doPatch(auth)<T>(path, body, params),
    doDelete: <T>(path: string, body?: unknown, params?: FetchParams) =>
      doDelete(auth)<T>(path, body, params),
    doUpload: <T>(
      path: string,
      files: File[],
      item?: SimpleItem,
      params?: FetchParams,
      onUploadProgress?: OnProgress
    ) => doUpload(auth)<T>(path, files, item, params, onUploadProgress),
    doDownload: <T>(
      path: string,
      body?: unknown,
      params?: FetchParams,
      filename?: string,
      ext = 'csv',
      autoDownload = true,
      mimeType = 'text/csv'
    ) =>
      doGet(auth)<T>(path, body, params, { responseType: 'blob' }).then(
        response => {
          const content = URL.createObjectURL(
            new Blob([(response as unknown) as BlobPart], { type: mimeType })
          )
          if (autoDownload) {
            openContent(content, filename, ext)
            URL.revokeObjectURL(content)
          }
          return content
        }
      ),
  }
}
