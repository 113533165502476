// api functions
import fetchFunctions, { doFetchNoAuth } from '@/api/functions'
import AuthService from '@/auth'
import Issue from '@/models/Issues/Issue'

// models
import Order from '@/models/Orders/Order'
import OrderEditable from '@/models/Orders/OrderEditable'
import StorageObject from '@/models/StorageObject'

// components
import { Query } from '@/components/charts/chart-settings'
import { Pivot2Data } from '@/components/charts/map-config-settings'

// utils
import { TableGrouperItem } from '@/utils/table-grouper'

// types
import {
  Company,
  Customer,
  CustomerType,
  CustomerAddress,
  ID,
  UUID,
  OrderCategory,
  OrderAdjustmentCategory,
  OrderInput,
  OrderEditableInput,
  OrderOutput,
  OrderDetail,
  OrderType,
  OrderTag,
  Product,
  ProductCategory,
  ProductCategoryGroup,
  ProductCondition,
  ProductCommissionComponent,
  SalesArea,
  Salesperson,
  Currency,
  ShippingTerm,
  CommissionQuotaPeriod,
  CommissionPayPeriod,
  StorageObjectInput,
  StorageObjectUpdate,
  CommissionComponent,
  IssueInput,
  CommissionComponentGroup,
  CommissionPayment,
  CommissionAdjustment,
  CommissionAdjustmentType,
  CommissionQuota,
  CommissionPayPeriodQuota,
  CommissionPayoutRate,
  UserCustomItem,
  UserCustomItemCreate,
  OrderItemInput,
  Audit,
  GeocodeResponse,
  // Pivot2Result,
  ChartCumulativeAmountByDateItem,
  ChartQuotasItem,
  CommissionQuotaAttainmentGroups,
} from '@/types'

import {
  List,
  ListPaginate,
  ListOpts,
  Search,
  Get,
  Create,
  Replace,
  Delete,
  UploadAttachments,
  DeleteAttachment,
  Download,
  PivotResult,
  OnProgress,
} from '@/api/types'

export type UserCustomItemType = 'explorer_chart' | 'explorer_map'

export interface ApiInterface {
  test: () => Promise<unknown>

  version: () => Promise<string>

  currencies: List<Currency>
  orderTypes: List<OrderType>
  orderCategories: List<OrderCategory>
  shippingTerms: List<ShippingTerm>
  orderAdjustmentCategories: List<OrderAdjustmentCategory>
  companies: List<Company>
  salesAreas: List<SalesArea>
  salespersons: List<Salesperson>
  commissionQuotaPeriods: List<CommissionQuotaPeriod>
  commissionPayPeriods: List<CommissionPayPeriod>
  commissionComponents: List<CommissionComponent>
  commissionComponentGroups: List<CommissionComponentGroup>
  products: List<Product> & Get<Product> & Create<Product> & Replace<Product>
  productCommissionComponents: List<ProductCommissionComponent> &
    Create<ProductCommissionComponent> &
    Replace<ProductCommissionComponent> &
    Delete<unknown>
  orderTags: List<OrderTag> &
    Get<OrderTag> &
    Create<OrderTag> &
    Replace<OrderTag>
  issues: List<Issue> &
    Get<Issue> &
    Create<IssueInput, Issue> &
    Replace<IssueInput, Issue> &
    Delete<unknown> &
    UploadAttachments &
    DeleteAttachment
  productCategories: List<ProductCategory>
  productCategoryGroups: List<ProductCategoryGroup>
  productConditions: List<ProductCondition>
  customers: List<Customer> &
    Search<Customer> &
    Get<Customer> &
    Create<Customer> &
    Replace<Customer> & {
      addresses: {
        create: (
          customerId: ID,
          address: Partial<CustomerAddress>
        ) => Promise<CustomerAddress>
        delete: (customerId: ID, addressId: ID) => Promise<CustomerAddress>
      }
    }
  customerTypes: List<CustomerType> &
    Create<CustomerType> &
    Replace<CustomerType> &
    Delete<CustomerType>
  orders: ListPaginate<Order> & {
    cancelled: ListPaginate<Order>
  } & Get<OrderEditable> & {
      getItems: (orderId: ID) => Promise<OrderItemInput[]>
    } & { get1: (id: ID) => Promise<Order> } & Create<
      OrderOutput,
      OrderEditable
    > &
    Replace<OrderOutput, OrderEditable> &
    Delete<undefined, unknown> & {
      restore: (orderId: ID, orderTypeId: ID) => Promise<Order>
    } & UploadAttachments &
    DeleteAttachment &
    Download & {
      downloadPdf: (
        id: ID,
        autoDownload: boolean,
        filename?: string
      ) => Promise<string>
    } & {
      sendEmail: (
        id: ID,
        to: string,
        subject?: string,
        text?: string
      ) => Promise<unknown>
    }
  orderDetails: List<OrderDetail>
  users: {
    customItems: (
      type: UserCustomItemType
    ) => List<UserCustomItem> &
      Create<UserCustomItemCreate, UserCustomItem> &
      Replace<UserCustomItem> &
      Delete<UserCustomItem>
  }
  commissions: {
    quotas: List<CommissionQuota> &
      Create<CommissionQuota> &
      Replace<CommissionQuota> &
      Delete<CommissionQuota> & {
        attainments: List<unknown>

        periods: {
          period: (id: ID) => List<CommissionQuotaAttainmentGroups>
        }
      }

    payPeriodQuotas: List<CommissionPayPeriodQuota> &
      Create<CommissionPayPeriodQuota> &
      Replace<CommissionPayPeriodQuota> &
      Delete<CommissionPayPeriodQuota>

    payouts: {
      rates: List<CommissionPayoutRate> &
        Create<CommissionPayoutRate> &
        Replace<CommissionPayoutRate> &
        Delete<CommissionPayoutRate>

      periods: {
        period: (id: ID) => List<TableGrouperItem>
      }
    }
    payments: List<CommissionPayment> &
      Create<CommissionPayment> &
      Replace<CommissionPayment> &
      Delete<unknown>

    adjustmentTypes: List<CommissionAdjustmentType> &
      Create<CommissionAdjustmentType> &
      Replace<CommissionAdjustmentType> &
      Delete<unknown>

    adjustments: List<CommissionAdjustment> &
      Create<CommissionAdjustment> &
      Replace<CommissionAdjustment> &
      Delete<unknown>
  }

  charts: {
    cumulativeAmountByDate: (
      where: Query,
      amountField?: string,
      groupByField?: string
    ) => Promise<ChartCumulativeAmountByDateItem[]>

    pivot: {
      get: (
        amount: string,
        row: string,
        column: string,
        where?: Query
      ) => Promise<PivotResult>

      get2: (
        amount: string,
        rows: string[],
        columns?: string[],
        where?: Query
      ) => Promise<Pivot2Data>
    }

    quotas: {
      get: (where: Query) => Promise<ChartQuotasItem[]>
    }

    salesTimeline: {
      get: (periodId: ID) => Promise<OrderDetail[]>
    }
  }

  storageObject: {
    patch: (id: string, input: Partial<StorageObject>) => Promise<StorageObject>
  }

  geocode: {
    get: (search: string) => Promise<GeocodeResponse>
  }

  audit: {
    list: (table: string, tableId: ID) => Promise<Audit[]>
  }

  webpush: {
    subscribe: (json: PushSubscriptionJSON) => Promise<unknown>
  }
}

export default (auth: AuthService): ApiInterface => {
  const {
    doGet,
    doPut,
    doPost,
    doPatch,
    doDelete,
    doUpload,
    doDownload,
  } = fetchFunctions(auth)

  return {
    test: () => doPost('/test'),

    version: () =>
      doFetchNoAuth('GET', '/version', undefined, undefined, undefined, {
        responseType: 'text',
      }),

    currencies: {
      list: () => doGet<Currency[]>('/currencies'),
    },
    orderTypes: {
      list: () => doGet<OrderType[]>('/order-types'),
    },
    orderCategories: {
      list: () => doGet<OrderCategory[]>('/order-categories'),
    },
    shippingTerms: {
      list: () => doGet<ShippingTerm[]>('/shipping-terms'),
    },
    orderAdjustmentCategories: {
      list: () =>
        doGet<OrderAdjustmentCategory[]>('/order-adjustment-categories'),
    },
    companies: {
      list: () => doGet<Company[]>('/companies'),
    },
    salesAreas: {
      list: () => doGet<SalesArea[]>('/sales-areas'),
    },
    salespersons: {
      list: () => doGet<Salesperson[]>('/salespersons'),
    },
    commissionQuotaPeriods: {
      list: () => doGet<CommissionQuotaPeriod[]>('/commission-quota-periods'),
    },
    commissionPayPeriods: {
      list: () => doGet<CommissionPayPeriod[]>('/commission-pay-periods'),
    },
    commissionComponents: {
      list: () => doGet<CommissionComponent[]>('/commission-components'),
    },
    commissionComponentGroups: {
      list: () =>
        doGet<CommissionComponentGroup[]>('/commission-component-groups'),
    },
    products: {
      get: (id: number) =>
        doGet<Product>(`/products/${encodeURIComponent(id)}`),
      list: () => doGet<Product[]>('/products'),
      create: (input: Product) => doPost<Product>('/products', input),
      replace: (id: ID, input: Product) =>
        doPut<Product>(`/products/${encodeURIComponent(id)}`, input),
    },
    productCommissionComponents: {
      list: () =>
        doGet<ProductCommissionComponent[]>('/product-commission-components'),
      create: (input: ProductCommissionComponent) =>
        doPost<ProductCommissionComponent>(
          '/product-commission-components',
          input
        ),
      replace: (id: ID, input: ProductCommissionComponent) =>
        doPut<ProductCommissionComponent>(
          `/product-commission-components/${encodeURIComponent(id)}`,
          input
        ),
      delete: (id: ID) =>
        doDelete(`/product-commission-components/${encodeURIComponent(id)}`),
    },
    orderTags: {
      get: (id: number) =>
        doGet<OrderTag>(`/order-tags/${encodeURIComponent(id)}`),
      list: () => doGet<OrderTag[]>('/order-tags'),
      create: (input: OrderTag) => doPost<OrderTag>('/order-tags', input),
      replace: (id: ID, input: OrderTag) =>
        doPut<OrderTag>(`/order-tags/${encodeURIComponent(id)}`, input),
    },
    productCategories: {
      list: () => doGet<ProductCategory[]>('/product-categories'),
    },
    productCategoryGroups: {
      list: () => doGet<ProductCategoryGroup[]>('/product-category-groups'),
    },
    productConditions: {
      list: () => doGet<ProductCondition[]>('/product-conditions'),
    },
    customers: {
      get: (id: number) =>
        doGet<Customer>(`/customers/${encodeURIComponent(id)}`),
      list: () => doGet<Customer[]>('/customers'),
      search: (q?: string) =>
        doGet<Customer[]>(`/customers?q=${encodeURIComponent(q || '')}`),
      create: (input: Customer) => doPost<Customer>('/customers', input),
      replace: (id: ID, input: Customer) =>
        doPut<Customer>(`/customers/${encodeURIComponent(id)}`, input),
      addresses: {
        create: (customerId: ID, input: Partial<CustomerAddress>) =>
          doPost(
            `/customers/${encodeURIComponent(customerId)}/addresses`,
            input
          ),
        delete: (customerId: ID, id: ID) =>
          doDelete(
            `/customers/${encodeURIComponent(
              customerId
            )}/addresses/${encodeURIComponent(id)}`
          ),
      },
    },
    customerTypes: {
      list: () => doGet<CustomerType[]>('/customer-types'),
      create: (input: CustomerType) =>
        doPost<CustomerType>('/customer-types', input),
      replace: (id: ID, input: CustomerType) =>
        doPut<CustomerType>(`/customer-types/${encodeURIComponent(id)}`, input),
      delete: (id: ID) => doDelete(`/customer-types/${encodeURIComponent(id)}`),
    },

    issues: {
      list: (opts?: ListOpts) =>
        doGet<IssueInput[]>('/issues', undefined, opts).then(inputs =>
          inputs.map(input => Issue.fromJSON(input))
        ),
      get: (id: number) =>
        doGet<IssueInput>(`/issues/${encodeURIComponent(id)}`).then(input =>
          Issue.fromJSON(input)
        ),
      create: (input: IssueInput) =>
        doPost<IssueInput>('/issues', input).then(input =>
          Issue.fromJSON(input)
        ),
      replace: (id: ID, input: IssueInput) =>
        doPut<IssueInput>(
          `/issues/${encodeURIComponent(id)}`,
          input
        ).then(input => Issue.fromJSON(input)),
      delete: (id: ID) => doDelete(`/issues/${encodeURIComponent(id)}`),
      uploadAttachments: (id: ID) => (
        files: File[],
        input?: StorageObjectUpdate,
        onUploadProgress?: OnProgress
      ) =>
        doUpload<StorageObjectInput[]>(
          `/issues/${encodeURIComponent(id)}/attachments/`,
          files,
          input,
          undefined,
          onUploadProgress
        ).then(inputs => inputs.map(input => StorageObject.fromJSON(input))),
      deleteAttachment: (issueId: ID) => (attachmentId: UUID) =>
        doDelete(
          `/issues/${encodeURIComponent(
            issueId
          )}/attachments/${encodeURIComponent(attachmentId)}`
        ),
    },

    orders: {
      list: (opts?: ListOpts) =>
        doGet<[OrderInput[], number]>(`/orders`, undefined, opts).then(
          ([orderInputs, count]) => {
            return [
              orderInputs.map(orderInput => Order.fromJSON(orderInput)),
              count,
            ]
          }
        ),
      cancelled: {
        list: (opts?: ListOpts) =>
          doGet<[OrderInput[], number]>(`/orders/x`, undefined, opts).then(
            ([orderInputs, count]) => {
              return [
                orderInputs.map(orderInput => Order.fromJSON(orderInput)),
                count,
              ]
            }
          ),
      },
      get: (orderId: ID) =>
        doGet<OrderEditableInput>(
          `/orders/${encodeURIComponent(orderId)}`
        ).then(orderInput => OrderEditable.fromJSON(orderInput)),
      get1: (orderId: ID) =>
        doGet<OrderInput>(
          `/orders/${encodeURIComponent(orderId)}`
        ).then(orderInput => Order.fromJSON(orderInput)),
      getItems: (orderId: ID) =>
        doGet<OrderItemInput[]>(`/orders/${encodeURIComponent(orderId)}/items`),
      create: (input: OrderOutput) =>
        doPost<OrderEditableInput>('/orders', input).then(orderInput =>
          OrderEditable.fromJSON(orderInput)
        ),
      replace: (id: ID, input: OrderOutput) =>
        doPut<OrderEditableInput>(
          `/orders/${encodeURIComponent(id)}`,
          input
        ).then(orderInput => OrderEditable.fromJSON(orderInput)),
      delete: (id: ID) => doDelete(`/orders/${encodeURIComponent(id)}`),
      restore: (orderId: ID, orderTypeId: ID) =>
        doPut(
          `/orders/${encodeURIComponent(orderId)}/restore/${encodeURIComponent(
            orderTypeId
          )}`,
          null
        ),
      uploadAttachments: (id: ID) => (
        files: File[],
        input?: StorageObjectUpdate,
        onUploadProgress?: OnProgress
      ) =>
        doUpload<StorageObjectInput[]>(
          `/orders/${encodeURIComponent(id)}/attachments/`,
          files,
          input,
          undefined,
          onUploadProgress
        ).then(inputs => inputs.map(input => StorageObject.fromJSON(input))),
      deleteAttachment: (orderId: ID) => (attachmentId: UUID) =>
        doDelete(
          `/orders/${encodeURIComponent(
            orderId
          )}/attachments/${encodeURIComponent(attachmentId)}`
        ),
      download: (opts?: ListOpts, filename?: string) =>
        doDownload(`/orders/download`, undefined, opts, filename),
      downloadPdf: (orderId: ID, autoDownload = true, filename?: string) =>
        doDownload<unknown>(
          `/orders/${encodeURIComponent(orderId)}/pdf`,
          undefined,
          undefined,
          filename,
          'pdf',
          autoDownload,
          'application/pdf'
        ),
      sendEmail: (orderId: ID, to: string, subject?: string, text?: string) =>
        doPost(
          `/orders/${encodeURIComponent(
            orderId
          )}/pdf/email/${encodeURIComponent(to)}`,
          { text },
          { subject }
        ),
    },

    orderDetails: {
      list: (opts?: ListOpts) =>
        doGet<OrderDetail[]>(`/order-details`, undefined, opts),
    },

    users: {
      customItems: (type: UserCustomItemType) => {
        return {
          list: () => doGet(`/users/custom-items/${type}`),
          create: (input: unknown) =>
            doPost(`/users/custom-items/${type}`, input),
          replace: (id: ID, input: unknown) =>
            doPut(
              `/users/custom-items/${type}/${encodeURIComponent(id)}`,
              input
            ),
          delete: (id: ID) =>
            doDelete(`/users/custom-items/${type}/${encodeURIComponent(id)}`),
        }
      },
    },

    commissions: {
      quotas: {
        list: () => doGet<CommissionQuota[]>('/commissions/quotas'),
        create: (input: CommissionQuota) =>
          doPost<CommissionQuota>(`/commissions/quotas/`, input),
        replace: (id: ID, input: CommissionQuota) =>
          doPut<CommissionQuota>(
            `/commissions/quotas/${encodeURIComponent(id)}`,
            input
          ),
        delete: (id: ID) =>
          doDelete(`/commissions/quotas/${encodeURIComponent(id)}`),

        attainments: {
          list: () => doGet<unknown[]>('/commissions/quotas/attainments'),
        },
        periods: {
          period: (periodId: ID) => ({
            list: () =>
              doGet<CommissionQuotaAttainmentGroups[]>(
                `/commissions/quotas/periods/${encodeURIComponent(periodId)}`
              ),
          }),
        },
      },
      payPeriodQuotas: {
        list: opts =>
          doGet<CommissionPayPeriodQuota[]>(
            '/commissions/pay-period-quotas',
            undefined,
            opts
          ),
        create: (input: CommissionPayPeriodQuota) =>
          doPost<CommissionPayPeriodQuota>(
            `/commissions/pay-period-quotas/`,
            input
          ),
        replace: (id: ID, input: CommissionPayPeriodQuota) =>
          doPut<CommissionPayPeriodQuota>(
            `/commissions/pay-period-quotas/${encodeURIComponent(id)}`,
            input
          ),
        delete: (id: ID) =>
          doDelete(`/commissions/pay-period-quotas/${encodeURIComponent(id)}`),
      },
      payouts: {
        rates: {
          list: () =>
            doGet<CommissionPayoutRate[]>('/commissions/payout-rates'),
          create: (input: CommissionPayoutRate) =>
            doPost<CommissionPayoutRate>(`/commissions/payout-rates/`, input),
          replace: (id: ID, input: CommissionPayoutRate) =>
            doPut<CommissionPayoutRate>(
              `/commissions/payout-rates/${encodeURIComponent(id)}`,
              input
            ),
          delete: (id: ID) =>
            doDelete(`/commissions/payout-rates/${encodeURIComponent(id)}`),
        },

        periods: {
          period: (periodId: ID) => ({
            list: () =>
              doGet<TableGrouperItem[]>(
                `/commissions/payouts/periods/${encodeURIComponent(periodId)}`
              ),
          }),
        },
      },
      payments: {
        list: () => doGet<CommissionPayment[]>(`/commissions/payments/`),
        create: (input: CommissionPayment) =>
          doPost<CommissionPayment>(`/commissions/payments/`, input),
        replace: (id: ID, input: CommissionPayment) =>
          doPut<CommissionPayment>(
            `/commissions/payments/${encodeURIComponent(id)}`,
            input
          ),
        delete: (id: ID) =>
          doDelete(`/commissions/payments/${encodeURIComponent(id)}`),
      },
      adjustmentTypes: {
        list: () =>
          doGet<CommissionAdjustmentType[]>(`/commissions/adjustment-types/`),
        create: (input: CommissionAdjustmentType) =>
          doPost<CommissionAdjustmentType>(
            `/commissions/adjustment-types/`,
            input
          ),
        replace: (id: ID, input: CommissionAdjustmentType) =>
          doPut<CommissionAdjustmentType>(
            `/commissions/adjustment-types/${encodeURIComponent(id)}`,
            input
          ),
        delete: (id: ID) =>
          doDelete(`/commissions/adjustment-types/${encodeURIComponent(id)}`),
      },
      adjustments: {
        list: () => doGet<CommissionAdjustment[]>(`/commissions/adjustments/`),
        create: (input: CommissionAdjustment) =>
          doPost<CommissionAdjustment>(`/commissions/adjustments/`, input),
        replace: (id: ID, input: CommissionAdjustment) =>
          doPut<CommissionAdjustment>(
            `/commissions/adjustments/${encodeURIComponent(id)}`,
            input
          ),
        delete: (id: ID) =>
          doDelete(`/commissions/adjustments/${encodeURIComponent(id)}`),
      },
    },
    charts: {
      cumulativeAmountByDate: (
        params: { [key: string]: unknown },
        amountField = 'postedAmount',
        groupByField = 'postedDate'
      ) =>
        doGet<ChartCumulativeAmountByDateItem[]>(
          `/charts/cumulativeAmountByDate/${encodeURIComponent(
            amountField
          )}/${encodeURIComponent(groupByField)}`,
          undefined,
          params
        ),

      pivot: {
        get: (amount: string, row: string, column?: string, where?: Query) =>
          doGet<PivotResult>(
            column
              ? `/charts/pivot/${encodeURIComponent(
                  amount
                )}/${encodeURIComponent(row)}/${encodeURIComponent(column)}`
              : `/charts/pivot/${encodeURIComponent(
                  amount
                )}/${encodeURIComponent(row)}`,
            undefined,
            where
          ),
        get2: (
          amount: string,
          rows: string[],
          columns?: string[],
          where?: Query
        ) =>
          doGet<Pivot2Data>(
            columns
              ? `/charts/pivot2/${encodeURIComponent(amount)}/${rows
                  .map(row => encodeURIComponent(row))
                  .join(',')}/${columns
                  .map(column => encodeURIComponent(column))
                  .join(',')}`
              : `/charts/pivot2/${encodeURIComponent(amount)}/${rows
                  .map(row => encodeURIComponent(row))
                  .join(',')}`,
            undefined,
            where
          ),
      },

      quotas: {
        get: (params: { [key: string]: unknown }) =>
          doGet<ChartQuotasItem[]>(`/charts/quotas`, undefined, params),
      },

      salesTimeline: {
        get: (periodId: ID) =>
          doGet(`/charts/sales-timeline/${encodeURIComponent(periodId)}`),
      },
    },

    storageObject: {
      patch: (id: string, input: Partial<StorageObject | StorageObjectInput>) =>
        doPatch<StorageObjectInput>(
          `/storage-object/${encodeURIComponent(id)}`,
          input
        ).then(value => StorageObject.fromJSON(value)),
    },

    geocode: {
      get: (search: string) => doGet(`/geocode/${encodeURIComponent(search)}`),
    },

    audit: {
      list: (table: string, tableId: ID) =>
        doGet(
          `/audit/${encodeURIComponent(table)}/${encodeURIComponent(tableId)}`
        ),
    },

    webpush: {
      subscribe: (json: PushSubscriptionJSON) =>
        doPost(`/webpush/subscribe`, json),
    },
  }
}
