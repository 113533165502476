









































































import Vue, { PropType } from 'vue'
import { RawLocation } from 'vue-router'

// components
import JSnackbar from '@/components/controls/JSnackbar.vue'
import NavDrawerList from '@/components/nav/NavDrawerList.vue'
import UserCard from '@/components/users/UserCard.vue'
import JServiceWorkerNotification from '@/components/controls/JServiceWorkerNotification.vue'

// types
import { User } from '@/types'

interface Tab {
  name: string
  to: RawLocation
  exact: boolean
  icon?: string
}

export default Vue.extend({
  components: {
    NavDrawerList,
    UserCard,
    JSnackbar,
    JServiceWorkerNotification,
  },
  props: {
    tabs: Array as PropType<Tab[]>,
  },
  data: () => ({
    drawer: null,
    userMenu: false,
  }),
  computed: {
    user(): User | undefined {
      return this.$auth.user
    },
    appBarColor(): string {
      return process.env.NODE_ENV === 'production' ? 'primary' : 'dev'
    },
  },
  methods: {
    showTabName(tab: Tab): boolean {
      return !tab.icon || this.$vuetify.breakpoint.smAndUp
    },
  },
})
