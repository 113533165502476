export const arrayMove = (arr: unknown[], from: number, to: number) => {
  arr.splice(to, 0, arr.splice(from, 1)[0])
}

export const arrayMovePrev = (arr: unknown[], from: number) => {
  arrayMove(arr, from, Math.max(0, from - 1))
}

export const arrayMoveNext = (arr: unknown[], from: number) => {
  arrayMove(arr, from, Math.min(arr.length - 1, from + 1))
}
