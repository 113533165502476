


































































import Vue from 'vue'

export default Vue.extend({
  props: {
    value: [String, Number],
    label: String,
    type: {
      type: String,
      default: 'text',
    },

    icon: {
      type: String,
      default: 'mdi-fullscreen',
    },

    rows: {
      type: [String, Number],
      default: undefined,
    },
    height: {
      type: [String, Number],
      default: undefined,
    },

    clearable: Boolean,
    readonly: Boolean,
    noResize: Boolean,
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    calcHeight(): string | undefined {
      return !this.height
        ? undefined
        : Number.isNaN(Number(this.height))
        ? this.height.toString()
        : `${this.height}px`
    },
  },
  methods: {
    onInput(event: Event): void {
      const target = event.target as HTMLTextAreaElement
      const value = target.value

      this.$emit('input', value)
    },
  },
})
