export const setProgressText = (
  message: string,
  elementId = 'jad-progress-text'
) => {
  const el = document.getElementById(elementId)
  if (el) el.textContent = message
}

export const setErrorState = (elementId = 'spinner') => {
  const el = document.getElementById(elementId)
  if (el) el.classList.add('error')
}
