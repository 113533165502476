













import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    dragover: false,
  }),
  mounted() {
    // to register listeners, we have to know the html elements
    const dropzone = this.$el

    // register listeners on your dropzone / v-sheet
    if (dropzone) {
      // register all drag & drop event listeners
      dropzone.addEventListener('dragenter', e => {
        e.preventDefault()
        this.dragover = true
      })
      dropzone.addEventListener('dragleave', e => {
        e.preventDefault()
        this.dragover = false
      })
      dropzone.addEventListener('dragover', e => {
        e.preventDefault()
        this.dragover = true
      })
      dropzone.addEventListener('drop', e => {
        e.preventDefault()
        const dragevent = e as DragEvent
        if (dragevent.dataTransfer) {
          this.$emit('drop', dragevent.dataTransfer.files)
        }
        this.dragover = false
      })
    }
  },
})
