import Vue from 'vue'
import { VTextField, VSelect } from 'vuetify/lib'

// types
import {
  Product,
  CommissionAdjustmentType,
  CommissionComponent,
  CommissionPayPeriod,
  CommissionQuotaPeriod,
  Company,
  DateOnly,
  ID,
  SalesArea,
  Salesperson,
} from '@/types'

import { RefDataField } from './types'

export const getRefDataField = <Item, Value = string>(
  input: RefDataField<Item, Value>
) => input

export const idField = <Item extends { id: ID }>() =>
  getRefDataField<Item, ID>({
    id: 'id',
    castId: v => parseInt(v),
    label: 'ID',
    field: 'id',
    value: v => v.id,
    filter: 'list',
  })

export const abbrField = <Item extends { abbr: string }>() =>
  getRefDataField<Item, string>({
    id: 'abbr',
    label: 'Abbr',
    field: 'abbr',
    value: v => v.abbr,
    input: {
      value: item => item.abbr,
      component: VTextField,
      attrs: { label: 'Abbr' },
    },
    colAttrs: { 'col-md': 6 },
    filter: 'list',
  })

export const nameField = <Item extends { name: string }>() =>
  getRefDataField<Item, string>({
    id: 'name',
    label: 'Name',
    field: 'name',
    value: v => v.name,
    input: {
      value: item => item.name,
      component: VTextField,
      attrs: { label: 'Name' },
    },
    colAttrs: { 'col-md': 6 },
    filter: 'list',
  })

export const descriptionField = <Item extends { description: string }>() =>
  getRefDataField<Item, string>({
    id: 'description',
    label: 'Description',
    field: 'description',
    value: v => v.description,
    input: {
      value: item => item.description,
      component: VTextField,
      attrs: { label: 'Description' },
    },
    colAttrs: { col: 12 },
    filter: 'search',
  })

export const currencyField = <Item extends { currency: string }>() =>
  getRefDataField<Item, string>({
    id: 'currency',
    label: 'Currency',
    field: 'currency',
    value: v => v.currency,
    input: {
      value: item => item.currency,
      onInput: copy => item => Vue.set(copy, 'currency', item),
      component: VSelect,
      items: store => store.getters.currencies,
      attrs: {
        'item-text': 'id',
        'item-value': 'id',
        'return-object': true,
        label: 'Currency',
      },
    },
    colAttrs: { 'col-md': 6 },
    filter: 'list',
  })

export const startDateField = <Item extends { startDate: DateOnly }>() =>
  getRefDataField<Item, DateOnly>({
    id: 'startDate',
    label: 'Start',
    field: 'startDate',
    value: v => v.startDate,
    filter: 'date-range',
  })

export const endDateField = <Item extends { endDate: DateOnly }>() =>
  getRefDataField<Item, DateOnly>({
    id: 'endDate',
    label: 'End',
    field: 'endDate',
    value: v => v.endDate,
    filter: 'date-range',
  })

export const companyField = <Item extends { company: Company }>() =>
  getRefDataField<Item, Company>({
    id: 'companyId',
    castId: v => parseInt(v),
    label: 'Co',
    field: 'company.abbr',
    value: v => v.company.abbr,
    input: {
      value: item => item.company,
      onInput: copy => item => Vue.set(copy, 'company', item),
      component: VSelect,
      items: store => store.getters.companies,
      attrs: {
        'item-text': 'abbr',
        'item-value': 'id',
        'return-object': true,
        label: 'Company',
      },
    },
    colAttrs: { 'col-md': 6 },
    filter: 'list',
  })

export const salesAreaField = <Item extends { salesArea: SalesArea }>() =>
  getRefDataField<Item, SalesArea>({
    id: 'salesAreaId',
    castId: v => parseInt(v),
    label: 'Sales Area',
    field: 'salesArea.abbr',
    value: v => v.salesArea.abbr,
    input: {
      value: item => item.salesArea,
      onInput: copy => item => Vue.set(copy, 'salesArea', item),
      component: VSelect,
      items: store => store.getters.salesAreas,
      attrs: {
        'item-text': 'abbr',
        'item-value': 'id',
        'return-object': true,
        label: 'Sales Area',
      },
    },
    colAttrs: { 'col-md': 6 },
    filter: 'list',
  })

export const salespersonField = <Item extends { salesperson: Salesperson }>() =>
  getRefDataField<Item, Salesperson>({
    id: 'salespersonId',
    castId: v => parseInt(v),
    label: 'Salesperson',
    field: 'salesperson.username',
    value: v => v.salesperson.username,
    input: {
      value: item => item.salesperson,
      onInput: copy => item => Vue.set(copy, 'salesperson', item),
      component: VSelect,
      items: store => store.getters.salespersons,
      attrs: {
        'item-text': 'username',
        'item-value': 'id',
        'return-object': true,
        label: 'Salesperson',
      },
    },
    colAttrs: { 'col-md': 6 },
    filter: 'list',
  })

export const commissionPayPeriodField = <
  Item extends {
    commissionPayPeriod: CommissionPayPeriod
  }
>() =>
  getRefDataField<Item, CommissionPayPeriod>({
    id: 'commissionPayPeriodId',
    castId: v => parseInt(v),
    label: 'Comm Pay Period',
    field: 'commissionPayPeriod.abbr',
    value: v => v.commissionPayPeriod.abbr,
    input: {
      value: item => item.commissionPayPeriod,
      onInput: copy => item => Vue.set(copy, 'commissionPayPeriod', item),
      component: VSelect,
      items: store => store.getters.commissionPayPeriods,
      attrs: {
        'item-text': 'abbr',
        'item-value': 'id',
        'return-object': true,
        label: 'Commission Pay Period',
      },
    },
    colAttrs: { 'col-md': 6 },
    filter: 'list',
  })

export const productField = <
  Item extends {
    product: Product
  }
>() =>
  getRefDataField<Item, Product>({
    id: 'productId',
    castId: v => parseInt(v),
    label: 'Product',
    field: 'product.abbr',
    value: v => v.product.abbr,
    input: {
      value: item => item.product,
      onInput: copy => item => Vue.set(copy, 'product', item),
      component: VSelect,
      items: store => store.getters.products,
      attrs: {
        'item-text': 'abbr',
        'item-value': 'id',
        'return-object': true,
        label: 'Product',
      },
    },
    colAttrs: { 'col-md': 6 },
    filter: 'list',
  })

export const commissionComponentField = <
  Item extends {
    commissionComponent: CommissionComponent
  }
>() =>
  getRefDataField<Item, CommissionComponent>({
    id: 'commissionComponentId',
    castId: v => parseInt(v),
    label: 'Comm',
    field: 'commissionComponent.abbr',
    value: v => v.commissionComponent.abbr,
    input: {
      value: item => item.commissionComponent,
      onInput: copy => item => Vue.set(copy, 'commissionComponent', item),
      component: VSelect,
      items: store => store.getters.commissionComponents,
      attrs: {
        'item-text': 'abbr',
        'item-value': 'id',
        'return-object': true,
        label: 'Commission Component',
      },
    },
    colAttrs: { 'col-md': 6 },
    filter: 'list',
  })

export const commissionQuotaPeriodField = <
  Item extends {
    commissionQuotaPeriod: CommissionQuotaPeriod
  }
>() =>
  getRefDataField<Item, CommissionQuotaPeriod>({
    id: 'commissionQuotaPeriodId',
    castId: v => parseInt(v),
    label: 'Quota Period',
    field: 'commissionQuotaPeriod.abbr',
    value: v => v.commissionQuotaPeriod.abbr,
    input: {
      value: item => item.commissionQuotaPeriod,
      onInput: copy => item => Vue.set(copy, 'commissionQuotaPeriod', item),
      component: VSelect,
      items: store => store.getters.commissionQuotaPeriods,
      attrs: {
        'item-text': 'abbr',
        'item-value': 'id',
        'return-object': true,
        label: 'Commission Quota Period',
      },
    },
    colAttrs: { 'col-md': 6 },
    filter: 'list',
  })

export const commissionAdjustmentTypeField = <
  Item extends { typeId: ID; type: CommissionAdjustmentType }
>() =>
  getRefDataField<Item, number>({
    id: 'typeId',
    castId: v => parseInt(v),
    label: 'Type',
    field: 'type.abbr',
    value: v => v.type.abbr,
    input: {
      value: item => item.typeId,
      onInput: copy => item => Vue.set(copy, 'type', item),
      component: VSelect,
      items: store => store.getters.commissionAdjustmentTypes,
      attrs: {
        'item-text': 'abbr',
        'item-value': 'id',
        'return-object': true,
        label: 'Commission Adjustment Type',
      },
    },
    colAttrs: { 'col-md': 6 },
    filter: 'list',
  })
