const isProd = process.env.NODE_ENV === 'production'

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info: (...data: any[]) => {
    if (!isProd) console.log(...data)
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn: (...data: any[]) => {
    if (!isProd) console.warn(...data)
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: (...data: any[]) => {
    console.error(...data)
  },
}
