var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jad-issues-table-container",staticStyle:{"position":"relative"}},[_c('v-dialog',{attrs:{"persistent":"","width":"600"},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[(_vm.editItem)?_c('issue-edit',{attrs:{"value":_vm.editItem},on:{"save":_vm.onSave,"delete":_vm.onDelete,"close":function($event){return _vm.close()},"upload-attachments":_vm.onUploadedAttachments,"delete-attachment":_vm.onDeletedAttachment}}):_vm._e()],1),_c('v-data-table',{staticClass:"jad-data-table jad-issues-table table-nowrap flex-card-content flex-card mock-card",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"dense":_vm.dense,"hide-default-footer":_vm.hideDefaultFooter,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":_vm.itemsPerPage,"footer-props":{
      itemsPerPageOptions: [25, 50, 100, -1],
    }},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":_vm.scrollToTop},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
    var value = ref.value;
    var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.setEditItem(item)}}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.amount",fn:function(ref){
    var value = ref.value;
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value,item.currency))+" ")]}},{key:"item.orderId",fn:function(ref){
    var value = ref.value;
return [(value)?_c('router-link',{attrs:{"to":{ name: 'order-edit', params: { orderId: value } }}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }