






import Vue from 'vue'
import { BreakpointName } from 'vuetify/types/services/breakpoint'

type GridStyle = {
  'grid-column'?: string
  'grid-row'?: string
}

export default Vue.extend({
  props: {
    col: [String, Number],
    cols: [String, Number],
    row: [String, Number],
    rows: [String, Number],

    colSm: [String, Number],
    colsSm: [String, Number],
    rowSm: [String, Number],
    rowsSm: [String, Number],

    colMd: [String, Number],
    colsMd: [String, Number],
    rowMd: [String, Number],
    rowsMd: [String, Number],

    colLg: [String, Number],
    colsLg: [String, Number],
    rowLg: [String, Number],
    rowsLg: [String, Number],

    colXl: [String, Number],
    colsXl: [String, Number],
    rowXl: [String, Number],
    rowsXl: [String, Number],
  },
  computed: {
    BreakpointName(): BreakpointName {
      return this.$vuetify.breakpoint.name
    },
    calcCol(): string | number | undefined {
      switch (this.BreakpointName) {
        case 'xl':
          return (
            this.colXl || this.colLg || this.colMd || this.colSm || this.col
          )
        case 'lg':
          return this.colLg || this.colMd || this.colSm || this.col
        case 'md':
          return this.colMd || this.colSm || this.col
        case 'sm':
          return this.colSm || this.col
        default:
          return this.col
      }
    },
    calcCols(): string | number | undefined {
      switch (this.BreakpointName) {
        case 'xl':
          return (
            this.colsXl ||
            this.colsLg ||
            this.colsMd ||
            this.colsSm ||
            this.cols
          )
        case 'lg':
          return this.colsLg || this.colsMd || this.colsSm || this.cols
        case 'md':
          return this.colsMd || this.colsSm || this.cols
        case 'sm':
          return this.colsSm || this.cols
        default:
          return this.cols
      }
    },
    calcRow(): string | number | undefined {
      switch (this.BreakpointName) {
        case 'xl':
          return (
            this.rowXl || this.rowLg || this.rowMd || this.rowSm || this.row
          )
        case 'lg':
          return this.rowLg || this.rowMd || this.rowSm || this.row
        case 'md':
          return this.rowMd || this.rowSm || this.row
        case 'sm':
          return this.rowSm || this.row
        default:
          return this.row
      }
    },
    calcRows(): string | number | undefined {
      switch (this.BreakpointName) {
        case 'xl':
          return (
            this.rowsXl ||
            this.rowsLg ||
            this.rowsMd ||
            this.rowsSm ||
            this.rows
          )
        case 'lg':
          return this.rowsLg || this.rowsMd || this.rowsSm || this.rows
        case 'md':
          return this.rowsMd || this.rowsSm || this.rows
        case 'sm':
          return this.rowsSm || this.rows
        default:
          return this.rows
      }
    },
    style() {
      const grid: GridStyle = {}

      const col = this.calcCol
      const cols = this.calcCols
      const row = this.calcRow
      const rows = this.calcRows

      if (col) {
        grid['grid-column'] = `${col} / span ${cols || 1}`
      }

      if (row) {
        grid['grid-row'] = `${row} / span ${rows || 1}`
      } else if (rows) {
        grid['grid-row'] = `span ${rows || 1}`
      }

      return grid
    },
  },
})
