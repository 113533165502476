import { DateOnly } from '@/types'

export const sumArray = <T>(items: T[], valueFn: (item: T) => number) =>
  items.reduce((prev, item) => prev + valueFn(item), 0)

export const minDateArray = <T>(
  items: T[],
  valueFn: (item: T) => DateOnly | undefined
): DateOnly | undefined =>
  items
    .map(item => valueFn(item))
    .filter((value): value is DateOnly => !!value)
    .sort((a, b) => (a > b ? 1 : -1))[0]

export const maxDateArray = <T>(
  items: T[],
  valueFn: (item: T) => DateOnly | undefined
): DateOnly | undefined =>
  items
    .map(item => valueFn(item))
    .filter((value): value is DateOnly => !!value)
    .sort((a, b) => (a > b ? -1 : 1))[0]
