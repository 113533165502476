






































































































































































































































import Vue from 'vue'

import MessageBus from '@/message-bus'
import log from '@/log'

// components
import OrderSearchDialog from '@/components/orders/OrderSearchDialog.vue'

// data
import { refData } from '../refdata/ref-data'

// store
import initStore from '@/store/init'

// utils
import { sortAlpha } from '@/utils/sort'

// types
import { BeforeInstallPromptEvent } from '@/types/BeforeInstallPromptEvent'

export default Vue.extend({
  components: {
    OrderSearchDialog,
  },
  props: {
    closeable: Boolean,
  },
  data: () => ({
    menuRefData: false,
    searchOrderDialog: false,
  }),
  computed: {
    refData() {
      return refData
        .filter(r => !!r.fields && r.fields.length > 0)
        .sort(
          sortAlpha(item =>
            Array.isArray(item.label) ? item.label[1] : item.label
          )
        )
    },
    installablePrompt(): BeforeInstallPromptEvent {
      return this.$store.state.installablePrompt
    },
    isInstallable(): boolean {
      return !!this.installablePrompt
    },
  },
  methods: {
    onSearchOrder() {
      this.searchOrderDialog = !this.searchOrderDialog
    },
    refreshReferenceData() {
      MessageBus.info('refreshing reference data...')

      Promise.all(initStore(this.$api))
        .then(() => {
          MessageBus.success('refreshed reference data!')
        })
        .catch(err => {
          MessageBus.error(err.message)
        })
    },
    installApp() {
      if (!this.installablePrompt) throw new Error('deferredPrompt missing')
      // Hide the app provided install promotion
      // hideMyInstallPromotion()
      // Show the install prompt
      this.installablePrompt.prompt()
      // Wait for the user to respond to the prompt
      this.installablePrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          log.info('User accepted the install prompt')
        } else {
          log.info('User dismissed the install prompt')
        }
      })
    },
  },
})
