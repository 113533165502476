












































































import Vue, { PropType } from 'vue'

import log from '@/log'

// components
import Dropzone from '@/components/attachments/Dropzone.vue'

// utils
import { getMimeTypeRef } from '@/utils/mimetype'

// types
import { StorageObjectInput } from '@/types'
import { OnProgress } from '@/api/types'

type ApiCall = (
  files: File[],
  input?: StorageObjectInput,
  onUploadProgress?: OnProgress
) => Promise<unknown>

export default Vue.extend({
  components: {
    Dropzone,
  },
  props: {
    apiCall: Function as PropType<ApiCall>,
  },
  data: () => ({
    files: [] as File[],

    errors: [] as Error[],

    isUploading: false,
    uploadProgress: 0,
  }),
  computed: {
    hasFiles(): boolean {
      return !!this.files && this.files.length > 0
    },
  },
  methods: {
    getMimeTypeRef,
    onFilesChange(f: Event) {
      const target = f.target as HTMLInputElement

      if (target.files && target.files.length > 0) {
        this.files.push(...target.files)
      }
    },
    removeFile(i: number) {
      this.$delete(this.files, i)
    },
    clearFiles() {
      this.files = []
    },
    close() {
      this.clearFiles()
      this.$emit('close')
    },
    onDrop(filelist: FileList) {
      if (this.files === undefined) this.files = []
      for (let i = 0; i <= filelist.length; i++) {
        if (filelist.item(i)) this.files.push(filelist.item(i) as File)
      }
    },
    submit() {
      if (!this.apiCall) throw new Error('upload function not found')
      if (!this.files || this.files.length <= 0)
        throw new Error('no file selected')

      this.isUploading = true
      this.errors = []

      this.apiCall(this.files, undefined, evt => {
        this.uploadProgress = evt.loaded / evt.total
      })
        .then(response => {
          log.info(response)
          this.clearFiles()
          this.$emit('success', response)
        })
        .catch(err => {
          log.error(err)
          this.errors.push(err)
          this.$emit('error', err)
        })
        .finally(() => {
          this.isUploading = false
          this.uploadProgress = 0
        })
    },
  },
})
