







































import Vue, { PropType } from 'vue'

import JFormattedInput from '@/components/controls/JFormattedInput.vue'

export default Vue.extend({
  components: {
    JFormattedInput,
  },
  props: {
    value: [String, Number],
    format: {
      type: String,
      default: 'standard',
    },
    label: String,
    type: {
      type: String,
      default: 'text',
    },
    align: {
      type: String as PropType<'start' | 'center' | 'end'>,
      default: 'start',
    },

    clearable: Boolean,
    readonly: Boolean,
  },
  methods: {
    onInput(value: number): void {
      this.$emit('input', value)
    },
  },
})
