declare const Apex: any // eslint-disable-line @typescript-eslint/no-explicit-any

// Vue
import Vue from 'vue'
import router from '@/router'
import store from '@/store'

// services
import AuthService from '@/auth'
import api from '@/api'

// components
import App from '@/App.vue'

// plugins
import vuetify from '@/plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'

// helpers
import { initFilters } from '@/filters'
import { chartColors, chartOptions } from '@/charts/format'
import { setProgressText, setErrorState } from '@/utils/html-helpers'

// PWA
import '@/registerServiceWorker'

// log
import log from '@/log'

// types
import { AppInfo } from '@/types/app'

setProgressText('starting up...')

const auth = new AuthService()

const init = () => {
  Vue.config.productionTip = false

  // Google Analytics
  const GTAG_ID = process.env.VUE_APP_GTAG_ID
  if (GTAG_ID) Vue.use(VueGtag, { config: { id: GTAG_ID } }, router)

  // Apex Charts
  Vue.use(VueApexCharts)
  Vue.component('apexchart', VueApexCharts)
  Apex.chart = chartOptions
  Apex.colors = chartColors

  // metadata
  Vue.use(VueMeta)

  // PWA: make app installable
  window.addEventListener('beforeinstallprompt', e => {
    log.info('main.beforeinstallprompt', e)
    e.preventDefault() // Prevent the mini-infobar from appearing on mobile
    store.commit('setInstallablePrompt', e)
  })

  // set app info
  Vue.prototype.$app = {
    name: process.env.VUE_APP_TITLE || 'jad-sales-vue',
    version: process.env.VUE_APP_VERSION,
    env: process.env.NODE_ENV || 'unknown',
    isDev: process.env.NODE_ENV === 'development',
  } as AppInfo

  // filters
  initFilters(Vue)
}

// auth then render
auth.init(setProgressText).then(
  () => {
    init()

    Vue.prototype.$auth = auth
    Vue.prototype.$api = api(auth)

    new Vue({
      router,
      vuetify,
      store,
      render: h => h(App),
    }).$mount('#app')
  },
  err => {
    if (err.type !== 'authenticating') setErrorState()
    setProgressText(err.message || err)
    log.error(err)
  }
)
