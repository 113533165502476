

















































import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    value: [String, Number],
    label: String,
    placeholder: String,
    type: {
      type: String,
      default: 'text',
    },
    align: {
      type: String as PropType<'start' | 'center' | 'end'>,
      default: 'start',
    },

    clearable: Boolean,
    readonly: Boolean,
    hideInputAppearance: Boolean,
  },
  methods: {
    getValueFromEvent(event: Event): string | number | null | undefined {
      const target = event.target as HTMLInputElement
      return target.value
    },
    onInput(event: Event): void {
      let value = this.getValueFromEvent(event)

      if (
        (this.type === 'number' &&
          typeof value === 'string' &&
          value.trim() === '') ||
        value === undefined
      ) {
        value = null
      }

      this.$emit('input', value, event)
    },
    onChange(event: Event): void {
      let value = this.getValueFromEvent(event)

      if (
        (this.type === 'number' &&
          typeof value === 'string' &&
          value.trim() === '') ||
        value === undefined
      ) {
        value = null
      }

      this.$emit('change', value, event)
    },
  },
})
