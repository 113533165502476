
























































import evalValue from '@/utils/eval-value'
import Vue, { PropType } from 'vue'

type ItemType = {
  [key: string]: string | number // this is just a placeholder
}

type JTableHeaderFormat = string | ((value: unknown, item: unknown) => unknown)

export type JTableHeader = {
  text?: string
  value?: unknown
  align?: 'left' | 'center' | 'right'
  width?: string
  id?: string | number

  icon?: string
  smallIcon?: boolean

  format?: JTableHeaderFormat
}

export default Vue.extend({
  components: {},
  props: {
    items: Array as PropType<ItemType[]>,
    itemKey: {
      type: String,
      default: 'id',
    },
    headers: Array as PropType<JTableHeader[]>,
    create: {
      type: Function as PropType<(...args: unknown[]) => ItemType>,
      required: false,
    },

    dense: Boolean,
    filled: Boolean,
    narrow: Boolean,
  },
  computed: {
    defaultItemsCount(): number {
      return this.items.filter(item => item.isDefault).length
    },
    hasDefaultItem(): boolean {
      return this.defaultItemsCount > 0
    },
  },
  watch: {
    defaultItemsCount: {
      immediate: true,
      handler(n) {
        if (n === 0 && this.create) this.create()
      },
    },
  },
  methods: {
    evalValue(
      item: ItemType,
      prop: string,
      format?: JTableHeaderFormat
    ): unknown {
      if (!prop) return item

      const value = evalValue(item, prop)

      const formatter =
        format && this.$options.filters
          ? typeof format === 'string'
            ? this.$options.filters[format] || ((val: unknown) => val)
            : format
          : (val: unknown) => val

      return formatter(value, item)
    },
  },
})
