import Vue from 'vue'

const filters = {
  currency: (
    value: number,
    currency = 'USD',
    options: Intl.NumberFormatOptions = {}
  ): string | undefined => {
    if (value === undefined || value === null) return undefined
    return Number(value).toLocaleString(undefined, {
      ...options,
      style: 'currency',
      currency,
    })
  },

  percent: (
    value: number,
    digits = 3,
    options: Intl.NumberFormatOptions = {}
  ) => {
    if (value === undefined || value === null) return undefined
    return (value as number).toLocaleString(undefined, {
      ...options,
      style: 'percent',
      minimumFractionDigits: digits,
    })
  },
  number: (
    value: number,
    digits = 0,
    options: Intl.NumberFormatOptions = {}
  ) => {
    if (value === undefined || value === null) return undefined
    return (value as number).toLocaleString(undefined, {
      ...options,
      minimumFractionDigits: digits,
    })
  },
  datetime: (
    value: Date | string,
    options: Intl.DateTimeFormatOptions = {}
  ) => {
    if (value === undefined || value === null) return undefined
    return new Date(value).toLocaleString(undefined, options)
  },
  datetimeShort: (
    value: Date | string,
    options: Intl.DateTimeFormatOptions = {}
  ) => {
    if (value === undefined || value === null) return undefined
    return new Date(value).toLocaleString(undefined, {
      ...options,
      dateStyle: 'medium',
      timeStyle: 'short',
    })
  },
  filesize: (value: number) => {
    const abbrs = ['B', 'KB', 'MB', 'GB']
    const bytes = 1024
    let n = 0

    while (value / Math.pow(bytes, n) > 5000 && n < abbrs.length) {
      n++
    }

    return Math.round(value / Math.pow(bytes, n)) + ' ' + abbrs[n]
  },
}

export const initFilters = (v: typeof Vue) =>
  Object.entries(filters).forEach(([id, definition]) =>
    v.filter(id, definition)
  )

export default filters
