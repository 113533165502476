





























import Vue, { PropType } from 'vue'

import Order from '@/models/Orders/Order'

export default Vue.extend({
  props: {
    order: Object as PropType<Order>,
  },
})
