






import Vue from 'vue'

export default Vue.extend({
  props: {
    fixedHeight: Boolean,
    fluid: Boolean,
  },
})
