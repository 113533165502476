export const chartOptions = {
  fontFamily: "'Roboto', sans-serif",
  toolbar: { show: false },
  animations: {
    speed: 250,
  },
}

export const chartColors = [
  '#19456b', // secondary
  '#16c79a', // primary
  '#11698e', // accent
  '#9590A8', // <= begins column-only colors
  '#C3A995',
  '#98B9F2',
  '#A3A5C3',
  '#ECCE8E',
  '#8B786D',
  '#856A5D',
]
