































































import Vue, { PropType } from 'vue'
import log from '@/log'

import clonedeep from 'lodash.clonedeep'
import MessageBus from '@/message-bus'
import { ApiInterface } from '@/api'

// components
import { RefDataField } from '@/components/refdata/ref-data'

// types
import { GenericRefItem } from '@/types'
import { CreateFn, ReplaceFn } from '@/api/types'

const DEFAULT_COPY = {
  id: undefined,
  abbr: undefined,
  name: undefined,
}

type SubmitType = 'create' | 'replace'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    value: Object,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields: Array as PropType<RefDataField<any>[]>,
    mutation: {
      type: String,
      required: false,
    },
    create: {
      type: Function as PropType<
        (api: ApiInterface) => CreateFn<GenericRefItem>
      >,
      required: false,
    },
    replace: {
      type: Function as PropType<
        (api: ApiInterface) => ReplaceFn<GenericRefItem>
      >,
      required: false,
    },
  },
  data: () => ({
    copy: undefined as GenericRefItem | undefined,

    isSaving: false,
    errors: [] as unknown[],
  }),
  created() {
    this.copy = clonedeep(this.value || DEFAULT_COPY)
  },
  computed: {
    createFn(): CreateFn<GenericRefItem> | undefined {
      if (!this.create) return undefined
      return this.create(this.$api)
    },
    replaceFn(): ReplaceFn<GenericRefItem> | undefined {
      if (!this.replace) return undefined
      return this.replace(this.$api)
    },
  },
  methods: {
    doSubmit<R extends GenericRefItem>(
      promiseFn: Promise<R>,
      submitType: SubmitType
    ): void {
      this.isSaving = true

      promiseFn
        .then(value => {
          if (!value) throw new Error('item saved but error fetching')

          if (this.mutation) this.$store.commit(this.mutation, value)

          this.$emit('input', value)
          this.$emit(submitType, value)

          MessageBus.success(`saved #${value.id}`)

          this.$emit('close')
        })
        .catch(err => {
          MessageBus.error(
            `error saving #${this.copy?.id}: ${err.message || err}`
          )
          log.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    onSubmit(): void {
      if (!this.copy) throw new Error('copy missing')

      if (this.copy?.id) {
        // replace
        if (!this.replaceFn) throw new Error('replace function missing')
        this.doSubmit(this.replaceFn(this.copy.id, this.copy), 'replace')
      } else {
        // create
        if (!this.createFn) throw new Error('create function missing')
        this.doSubmit(this.createFn(this.copy), 'create')
      }
    },
  },
})
