export const isString = (variable: unknown): variable is string =>
  typeof variable === 'string' || variable instanceof String

// TODO: check if function types are the same
export const isFunction = <TFunction>(
  variable: unknown
): variable is TFunction =>
  !!variable && {}.toString.call(variable) === '[object Function]'

export const isArray = <T = unknown>(variable: unknown): variable is T[] =>
  Array.isArray(variable)

export const isNumber = (variable: unknown): variable is number =>
  !Number.isNaN(Number(variable))

export const isInteger = (variable: unknown): variable is number =>
  Number.isInteger(Number(variable))

export const isBoolean = (variable: unknown): variable is boolean =>
  variable === true || variable === false

export const isObject = (
  variable: unknown
): variable is Record<string, unknown> => typeof variable === 'object'

export const isNull = (variable: unknown): variable is null => variable === null

export const isDate = (variable: unknown): variable is Date =>
  variable instanceof Date && !isNaN(variable.valueOf())
